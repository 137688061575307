import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import './ReportMarkup.scss';
import { Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts'
import { ZLoader } from '../../../shared/loader/ZLoader'
import RegisteredChart from './RegisteredChart'

const ReportMarkup = (props) => {
     const { isLoading, cohortAlldataSeries, AllstudentApplicationseries,AllstudentApplicationLabel,APAstudentApplicationLabel, AllstudentApplicationoptions, APAstudentApplicationseries, APAstudentApplicationoptions, cohortAlldataOptions, cohortAPAdataSeries, cohortAPAdataOptions } = props
     const months=['Jan-2020','Feb-2020','Mar-2020','Apr-2020','May-2020','Jun-2020','Jul-2020','Aug-2020','Sep-2020','Oct-2020','Nov-2020','Dec-2020','Jan-2021','Feb-2021','Mar-2021','Apr-2021','May-2021','Jun-2021','Jul-2021','Aug-2021','Sep-2021','Oct-2021','Nov-2021','Dec-2021','Jan-2022','Feb-2022','Mar-2022','Apr-2022','May-2022','Jun-2022','Jul-2022','Aug-2022','Sep-2022','Oct-2022','Nov-2022','Dec-2022','Jan-2023','Feb-2023','Mar-2023','Apr-2023','May-2023','Jun-2023','Jul-2023','Aug-2023','Sep-2023','Oct-2023','Nov-2023','Dec-2023','Jan-2024','Feb-2024','Mar-2024','Apr-2024','May-2024','Jun-2024','Jul-2024','Aug-2024','Sep-2024','Oct-2024','Nov-2024','Dec-2024']

     // Function to convert chart data to CSV
     function convertToCSV(label,series) {
          let csvContent = "data:text/csv;charset=utf-8,";
          csvContent +=`Registration month, Application created month, count\n`;
          for (let i = 0; i < label.length; i++) {
                    const splitIndex = label[i].indexOf("_", label[i].indexOf("_") + 1);  
                    const firstPart = label[i].slice(0, splitIndex);
                    const secondPart = label[i].slice(splitIndex + 1);
                    csvContent += `${firstPart},${secondPart},${series[0].data[i]}\n`;
          }
          return csvContent;
     }

     // Function to download the chart data as a CSV file
     function downloadApplicationcsv(key,label,series) {
          const csvData = convertToCSV(label,series);
          const link = document.createElement('a');
          link.href = encodeURI(csvData);
          link.download = `${key}_Applications_data.csv`;
          link.click();
     }

     const handleMonthname = (param) => {
          let monthNumber=''                   
          let full_date = param;
          monthNumber = full_date.split("_")[1]
          const monthName = new Date(2000, monthNumber - 1).toLocaleString('en-US', { month: 'long' });
          return `${monthName.slice(0, 3)}-${full_date.split("_")[0]}`
     }
     
     const  createApplicationArray = (label,series) => {
          let newElement ={}
          let array =[]
          for (let i = 0; i < label.length; i++) {
               const splitIndex = label[i].indexOf("_", label[i].indexOf("_") + 1);  
               const firstPart = handleMonthname(label[i].slice(0, splitIndex))
               const secondPart = handleMonthname(label[i].slice(splitIndex + 1))
               newElement = {'full_date':label[i],'registration_month':firstPart,'application_month':secondPart,count:series[0].data[i]}
               array.push(newElement)
          }
          return array
     }
     const checkDates = (x,y) => {
          const dateString1 = x;
          const dateString2 =  y;  
          const date1 = new Date(dateString1);
          const date2 = new Date(dateString2);
          const areDatesEqual = date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
          return areDatesEqual          
     }

     const convertCohortToCSV = (label,series,cohortOption,cohortSeries) => {
          let ApplicationArray = createApplicationArray(label,series)  
          let csvContent = "data:text/csv;charset=utf-8,";
          csvContent +=',This sheet represents cohort base analysis of stage wise lead movement/revenue basis the lead registration month\n\n';
          csvContent +=`,Cohort,,Total App,,`;
          for (let i = 0; i < months.length; i++) {
               let total_application_count = 0;
               let filteredArray = []
               let x = ApplicationArray.map((element,index) => {
               let areDatesEqual=checkDates(element['application_month'], months[i])
                    if(areDatesEqual) {
                         filteredArray.push(element)
                         total_application_count += element.count
                    }});     
               csvContent +=`${JSON.stringify(total_application_count)},`          
          }
          csvContent +="\n\n"
          csvContent +=`Conversion %,,Leads Month,,,`;
          for (let i = 0; i < cohortOption.length; i++) {
               for (let j = 0; j < cohortSeries.length; j++) {
                    csvContent +=`${cohortSeries[j].name}-${cohortOption[i]},`;
               }
          }
          for (let i = 0; i < months.length; i++) {
               let filteredArray = []
               let x = ApplicationArray.map((element,index) => {
               let areDatesEqual=checkDates(element['registration_month'], months[i])
                    if(areDatesEqual) {
                         filteredArray.push(element)
                    }});               
          }
          csvContent +=`\n`;
          for (let i = 0; i < cohortOption.length; i++) {
               for (let j = 0; j < cohortSeries.length; j++) {
                    let filteredregisterArray = ApplicationArray.filter((element) => checkDates(`${cohortSeries[j].name}-${cohortOption[i]}`,element['registration_month']))
                    
                    let rowcontent = ''
                    let total=0
                    for (let k = 0; k < months.length; k++) { 
                         let filteredapplicationarray = filteredregisterArray.filter((element) => checkDates(`${months[k]}`,element['application_month']))
                         if(filteredapplicationarray && filteredapplicationarray[0] && filteredapplicationarray[0].count)  
                         {
                              rowcontent += `${filteredapplicationarray[0].count},`
                              total+=filteredapplicationarray[0].count
                         }
                         else rowcontent += `,`
                    }
                    let percentage_app = total/cohortSeries[j].data[i] ? (total/cohortSeries[j].data[i]).toFixed(3) : 0;
                    csvContent += `,,${cohortSeries[j].name}-${cohortOption[i]},Leads,${cohortSeries[j].data[i]}\n`;
                    csvContent+= `Lead- Application,,${JSON.stringify(percentage_app)},Applications,${JSON.stringify(total)},${rowcontent}\n`;
                    csvContent+= `Application-Enrolment,,,Enrolment,\n\n`
               }
          }
          csvContent +=`\n`;
          return csvContent;
     }

     // Function to download the chart data as a CSV file
     const downloadCohortCSV = (key,label,series,cohortOption,cohortSeries) => {
          const csvData = convertCohortToCSV(label,series,cohortOption,cohortSeries);
          const link = document.createElement('a');
          link.href = encodeURI(csvData);
          link.download = `${key}_Cohort_Report.csv`;
          link.click();
     }


     return (
          <div className="report-container">
               <Container>
                    {isLoading && isLoading ? <ZLoader /> : <>
                    <Row>                         
                         <Col>
                               <Button
                                   variant="success"
                                   className="center"
                                   onClick={ () => downloadCohortCSV('All',AllstudentApplicationLabel,AllstudentApplicationseries,cohortAlldataOptions,cohortAlldataSeries) }
                                   > <i class="fa fa-cloud-download" aria-hidden="true"></i> All Cohort Report
                              </Button>
                         </Col>    
                         <Col>
                               <Button
                                   variant="success"
                                   className="center"
                                   onClick={ () => downloadCohortCSV('APA',APAstudentApplicationLabel,APAstudentApplicationseries,cohortAPAdataOptions,cohortAPAdataSeries) }
                                   > <i class="fa fa-cloud-download" aria-hidden="true"></i> APA Cohort Report
                              </Button>
                         </Col>                  
                    </Row>
                    <Row>
                         <Col>
                              {cohortAlldataSeries && cohortAlldataOptions ?
                                   <div className="chart2">
                                        <RegisteredChart
                                             chartitle={'All Registration'}
                                             cohortdataSeries={cohortAlldataSeries}
                                             cohortdataOptions={cohortAlldataOptions}
                                        />
                                   </div>
                                   : ''
                              }
                         </Col>
                         <Col>
                              {cohortAPAdataSeries && cohortAPAdataOptions ?
                                   <div className="chart2">
                                        <RegisteredChart
                                             chartitle={'APA Through Registration'}
                                             cohortdataSeries={cohortAPAdataSeries}
                                             cohortdataOptions={cohortAPAdataOptions}
                                        />
                                   </div>
                                   : ''
                              }
                         </Col>
                    </Row>
                    <hr />
                    <br/>
                    <Row>
                         <Col>
                              <Button
                                   variant="success"
                                   className="center"
                                   onClick={ () => downloadApplicationcsv('All',AllstudentApplicationLabel,AllstudentApplicationseries) }
                                   > <i class="fa fa-cloud-download" aria-hidden="true"></i> All Applications report
                              </Button>
                         </Col>
                    </Row>
                    <br/>
                    <Row>
                         <Col md="12">
                              <div className="chart-one" >
                                   <div id="chart" >
                                        <Chart
                                             series={AllstudentApplicationseries}
                                             options={AllstudentApplicationoptions}
                                             type="area" height={350}
                                        />
                                   </div>
                                   <p style={{ textAlign: 'center' }}>Dates ( YYYY_MM_YYYY_MM ) <FontAwesomeIcon className={"icon"} icon={['fas', 'long-arrow-alt-right']} /></p>
                                   <p style={{ textAlign: 'center',fontSize:12 }}>First YYYY_MM registered month and second YYYY_MM Application created month</p>

                              </div>
                         </Col>
                    </Row>
                    <hr />
                    <br/>
                    <Row>
                         <Col>
                              <Button
                                   variant="success"
                                   className="center"
                                   onClick={ () => downloadApplicationcsv('APA',APAstudentApplicationLabel,APAstudentApplicationseries) }
                                   > <i class="fa fa-cloud-download" aria-hidden="true"></i> APA Applications report
                              </Button>
                         </Col>
                    </Row>
                    <br/>
                    <Row>
                         <Col md="12">
                              <div className="chart-one" >
                                   <div id="chart" >
                                        <Chart
                                             series={APAstudentApplicationseries}
                                             options={APAstudentApplicationoptions}
                                             type="area" height={350}
                                        />
                                   </div>
                                   <p style={{ textAlign: 'center' }}>Dates ( YYYY_MM_YYYY_MM ) <FontAwesomeIcon className={"icon"} icon={['fas', 'long-arrow-alt-right']} /></p>
                                   <p style={{ textAlign: 'center',fontSize:12 }}>first YYYY_MM registered month and second YYYY_MM Application created month</p>

                              </div>
                         </Col>
                    </Row>
                    </>}
               </Container>
          </div>
     )
}

export default ReportMarkup;
