import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
    InputGroup,
    Label,
    Button,
    MenuItem,
    TextArea,
    Position,
    Checkbox
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { Radio, RadioGroup,FileInput } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment';
import _ from 'lodash';
import './FormGenerator.scss';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const renderItem = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    return (
        <MenuItem
            // className="form-generator-menu-item"
            active={modifiers.active}
            disabled={modifiers.disabled}
            text={item.label ? item.label.toString() : null}
            key={item.label}
            onClick={handleClick}
        />
    );
};



const FormGenerator = ({
    formData,
    updateValue,
    coursesState,onDropFile,step,onRemoveDoc,RemoveEducation,RemoveExperience
}) => {
  const  filterItems = (query, form) => {
        return form.value ? form.value.toLowerCase().indexOf(query.toLowerCase()) !== -1 : [];
    };
    console.log("formData>>>", formData);

    return (
        <Container className="form-generator">
            <Row>
                {formData.map((form, index) => {
                    switch (form.type) {
                        case 'input':
                            return (
                                <>
                                <Col className="field-wrapper" md={form.layout.md}>
                                    {form.label ? <Label htmlFor="input-b">{`${form.label} ${form.mandatory==1?"*":''}`}</Label> : null}
                                    <InputGroup
                                        key={form.order}
                                        placeholder={form.placeholder}
                                        id={form.id}
                                        onChange={(e) => updateValue(e, form.order)}
                                        value={form.value}
                                        lineDirection='center'
                                        error={form.error}
                                        disabled={!!form.disabled}
                                        errorText={form.errorText} 
                                        maxLength={form.textLimit?form.textLimit:null}
                                        />
                                        { form.error ? <div className="error">{form.errorText}</div> : null }
                                </Col>
                        
                                </>
                            );
                        case 'textarea':
                            return (
                                <Col className="field-wrapper" md={form.layout.md}>
                                    {form.label ? <Label htmlFor="input-b">{`${form.label} ${form.mandatory==1?"*":''}`}</Label> : null}
                                    <TextArea
                                        key={form.order}
                                        placeholder={form.placeholder}
                                        id={form.id}
                                        onChange={(e) => updateValue(e, form.order)}
                                        value={form.value}
                                        large={true}
                                        lineDirection='center'
                                        error={form.error}
                                        disabled={!!form.disabled}
                                        errorText={form.errorText}
                                        fill={true}
                                        maxLength={form.textLimit?form.textLimit:null}

                                        />
                                        { form.error ? <div className="error">{form.errorText}</div> : null }
                                </Col>
                            );
                        case 'select':
                            return (
                                <Col className="field-wrapper"  md={form.layout.md}>
                                    {form.label ? <Label htmlFor="input-b">{`${form.label} ${form.mandatory==1?"*":''}`}</Label> : null}
                                    <Select
                                        className="select-class"
                                        items={form.options}
                                        itemRenderer={renderItem}
                                        noResults={<MenuItem disabled={true} text="No results." />}
                                        filterable={true}
                                        minimal={true}
                                        itemPredicate={filterItems}
                                        onItemSelect={(e) => {
                                            const event = {
                                                target: {
                                                    value: e
                                                }
                                            }
                                            updateValue(event, form.order)
                                        }}
                                        popoverProps={{ minimal:true }}
                                    >
                                        <Button text={form.value ? form.value.label : "select"} rightIcon="double-caret-vertical" />
                                    </Select>
                                    { form.error ? <div className="error">{form.errorText}</div> : null }
                                </Col>
                            );
                        case 'radio':
                            const options = form.options.map(item => <Radio label={item.label} value={item.value} />)
                            return (
                                <Col className="field-wrapper"  md={form.layout.md}>
                                    <RadioGroup
                                        inline={true}
                                        label={`${form.label} ${form.mandatory==1?"*":''}`}
                                        name="group"
                                        onChange={(e) => updateValue(e, form.order)}
                                        selectedValue={form.value}
                                    >
                                        {options}
                                    </RadioGroup>
                                    { form.error ? <div className="error">{form.errorText}</div> : null }
                                </Col>
                            );
                        case 'checkbox':
                            const checkboxoptions = form.options.map(item => <Checkbox
                                onChange={(e) => {
                                    let { value } = form;
                                    if(_.includes(value, item.value)) {
                                        value = _.filter(value, function(o) { return o !== item.value; });
                                    } else {
                                        value.push(item.value)
                                    }
                                    const event = {
                                        target: {
                                            value: value
                                        }
                                    }
                                    updateValue(event, form.order)}
                                }
                                label={item.label} />
                                )
                            return (
                                <Col className="field-wrapper"  md={form.layout.md}>
                                    <div>
                                        {form.label ? <Label htmlFor="input-b">{`${form.label} ${form.mandatory==1?"*":''}`}</Label> : null}
                                        {checkboxoptions}
                                    </div>
                                    { form.error ? <div className="error">{form.errorText}</div> : null }
                                </Col>
                            );
                        case 'date':

                            return (
                                <Col className="field-wrapper"  md={form.layout.md}>
                                    {form.label ? <Label htmlFor="input-b">{`${form.label} ${form.mandatory==1?"*":''}`}</Label> : null}
                                    <DateInput
                                        formatDate={ date => moment(date).format("DD/MM/YYYY")}
                                        parseDate={str => moment(str, "DD/MM/YYYY").toDate()}
                                        onChange={(e) => {
                                            const event = {
                                                target: {
                                                    value: e
                                                }
                                            }
                                            updateValue(event, form.order)
                                        }}
                                        parseDate={str => new Date(str)}
                                        placeholder={"DD/MM/YYYY"}
                                        minDate={form.nexDates?new Date(): new Date('1/1/1950')}
                                        maxDate={form.previousDates? new Date():new Date('1/1/2030')}
                                      
                                    />
                                    { form.error ? <div className="error">{form.errorText}</div> : null }
                                </Col>
                            );
                        case 'file' :
                            return (
                               
                                    //  <Col className="field-wrapper"  md={form.layout.md}>
                                    //     {form.label ? <Label htmlFor="input-b">{form.label}</Label> : null}
                                    //  <FileInput onInputChange={(e)=> updateValue(e,form.order)}  text="Choose file..." />

                                    // </Col>
                                    <Col className="field-wrapper"  md={form.layout.md}>
                                  {form.label ? <Label htmlFor="input-b">{`${form.label} ${form.mandatory==1?"*":''}`}</Label> : null}
                                {step==5 && form.order>4?<FontAwesomeIcon onClick={()=>onRemoveDoc(form)} className="external-icon" icon={["fas", "times-circle"]} />:null}
                                    <Dropzone onDrop={files =>onDropFile(files,form.fileName, form.order,form.group)}>
                                    {({ getRootProps, getInputProps }) => (
                                        
                                        <div {...getRootProps({
                                            className: 'dropzone',
                                            onDrop: event => event.stopPropagation()
                                        })}>
                                            <input {...getInputProps()} accept={form.accept} />
                                            <p>{form.value && form.value.path ? form.value.path : "Click to select file" }</p>

                                        </div>
                                        
                                    )}
                                </Dropzone>
                                </Col>
                                
                            )
                        case 'button':

                            return(
                                    <Col  md={form.layout.md}>
                              {form.onRemove=='education'?  <Button className="remove" onClick={()=>RemoveEducation(form)} >{form.text}</Button>:null}
                             {form.onRemove=='experience'?   <Button className="remove" onClick={()=>RemoveExperience(form)} >{form.text}</Button>:null}
                             <hr/>

                                </Col>
                            )
                        default:
                            return (
                                <InputGroup
                                    key={form.order}
                                    id='singleTitle1'
                                    placeholder='Title'
                                    className='md-cell md-cell--bottom' />
                            );
                    }
                })}
            </Row>

        </Container>
    );
};

export default FormGenerator;
