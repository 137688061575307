import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FormMarkup from "../components/Form"
import { bindActionCreators } from 'redux';
import { Toast } from '../../../shared';
import { validation } from '../../../utils';
import actions from '../../Students/modules/actions'
import courseactions from '../../Course/modules/action'
import integrationAction from '../modules/actions'
import sourceAction from '../../Source/modules/actions'
import moment from 'moment';
import { CLOUDFROND_ASSETS3_URL } from '../../../env'
import countryList from '../modules/countryList'
import Modal from '../../../shared/model/model';
import FormGenerator from '../../../shared/FormBuilder/FormGenerator';
import { Container, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';


class ZilterForm extends Component {
    constructor(props) {
        super(props);
        this.state =
            this.state = {
                step: 0,
                courses: [],
                eduFileMarksheet: null,
                eduFileDegree: null,
                identity_proof: null,
                expFile: null,
                langFile: null,
                showEduModal: false,
                otherDetails: [],
                otherDetailsUniversity1: '',
                otherDetailsDegree1: '',
                otherDetailsDegreeType1: '',
                otherDetailsYear1: '',
                otherDetailsScore1: '',
                otherDetailsFile1: '',
                sources: [],
                lorDocFile: [],
                sopDocFile: [],
                otherDocFile: [],
                educations: [
                    {
                        'order': 0,
                        'id': 'home_university',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': 'Home university',
                        'value': '',
                        'placeholder': 'Home university',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid university name',
                        'icon': 'business',
                        "pattern":/\w+/,
                    }, {
                        'order': 1,
                        'id': 'degree_name',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Exact title of qualification',
                        'value': '',
                        'placeholder': 'Ex: BE Electronics and Telecommunication',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid degree and cours name',
                        'icon': 'business',
                        "pattern":/\w+/

                    },
                    {
                        'order': 2,
                        'id': 'degree_type',
                        'type': 'select',
                        "layout": { "lg": 6, "md": 6, "sm": 6, "xs": 6 },
                        'label': 'Qulification type',
                        'value': '',
                        'placeholder': 'Qulification type',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select degree type',
                        'icon': '',
                        'options': [
                            { "label": "Masters", "value": "Masters" },
                            { "label": "Bachelors", "value": "Bachelors" },
                        ]
                    },
                    {
                        'order': 3,
                        'id': 'completed_year',
                        'type': 'date',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'When was your degree issued?',
                        'value': '',
                        'placeholder': 'Ex: 3',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid year.',
                        'icon': 'business',
                    },
                    {
                        'order': 4,
                        'id': 'score',
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Marks',
                        'value': '',
                        'placeholder': 'Marks in %',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid percent.',
                        'icon': 'business',
                        'pattern':/^\s*(\d{0,2})(\.?(\d*))?\s*\%?\s*$/
                    },
                    {
                        'order': 5,
                        'id': 'file',
                        'type': 'file',
                        'fileName': 'eduFileDegree',
                        'accept':'.png, .jpg, .jpeg, .pdf',
                        "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Upload Degree/Diploma document',
                        'value': '',
                        'error': false,
                        'mandatory': 0,

                    },
                    {
                        'order': 6,
                        'id': 'file_1',
                        'type': 'file',
                        'fileName': 'eduFileMarksheet',
                        'accept':'.png, .jpg, .jpeg, .pdf',
                        "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Transcript/Marksheet',
                        'value': '',
                        'error': false,
                        'mandatory': 0,

                    },
                ],
                cards: [

                    {
                        name: "Programme",
                        title: "Select the programme you want to apply for:",
                        input: [
                            {
                                'order': 0,
                                'id': 'course_type',
                                'type': 'select',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Study Type',
                                'value': '',
                                'placeholder': 'Study Type',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please select an option.',
                                'icon': '',
                                'options': [
                                    { "label": "Foundation", "value": "Foundation" },
                                    { "label": "Bachelors", "value": "Bachelors" },
                                    { "label": "Masters", "value": "Masters" },
                                    { "label": "Diploma", "value": "Diploma" },
                                    { "label": "Phd", "value": "phd_doctoral" },
                                ]
                            }, {
                                'order': 1,
                                'id': 'course_name',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Course Name',
                                'value': '',
                                'placeholder': 'Course Name',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please enter valid Course name',
                                'icon': 'business',
                        "textLimit":35


                            }, {
                                'order': 2,
                                'id': 'intake_month',
                                'type': 'select',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Intake Month',
                                'value': '',
                                'placeholder': 'Intake Month',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please select an option.',
                                'icon': '',
                                'options': [
                                    { "label": "January", "value": "January" },
                                    { "label": "February", "value": "February" },
                                    { "label": "March", "value": "March" },
                                    { "label": "April", "value": "April" },
                                    { "label": "May", "value": "May" },
                                    { "label": "June", "value": "June" },
                                    { "label": "July", "value": "July" },
                                    { "label": "August", "value": "August" },
                                    { "label": "September", "value": "September" },
                                    { "label": "October", "value": "October" },
                                    { "label": "November", "value": "November" },
                                    { "label": "December", "value": "December" },
                                ]
                            }, {
                                'order': 3,
                                'id': 'intake_year',
                                'type': 'select',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Intake Year',
                                'value': '',
                                'placeholder': 'Intake Year',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please select an option.',
                                'icon': '',
                                'options': [
                                    { "label": "2021", "value": "2021" },
                                    { "label": "2022", "value": "2022" },
                                    { "label": "2023", "value": "2023" },
                                    { "label": "2024", "value": "2024" },
                                    { "label": "2025", "value": "2025" },
                                    { "label": "2026", "value": "2026" },
                                    { "label": "2027", "value": "2027" },
                                    { "label": "2028", "value": "2028" },
                                    { "label": "2029", "value": "2029" },
                                    { "label": "2030", "value": "2030" },
                                ]
                            },

                            {
                                'order': 4,
                                'id': 'source',
                                'type': 'select',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Source',
                                'value': '',
                                'placeholder': 'Source',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please select an option.',
                                'icon': '',
                                'options': [
                                    { "label": "direct", "value": "direct" },
                                    { "label": "others", "value": "others" },

                                ]
                            }, {
                                'order': 5,
                                'id': 'other_applied_course',
                                'type': 'textarea',
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                                'label': 'Comment',
                                'value': '',
                                'placeholder': 'Comment',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please enter valid Course name',
                                'icon': 'business',
                                "textLimit":251

                            }
                        ]
                    }, {
                        name: "Personal Details",
                        title: "Personal Details",
                        input: [{
                            'order': 0,
                            'id': 'first_name',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'First Name',
                            'value': '',
                            'placeholder': 'First Name',
                            'error': false,
                            'mandatory': 1,
                            'errorText': 'Please enter valid first name',
                            'icon': 'business',
                            "pattern":/[a-zA-Z]/,
                            "textLimit":35

                        }, {
                            'order': 1,
                            'id': 'middle_name',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Middle Name',
                            'value': '',
                            'placeholder': 'Middle Name',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid middle name.',
                            'icon': 'business',
                            "pattern":/[a-zA-Z]/,
                            "textLimit":35
                        }, {
                            'order': 2,
                            'id': 'last_name',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Last Name',
                            'value': '',
                            'placeholder': 'Last Name',
                            'error': false,
                            'mandatory': 1,
                            'errorText': 'Please enter valid last name.',
                            'icon': 'business',
                            "pattern":/[a-zA-Z]/,
                            "textLimit":35
                        }, {
                            'order': 3,
                            'id': 'email',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Email Address',
                            'value': '',
                            'placeholder': 'email id',
                            'error': false,
                            'mandatory': 1,
                            'errorText': 'Please enter valid email.',
                            'icon': 'business',
                            'pattern':/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                            
                        }, {
                            'order': 4,
                            'id': 'phone_number',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Mobile',
                            'value': '',
                            'placeholder': 'Mobile no.',
                            'error': false,
                            'mandatory': 1,
                            'errorText': 'Please enter valid phone number.',
                            'icon': 'business',
                            'pattern':/[0-9]/,
                            "textLimit":15
                        }, {
                            'order': 5,
                            'id': 'marital_status',
                            "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                            'type': 'radio',
                            'label': 'Marital Status',
                            'value': 'permanence',
                            'placeholder': 'Marital Status',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please select option.',
                            'icon': '',
                            'options': [
                                { "label": "Single", "value": "single" },
                                { "label": "Married", "value": "married" },
                            ]
                        }]
                    },

                    {
                        name: "Previous Education",
                        title: "Previous Education",
                        input: [
                            {
                                'order': 0,
                                'id': 'home_university',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                                'label': 'Home University',
                                'value': '',
                                'placeholder': 'Home University',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please enter valid university name',
                                'icon': 'business',
                                "pattern":/\w+/,
                                "textLimit":51

                            },


                            {
                                'order': 1,
                                'id': 'degree_name',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Exact title of qualification',
                                'value': '',
                                'placeholder': 'Ex: BE Electronics and Telecommunication',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please enter valid degree and cours name',
                                'icon': 'business',
                                "pattern":/\w+/,
                                "textLimit":51



                            },
                            {
                                'order': 2,
                                'id': 'degree_type',
                                'type': 'select',
                                "layout": { "lg": 6, "md": 6, "sm": 6, "xs": 6 },
                                'label': 'Qualification Type',
                                'value': '',
                                'placeholder': 'Qualification Type',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please select degree type',
                                'icon': '',
                                'options': [
                                    { "label": "Masters", "value": "Masters" },
                                    { "label": "Bachelors", "value": "Bachelors" },
                                    { "label": "10th /O level", "value": "SSC" },
                                    { "label": "12th /A level /IB", "value": "HSC" },
                                ]
                            },
                            {
                                'order': 3,
                                'id': 'completed_year',
                                'type': 'date',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'When was your degree issued?',
                                'value': '',
                                'placeholder': 'Ex: 3',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please enter valid year.',
                                'icon': 'business',

                            },
                            {
                                'order': 4,
                                'id': 'score',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Marks',
                                'value': '',
                                'placeholder': 'Marks in %',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please enter valid percent.',
                                'icon': 'business',
                                "pattern":/^\s*(\d{0,2})(\.?(\d*))?\s*\%?\s*$/,
                                "textLimit":5


                            },
                            {
                                'order': 5,
                                'id': 'file',
                                'type': 'file',
                                'fileName': 'eduFileDegree',
                                'accept':'.png, .jpg, .jpeg, .pdf',
                                "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Upload Degree/Diploma document',
                                'value': '',
                                'error': false,
                                'mandatory': 0,

                            },
                            {
                                'order': 6,
                                'id': 'file_1',
                                'type': 'file',
                                'fileName': 'eduFileMarksheet',
                                'accept':'.png, .jpg, .jpeg, .pdf',
                                "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Transcript/Marksheet',
                                'value': '',
                                'error': false,
                                'mandatory': 0,

                            },
                            {
                                'order': 7,
                                'id': 'college_name',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'College Name',
                                'value': '',
                                'placeholder': 'College Name',
                                'error': false,
                                'mandatory': 1,
                                'errorText': 'Please enter valid college name',
                                'icon': 'business',
                                "pattern":/\w+/,
                                "textLimit":51


                            },
                        ]
                    },
                    {
                        name: "Language Proficiency",
                        title: "Language Proficiency",
                        input: [
                            {
                                'order': 0,
                                'id': 'study_option',
                                'type': 'select',
                                "layout": { "lg": 6, "md": 6, "sm": 6, "xs": 6 },
                                'label': 'Language Proficiency Type',
                                'value': '',
                                'placeholder': 'Language Proficiency Type',
                                'error': false,
                                'mandatory': 0,
                                'errorText': 'Please select Language Proficiency type',
                                'icon': '',
                                'options': [
                                    { "label": "IELTS", "value": "ielts" },
                                    { "label": "TOEFL", "value": "toefl" },
                                    { "label": "PTE", "value": "pte" },

                                ]
                            },
                            {
                                'order': 1,
                                'id': 'overall',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Overall',
                                'value': '',
                                'placeholder': 'Ex: 6',
                                'error': false,
                                'mandatory': 0,
                                'errorText': 'Please enter valid score.',
                                'icon': 'business',
                                "pattern":/^[1-9]{1,3}(\.\d)?$/,
                                "textLimit":3


                            },
                            {
                                'order': 2,
                                'id': 'listening',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Listening',
                                'value': '',
                                'placeholder': 'Ex: 6',
                                'error': false,
                                'mandatory': 0,
                                'errorText': 'Please enter valid score.',
                                'icon': 'business',
                                "pattern":/^[1-9]{1,3}(\.\d)?$/,
                                "textLimit":3



                            },
                            {
                                'order': 3,
                                'id': 'reading',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Reading',
                                'value': '',
                                'placeholder': 'Ex: 6',
                                'error': false,
                                'mandatory': 0,
                                'errorText': 'Please enter valid score.',
                                'icon': 'business',
                                "pattern":/^[1-9]{1,3}(\.\d)?$/,
                                "textLimit":3



                            },
                            {
                                'order': 4,
                                'id': 'speaking',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Speaking',
                                'value': '',
                                'placeholder': 'Ex: 6',
                                'error': false,
                                'mandatory': 0,
                                'errorText': 'Please enter valid score.',
                                'icon': 'business',
                                "pattern":/^[1-9]{1,3}(\.\d)?$/,
                                "textLimit":3

                            },
                            {
                                'order': 5,
                                'id': 'writing',
                                'type': 'input',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Writing',
                                'value': '',
                                'placeholder': 'Ex: 6',
                                'error': false,
                                'mandatory': 0,
                                'errorText': 'Please enter valid score.',
                                'icon': 'business',
                                "pattern":/^[1-9]{1,3}(\.\d)?$/,
                                "textLimit":3


                            },

                            {
                                'order': 6,
                                'id': 'file',
                                'type': 'file',
                                'fileName': 'langFile',
                                'accept':'.png, .jpg, .jpeg, .pdf',
                                "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                                'label': 'Upload Language proficiency document',
                                'value': '',
                                'error': false,
                                'mandatory': 0,
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },

                            }
                        ]
                    },
                    {
                        name: "Experience",
                        title: "Experience",
                        input: [{
                            'order': 0,
                            'id': 'company_name',
                            'type': 'input',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Company Name',
                            'value': '',
                            'placeholder': 'Enter company name which you have worked for.',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid value',
                            'icon': 'business',
                            "pattern":/\w+/,
                            "textLimit":51

                        },
                        //  {
                        //     'order': 1,
                        //     'id': 'experience',
                        //     'type': 'input',
                        //     "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        //     'label': 'Duration',
                        //     'value': '',
                        //     'placeholder': 'Enter duration in years Ex: 2',
                        //     'error': false,
                        //     'mandatory': 1,
                        //     'errorText': 'Please enter valid duration.',
                        //     'icon': 'business',
                        // },
                        {
                            'order': 1,
                            'id': 'start_exp_year',
                            'type': 'date',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Start Date',
                            'value': '',
                            'placeholder': 'Ex: 3/11/21',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid year.',
                            'icon': 'business',
                            'dateFilter':'previousDates'

                        },
                        {
                            'order': 2,
                            'id': 'completed_exp_year',
                            'type': 'date',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'End Date',
                            'value': '',
                            'placeholder': 'Ex: 3/11/21',
                            'error': false,
                            'mandatory': 0,
                            'errorText': 'Please enter valid year.',
                            'icon': 'business',
                            'compare':1,
                            'dateFilter':'previousDates'

                        },
                        {
                            'order': 3,
                            'id': 'file_2',
                            'type': 'file',
                            'fileName': 'expFile',
                            'accept':'.png, .jpg, .jpeg, .pdf',
                            "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                            'label': 'Upload experience document',
                            'value': '',
                            'error': false,
                            'mandatory': 0,
                            "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },

                        }
                        ]
                    },
                    {
                        name: "Documents",
                        title: "Documents",
                        input: [

                            {
                                'order': 0,
                                'id': 'file',
                                'type': 'file',
                                'fileName': 'cvDoc',
                                'label': 'CV',
                                'accept':'.png, .jpg, .jpeg, .pdf',
                                // 'accept':'.png, .jpg, .jpeg, .pdf',
                                'value': '',
                                'error': false,
                                'mandatory': 0,
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                                "group": "cv"


                            },
                            {
                                'order': 1,
                                'id': 'file',
                                'type': 'file',
                                'fileName': 'passportDoc',
                                'label': 'Passport',
                                'accept':'.png, .jpg, .jpeg, .pdf',
                                'value': '',
                                'error': false,
                                'mandatory': 0,
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                                "group": "passport"

                            },
                            {
                                'order': 2,
                                'id': 'file',
                                'type': 'file',
                                'fileName': 'sopDoc',
                                'label': 'SOP',
                                'accept':'.png, .jpg, .jpeg, .pdf',
                                'value': '',
                                'error': false,
                                'mandatory': 0,
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                                "group": "sop"

                            },
                            {
                                'order': 3,
                                'id': 'file',
                                'type': 'file',
                                'fileName': 'lorDoc',
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                                'label': 'Letter of recommendation',
                                'value': '',
                                'accept':'.png, .jpg, .jpeg, .pdf',
                                'error': false,
                                'mandatory': 0,
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                                "group": "lor"


                            },
                            {
                                'order': 4,
                                'id': 'file',
                                'type': 'file',
                                'fileName': 'otherDoc',
                                'label': 'Additional documents',
                                'accept':'.png, .jpg, .jpeg, .pdf',
                                'value': '',
                                'error': false,
                                'mandatory': 0,
                                "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                                "group": "other"


                            }

                        ]
                    },

                ]
            };
    }
    componentDidMount() {
        const { match: { params }, courses } = this.props
        let org_id = params.org_id
        this.props.courseactions.getOrganizationStudentsCourses(org_id);
        this.props.sourceAction.getOrgSource(org_id);
    }

    static getDerivedStateFromProps(props, state) {
        const { sourceList, history: { location: { search } } } = props
        const splitAPA = search.split('=')
        const APAname = splitAPA[2]
        console.log("params", splitAPA[2])

        let sources = []
        if (state.sources !== sourceList) {
            sources = sourceList.map(item => {
                return { "label": item.apa_name, "value": item.id }
            });
            let { cards } = state;

            const input = cards[0].input;

            input[4].options = sources;
            if (APAname != 'undefined' && APAname) {
                let obj = sources.find(o => o.label === APAname);
                input[4].value = obj
            }
            else {
                cards[0].input = input;
            }
            return {
                sources,
                cards
            }
        }
    }
    updateValue = (e, key) => {
        if (key !== undefined) {
            let { cards, step } = this.state;
            const input = cards[step].input;
            input[key].value = e.target.value;
            cards[step].input = input;
            validation([cards[step].input[key]],cards[step].input)
            this.setState({
                cards,
            });
        }
    };
    updateStep = (newStep) => {
        const { cards, step } = this.state;
        console.log("step=====",step);
        console.log("newStep=====",newStep);
        let stepInput = cards.length === step ? cards.length - 1 : step
        const
            input = newStep==6 || (newStep==5 && step != 4) ? validation(cards[stepInput].input) : validation(cards[stepInput].input,cards[step].input)
        cards[stepInput].input = input;
        this.setState({
            cards,
        });
        if (input.valid) {
            this.setState({ step: newStep });
        }
    }

    onDropFile = (file, name, key,group) => {
        const formData = new FormData()
        formData.append('doc_file', file[0]);

        const handleSuccess = (body) => {
            Toast.show({
                message: "File uploaded Successfully",
                timeout: 4000,
                intent: 'success'
            })
            if (key !== undefined) {
                let { cards, step, lorDocFile, sopDocFile, otherDocFile } = this.state;
                const input = cards[step].input;
              
                    if (group == "lor") {
                        lorDocFile.push({

                            doc_url: `${CLOUDFROND_ASSETS3_URL}/${body.data}`,
                            verified: 0,
                            description: ""
                        }
                        )
                    }
                    if (group == "sop") {


                        sopDocFile.push({
                            SOP_doc_url: `${CLOUDFROND_ASSETS3_URL}/${body.data}`,
                            SOP_verified: 0,
                            description: ''
                        }
                        )
                    }
                        if(group == "other") {

                            otherDocFile.push({

                                other_documents_url: `${CLOUDFROND_ASSETS3_URL}/${body.data}`,
                                other_verified: 0,
                                description: ''
                            }
                            )

                        
                        }
                        input[key].value = file[0];
                        input[key].path = body.data;
                        cards[step].input = input;
                        this.setState({
                            cards,
                            lorDocFile,
                            sopDocFile,
                            otherDocFile
                        });


                    }
                }
                const handleFailure = () => {
                    Toast.show({
                        message: "File upload failed",
                        timeout: 100000,
                        intent: 'danger'
                    })
                }
                this.props.integrationAction.documentUpload(handleSuccess, handleFailure, formData)
            }
        
            
            handleExperience = (experienceData) => {
                let expData = []
                if (experienceData && experienceData.length) {
                    // function chunkArray(array, size) {
                    //     let result = []
                    //     let arrayCopy = [...array]
                    //     while (arrayCopy.length > 0) {
                    //         result.push(arrayCopy.splice(0, size))
                    //     }
                    //     return result
                    // }
                    // function splitArry(array, size){
                    //     let arr = []
                    //     for(let i = 0; i < array.length; i++){
                    //       if(array[i].type!='button'){
                    //             arr.push(array[i])
                    //       }
                    //     }
                    //     return chunkArray(arr, size)

                    // }
                    let newArr = this.splitArray(experienceData, 4)

                    for (let i = 0; i < newArr.length; i++) {

                        if(newArr[i][0].type!='button'){
                            expData.push(
                                //    { "degree_name": newArr[i][0].value,
                                //     "degree_type": newArr[i][2].value.value,
                                //     "completed_year": newArr[i][3].value,
                                //     "doc_url_degree":`${CLOUDFROND_ASSETS3_URL}/${newArr[i][5].path}`,
                                //     "doc_url_marksheet":`${CLOUDFROND_ASSETS3_URL}/${newArr[i][6].path}`,
                                //     "score": newArr[i][4].value,
                                //     "verified": 0}
                                {

                                    "company_name": newArr[i][0].value,
                                    "experience": moment(newArr[i][1].value).diff(newArr[i][2].value, 'years'),
                                    "started_year": newArr[i][1].value,
                                    "completed_year": newArr[i][2].value,
                                    "exp_doc_url": `${CLOUDFROND_ASSETS3_URL}/${newArr[i][3].path}`,
                                    "exp_verified": null
                                }
                            )
                        }

                        // home_university,degree_name,degree_type,completed_year,score,file,file_1
                    }
                }

                return expData
            }
            chunkArray = (array, size)=>{
                let result = []
                let arrayCopy = [...array]
                while (arrayCopy.length > 0) {
                    result.push(arrayCopy.splice(0, size))
                }
                return result
            }
            splitArray = (array, size)=>{
               if(array && array.length){
                        let arr = []
                        for(let i = 0; i < array.length; i++){
                        if(array[i].type!='button'){
                                arr.push(array[i])
                        }
                        }
               return  this.chunkArray(arr, size)
             

                }

            }

            handleEducation = (educationsData) => {
                console.log(educationsData,"educationsData");
                let eduData = []
                if (educationsData && educationsData.length) {
                    console.log("ifffff",educationsData);
                    // function chunkArray(array, size) {
                    //     let result = []
                    //     let arrayCopy = [...array]
                    //     while (arrayCopy.length > 0) {
                    //         console.log(arrayCopy,"arrayCopyarrayCopyarrayCopy");
                    //         result.push(arrayCopy.splice(0, size))
                    //     }
                    //     return result
                    // }
                    let newArr = this.splitArray(educationsData, 8)
                    for (let i = 0; i < newArr.length; i++) {
                    console.log(newArr,"newAreduuuuurnewArr");

                    

                        eduData.push(
                            {
                                "degree_name": newArr[i][1].value,
                                "degree_type": newArr[i][2].value.value,
                                "completed_year": newArr[i][3].value,
                                "college_name": newArr[i][7].value,
                                "doc_url_degree": `${CLOUDFROND_ASSETS3_URL}/${newArr[i][5].path}`,
                                "doc_url_marksheet": `${CLOUDFROND_ASSETS3_URL}/${newArr[i][6].path}`,
                                "score": `${newArr[i][4].value}`,
                                "verified": 0
                            }
                        )

                        // home_university,degree_name,degree_type,completed_year,score,file,file_1
                    
                }
                }
                return eduData
            }

            submit = () => {
                const { match: { params }, sourceList } = this.props
                const queryParams = new URLSearchParams(this.props.location.search);
                const id = queryParams.get('id') ? queryParams.get('id') : '';
                const { cards,
                    lorDocFile,
                    sopDocFile,
                    otherDocFile
                } = this.state;
                const { user: { user }, user: { organization } } = this.props
                const eduDetails = cards[2].input
                let eduData = this.handleEducation(cards[2].input)
                let expData = this.handleExperience(cards[4].input)
                console.log(eduData,"eduDataeduData");
                console.log("cards[0]cards[0]cards[0], ", cards[0], cards[0].input[5], cards[0].input[5].value)

                // const edudata = eduDetails.map(edu=>{
                //     return (
                //         {
                //             "degree_name": eduDetails[i+1].value,
                //             "degree_type": eduDetails[i+2].value.value,
                //             "completed_year": eduDetails[i+3].value,
                //             "doc_url_degree":`${CLOUDFROND_ASSETS3_URL}/${eduDetails[i].path}`,
                //             "doc_url_marksheet":`${CLOUDFROND_ASSETS3_URL}/${eduDetails[i].path}`,
                //             "score": eduDetails[i+4].value,
                //             "verified": 0
                //         }
                //     )
                // })
                // for(let i = 0; i<eduDetails.length; i++){
                //     if(i%7==0 || i==0){
                //     eduData.push(
                //    { "degree_name": eduDetails[i+1].value,
                //     "degree_type": eduDetails[i+2].value.value,
                //     "completed_year": eduDetails[i+3].value,
                //     "doc_url_degree":`${CLOUDFROND_ASSETS3_URL}/${eduDetails[i].path}`,
                //     "doc_url_marksheet":`${CLOUDFROND_ASSETS3_URL}/${eduDetails[i].path}`,
                //     "score": eduDetails[i+4].value,
                //     "verified": 0}
                //     )
                // }
                //     // home_university,degree_name,degree_type,completed_year,score,file,file_1
                // }
                const form =
                {
                    "first_name": cards[1].input[0].value,
                    "middle_name": cards[1].input[1].value,
                    "last_name": cards[1].input[2].value,
                    "organization_id": params.org_id,
                    "financial_capacity": null,
                    "marital_status": cards[1].input[5].value,
                    "v_id": null,
                    "address": null,
                    "email": cards[1].input[3].value,
                    "phone_number": cards[1].input[4].value,
                    "gap": 0,
                    "regional_cap": null,
                    "student_status": "1",
                    "status": null,
                    "assigned_id": null,
                    "user_id": id,
                    "applied_course": cards[0].input[1].value,
                    "course_type": cards[0].input[0].value.value,
                    "intake_month": cards[0].input[2].value.value,
                    "intake_year": cards[0].input[3].value.value,
                    "other_applied_course": cards[0].input[5].value,
                    "permanence_type": null,
                    "student_comment": null,
                    "gender": null,
                    "dob": null,
                    "country": null,
                    "provice_state": null,
                    "city_town": null,
                    "postal_code": null,
                    "home_university": cards[2].input[0].value,
                    "come_to_know_us": null,
                    "factor_consider": null,
                    "payment_method": null,
                    "identity_proof": null,
                    "organization_id": params.org_id ? params.org_id: '',
                    'zoomabroad':null,
                    "source_id": cards[0].input[4].value.value,
                    "passport_doc_url": `${CLOUDFROND_ASSETS3_URL}/${cards[5].input[1].path}`,
                    "cv_doc_url": `${CLOUDFROND_ASSETS3_URL}/'cvdocuments'/${cards[5].input[0].path}`,
                    "academic":
                        // [

                        //     {
                        //         "degree_name": cards[2].input[1].value,
                        //         "degree_type": cards[2].input[2].value.value,
                        //         "completed_year": cards[2].input[3].value,
                        //         "doc_url_degree":`${CLOUDFROND_ASSETS3_URL}/${cards[2].input[5].path}`,
                        //         "doc_url_marksheet":`${CLOUDFROND_ASSETS3_URL}/${cards[2].input[6].path}`,
                        //         "score": cards[2].input[4].value,
                        //         "verified": 0
                        //     }
                        // ],
                        eduData,
                    "experience":
                        // [
                        //     {

                        //         "company_name": cards[4].input[0].value,
                        //         // "experience": cards[4].input[1].value,
                        //         "start_exp_year": cards[4].input[1].value,
                        //         "end_exp_year": cards[4].input[2].value,
                        //         "exp_doc_url":`${CLOUDFROND_ASSETS3_URL}/${cards[4].input[2].path}`,
                        //         "exp_verified": null
                        //     }
                        // ],
                        expData,
                    "letter_of_recommendation":lorDocFile,
                    // "letter_of_recommendation": [
                    //     {

                    //         doc_url :`${CLOUDFROND_ASSETS3_URL}/${cards[5].input[3].path}`,
                    //         verified :0,
                    //         description :""
                    //     }
                    // ],
                    "sop": sopDocFile,
                    // sopDocFile,
                    // "Other_documents": [
                    //     {

                    //         Other_doc_url :`${CLOUDFROND_ASSETS3_URL}/${cards[5].input[4].path}`,
                    //         Other_verified :0,
                    //         description:''


                    //     }
                    // ],
                   "other_documents": otherDocFile,

                    "key_highlights": [
                        {
                            "key_highlights": null,
                            "level": 3
                        }
                    ],
                    "language_efficiency": [
                        {
                            "ielts_score": cards[3].input[0].value.value == 'ielts' ? cards[3].input[1].value : null,
                            "toefl_score": cards[3].input[0].value.value == 'toefl' ? cards[3].input[1].value : null,
                            "pte_score": cards[3].input[0].value.value == 'pte' ? cards[3].input[1].value : null,
                            "listening": cards[3].input[2].value,
                            "speaking": cards[3].input[4].value,
                            "writing": cards[3].input[5].value,
                            "reading": cards[3].input[3].value,
                            "exemptions_equivalent": null,
                            "second_language": null,
                            "lang_doc_url": `${CLOUDFROND_ASSETS3_URL}/${cards[3].input[6].path}`
                        }],
                    "competetive_test": [
                        {
                            "gre": null,
                            "gmat": null,
                            "sat": null,
                            "act": null
                        },

                    ]
                };
                const handleCreateSuccess = () => {
                    Toast.show({
                        message: "Form submitted Successfully",
                        timeout: 4000,
                        intent: 'success'
                    })
                    let sourceName = ''
                let sourceShartName = ''

                const n_Data = [
                    'APAEI20124',
                    'APAEI20129',
                    'APAEI20127',
                    'APAEI20126',
                    'APACL20121',
                    'APAAEI202113'
                ]

                const b_Data = [
                    'APAEO201211',
                    'APAV20123',
                    'APAI20128',
                    'APAEC201210',
                    'APACG20125'
                ]
                const s_Data = [
                    'APAIEE20122'
                ]
                const notificationData = {
                    "apa_name": '',
                    "student_name": `${cards[1].input[0].value}`,
                    "email": ''
                }
                if (cards[0].input[4]) {
                    let a = sourceList.map(item => {
                        if (item.id == cards[0].input[4].value.value) {
                            sourceName = item.apa_name
                            sourceShartName = item.source_short_name
                        }
                    })

                    if (sourceName) {
                        for (let n of n_Data) {
                            if (sourceName == n) {
                                notificationData.email = 'admissions2@zoomabroad.com'
                                notificationData.apa_name = sourceShartName
                                this.props.integrationAction.createFormStudentNotification(notificationData, () => { }, () => { })
                            }
                        }
                        for (let b of b_Data) {
                            if (sourceName == b) {
                                notificationData.email = 'Bhavna.katore@zoomabroad.com'
                                notificationData.apa_name = sourceShartName
                                this.props.integrationAction.createFormStudentNotification(notificationData, () => { }, () => { })

                            }
                        }
                        for (let s of s_Data) {
                            if (sourceName == s) {
                                notificationData.email = 'Sunita.jalan@zoomabroad.com'
                                notificationData.apa_name = sourceShartName
                                this.props.integrationAction.createFormStudentNotification(notificationData, () => { }, () => { })

                            }
                        }
                    }
                }
                this.props.history.push('/studentsuccess')
                }
                const handleFailure = () => {
                    Toast.show({
                        message: "Could not submit form, Please try again",
                        timeout: 100000,
                        intent: 'danger'
                    })
                }
                console.log(form,"&&&&&&&&&&&&&");

                this.props.integrationAction.createZilterFormStudent(form, handleCreateSuccess, handleFailure)
                //notification email cards[0].input[4].value.value
                
            }
            onCloseModal = () => {
                this.setState({
                    showEduModal: false
                })
            }
            onOpenEduModal = () => {
                this.setState({
                    showEduModal: true
                })
            }
            OnSaveEdu = () => {
                const { educations, cards } = this.state
                const data = {
                    "degree_name": cards[2].input[1].value,
                    "degree_type": cards[2].input[2].value.value,
                    "completed_year": cards[2].input[3].value,
                    "doc_url_degree": `${CLOUDFROND_ASSETS3_URL}/${cards[2].input[5].path}`,
                    "doc_url_marksheet": `${CLOUDFROND_ASSETS3_URL}/${cards[2].input[6].path}`,
                    "score": cards[2].input[4].value,
                    "verified": 0
                }
                educations.push(data)
                this.setState({
                    educations
                })

            }
            onAddSop = (input)=>{
             
                let { otherDetails, educations, cards } = this.state;

                const nextItem = input.length-1;
                cards[5].input.push(
                    {
                        'order':  nextItem + 1,
                        'id': 'file',
                        'type': 'file',
                        'fileName': 'sopDoc',
                        'accept':'.png, .jpg, .jpeg, .pdf',
                        'label': 'SOP',
                        'value': '',
                        'error': false,
                        'mandatory': 0,
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        "group": "sop"

                    },

                )
                this.setState({

                    cards
                }
                )
            }
            onAddLor = (input)=>{
             
                let { otherDetails, educations, cards } = this.state;

                const nextItem = input.length-1;
                cards[5].input.push(
                    {
                        'order': nextItem + 1,
                        'id': 'file',
                        'type': 'file',
                        'fileName': 'lorDoc',
                        'label': 'Letter of recommendation',
                        'value': '',
                        'accept':'.png, .jpg, .jpeg, .pdf',
                        'error': false,
                        'mandatory': 0,
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        "group": "lor"


                    },

                )
                this.setState({

                    cards
                }
                )
            }
            onClickAddDoc = (input) => {
                let { otherDetails, educations, cards } = this.state;

                const nextItem = input.length-1;
                cards[5].input.push(
                    {
                        'order': nextItem + 1,
                        'id': `otherDoc${nextItem}`,
                        'type': 'file',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': `Additional Document`,
                        'value': '',
                        'fileName': `otherDoc${nextItem}`,
                        'accept':'.png, .jpg, .jpeg, .pdf',
                        'placeholder': 'additional document',
                        'error': false,
                        'mandatory': 0,
                        "group":"other"

                    }

                )
                this.setState({

                    cards
                }
                    // , () => {
                    //     this.setState((prevState) => ({
                    //         otherDetails: prevState.otherDetails.concat(nextItem)
                    //     }))
                    // }
                )
            }

            onClickAdd = (input) => {
                let { otherDetails, educations, cards } = this.state;
                const nextItem = input.length;

                cards[2].input.push(
                    {
                        'order': nextItem + 0,
                        'id': `home_university${nextItem}`,
                        'type': 'input',
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'label': 'Home university',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid university name',
                        'icon': 'business',
                        "pattern":/\w+/,
                        "textLimit":51

                    }, {
                    'order': nextItem + 1,
                    'id': `degree_name${nextItem}`,
                    'type': 'input',
                    "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                    'label': 'Title of qualification',
                    'value': '',
                    'placeholder': 'Ex: BE Electronics and Telecommunication',
                    'error': false,
                    'mandatory': 1,
                    'errorText': 'Please enter valid degree and cours name',
                    'icon': 'business',
                    "pattern":/\w+/,
                    "textLimit":35

                },
                    {
                        'order': nextItem + 2,
                        'id': `degree_type${nextItem}`,
                        'type': 'select',
                        "layout": { "lg": 6, "md": 6, "sm": 6, "xs": 6 },
                        'label': 'Qulification type',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please select degree type',
                        'icon': '',
                        'options': [
                            { "label": "Masters", "value": "Masters" },
                            { "label": "Bachelors", "value": "Bachelors" },
                            { "label": "10th /O level", "value": "SSC" },
                            { "label": "12th /A level /IB", "value": "HSC" },
                        ]
                    },
                    {
                        'order': nextItem + 3,
                        'id': `completed_year${nextItem}`,
                        'type': 'date',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'When was your degree issued?',
                        'value': '',
                        'placeholder': 'Ex: 3',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid year.',
                        'icon': 'business',
                    },
                    {
                        'order': nextItem + 4,
                        'id': `score${nextItem}`,
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Marks',
                        'value': '',
                        'placeholder': 'Marks in %',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid percent.',
                        'icon': 'business',
                        'pattern':/[0-9]/,
                        "textLimit":3

                        
                    },
                    {
                        'order': nextItem + 5,
                        'id': `file${nextItem}`,
                        'type': 'file',
                        'fileName': 'eduFileDegree',
                        'accept':'.png, .jpg, .jpeg, .pdf',
                        "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Upload Degree/Diploma document',
                        'value': '',
                        'error': false,
                        'mandatory': 0,

                    },
                    {
                        'order': nextItem + 6,
                        'id': `file_1${nextItem}`,
                        'type': 'file',
                        'fileName': 'eduFileMarksheet',
                        'accept':'.png, .jpg, .jpeg, .pdf',
                        "layout": { "lg": 6, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Transcript/Marksheet',
                        'value': '',
                        'error': false,
                        'mandatory': 0,

                    },
                    {
                        'order': nextItem + 7,
                        'id': `college_name${nextItem}`,
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'College name',
                        'value': '',
                        'placeholder': 'Name',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid college name',
                        'icon': 'business',
                        'pattern':/\w+/,
                        "textLimit":51


                    },
                    {
                        'order': nextItem + 8,
                        'type':"button",
                        'text':"Remove",
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'onRemove':'education'

                    }
                    
                )
                this.setState({

                    cards

                    // [` otherDetalsUniversity${nextItem}`]: '',
                    // [`otherDetailsDegree${nextItem}`]: '',
                    // [` otherDetailsDegreeType${nextItem}`]: '',
                    // [`otherDetailsYear${nextItem}`]: '',
                    // [`otherDetailsScore${nextItem}`]: '',
                    // [`otherDetailsFile${nextItem}`]: '',

                }, () => {
                    this.setState((prevState) => ({
                        otherDetails: prevState.otherDetails.concat(nextItem)
                    }))
                })
            }
            onClickAddExp = (input) => {
                let { otherDetails, educations, cards } = this.state;
                const nextItem = input.length;

                cards[4].input.push(
                    {
                        'order': nextItem + 0,
                        'id': `company_name${nextItem}`,
                        'type': 'input',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Company Name',
                        'value': '',
                        'placeholder': 'Enter company name which you have worked for.',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid value',
                        'icon': 'business',
                        'pattern':/\w+/,
                        "textLimit":51


                    },
                    {
                        'order': nextItem + 1,
                        'id': `start_exp_year${nextItem}`,
                        'type': 'date',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Start Date',
                        'value': '',
                        'placeholder': 'Ex: 3/11/21',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid year.',
                        'icon': 'business',
                    },
                    {
                        'order': nextItem + 2,
                        'id': `completed_exp_year${nextItem}`,
                        'type': 'date',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'End Date',
                        'value': '',
                        'placeholder': 'Ex: 3/11/21',
                        'error': false,
                        'mandatory': 1,
                        'errorText': 'Please enter valid year.',
                        'icon': 'business',
                    },

                    {
                        'order': nextItem + 3,
                        'id': `file${nextItem}`,
                        'type': 'file',
                        'fileName': 'expFile',
                        "layout": { "lg": 12, "md": 6, "sm": 12, "xs": 12 },
                        'label': 'Upload experience document',
                        'accept':'.png, .jpg, .jpeg, .pdf',
                        'value': '',
                        'error': false,
                        'mandatory': 0,
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },

                    },
                    {
                        'order': nextItem + 4,
                        'type':"button",
                        'text':"Remove",
                        "layout": { "lg": 12, "md": 12, "sm": 12, "xs": 12 },
                        'onRemove':'experience'
                    }
                )
                this.setState({

                    cards

                    // [` otherDetalsUniversity${nextItem}`]: '',
                    // [`otherDetailsDegree${nextItem}`]: '',
                    // [` otherDetailsDegreeType${nextItem}`]: '',
                    // [`otherDetailsYear${nextItem}`]: '',
                    // [`otherDetailsScore${nextItem}`]: '',
                    // [`otherDetailsFile${nextItem}`]: '',

                }, () => {
                    this.setState((prevState) => ({
                        otherDetails: prevState.otherDetails.concat(nextItem)
                    }))
                })
            }
            onClickRemove = (item) => {
                let { otherDetails } = this.state;
                const itemIndex = otherDetails.indexOf(item)
                const isValuePresent = itemIndex > -1;
                if (isValuePresent) {
                    otherDetails.splice(itemIndex, 1);
                    this.setState({
                        otherDetails
                    })
                }
            }

            onRemoveDoc = (index)=>{
                const {cards} =this.state
                // cards[5].input.splice(index,1)
              

                // var apps = [{id:34,name:'My App',another:'thing'},{id:37,name:'My New App',another:'things'}];

            // get index of object with id:37
            let removeIndex =  cards[5].input.map(function(item) { return item.order }).indexOf(index.order);

            // remove object
            cards[5].input.splice(removeIndex, 1);
            this.setState({
                cards
            })
            }

            RemoveEducation = (index)=>{
                const {cards} = this.state
            let removeIndex =  cards[2].input.map(function(item) { return item.order }).indexOf(index.order);
            cards[2].input.splice(removeIndex-8, 9);

                // cards[2].input.splice(index-8,9)
                this.setState({cards})

            }

            RemoveExperience = (index)=>{
                const {cards} = this.state
            let removeIndex =  cards[4].input.map(function(item) { return item.order }).indexOf(index.order);
            cards[4].input.splice(removeIndex-4, 5);

                // cards[2].input.splice(index-8,9)
                this.setState({cards})

            }
            render() {
                const { cards, step, eduFileDegree, showEduModal, educations, otherDetails } = this.state;
                // console.log(cards[5].input[1].path,"cards[5].input[1]cards[5].input[1]");
                return (
                    <>
                        {/* <Modal
                            modalTitle={"Course Data"}
                            isModalOpen={showEduModal}
                            onCloseModal={this.onCloseModal}
                        >
            <FormGenerator
                    formData={educations}
                    updateValue={this.updateValue}
                    onDropFile={this.onDropFile}
                />
                <Button onClick={this.OnSaveEdu} >Save</Button>
            </Modal> */}
                        <FormMarkup
                            isLoading={this.props.isLoading}
                            isLoadingIntegration={this.props.isLoadingIntegration}
                            updateValue={this.updateValue}
                            cards={cards}
                            step={step}
                            updateStep={this.updateStep}
                            submit={this.submit}
                            onDropFile={this.onDropFile}
                            summaryStep={5}
                            onOpenEduModal={this.onOpenEduModal}
                            educations={educations}
                            onClickAdd={this.onClickAdd}
                            otherDetails={otherDetails}
                            onClickRemove={this.onClickRemove}
                            onClickAddExp={this.onClickAddExp}
                            onClickAddDoc={this.onClickAddDoc}
                            onAddLor={this.onAddLor}
                            onAddSop={this.onAddSop}
                            onRemoveDoc={this.onRemoveDoc}
                            RemoveEducation={this.RemoveEducation}
                            RemoveExperience={this.RemoveExperience}
                        />
                    </>
                )
            }
        }

        const mapStateToProps = (state) => {
            return {
                isLoading: state.Auth.isLoading,
                isLoadingIntegration: state.Integration.isLoading,
                isCSV: state.students.isLoading,
                error: state.Auth.error,
                user: state.Auth.user,
                courses: state.courses.courseList,
                sourceList: state.source.sourceList

            }
        }
        const mapDispatchToProps = (dispatch) => {
            return {
                actions: bindActionCreators(actions, dispatch),
                integrationAction: bindActionCreators(integrationAction, dispatch),
                courseactions: bindActionCreators(courseactions, dispatch),
                sourceAction: bindActionCreators(sourceAction, dispatch),
            };
        };

        export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZilterForm));
