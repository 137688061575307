import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Toast } from '../../../../shared'
import actions from '../../modules/actions'
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import './AttendingMeeting.scss';

class AttendingMeeting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            description: '',
            subject: '',
            id: '',
            errors: {}
        }
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.actions.attendingMeeting(params.id, () => { }, () => { })
        this.props.actions.getStudent(params.id);

    }

    static getDerivedStateFromProps(props, state) {
        // attendMeeting
        console.log("appRejectedappRejected", props.attendMeeting)

        // if (props.attendMeeting && props.attendMeeting.length) {
        //     if (props.attendMeeting[0].id != state.id) {
        //         return {
        //             meeting_description: props.attendMeeting[0].meeting_description,
        //             id: props.attendMeeting[0].id
        //         };
        //     }
        // }
    }

    isvalid = () => {
        const { description, subject } = this.state;
        let errors = {};

        if (description == '') {
            errors.description = true;
            this.setState({ errors });
            return false;
        }
        if (subject == '') {
            errors.subject = true;
            this.setState({ errors });
            return false;
        }


        return true;
    };

    onSubmit = () => {
        const { match: { params }, userProfile, student, onCloseModal } = this.props;
        const { description, subject } = this.state;
        console.log("asdasdasd")
        
            const taskList = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'meeting_schedule': false,
                'attending_meeting': true,
                'required_documents': false,
                'visa_appointment': false,
                'opportunity': false,
                'select_offer': false,
            }
            const ZAtaskList = {
                'user_id': student ? student.za_id : null,
                'meeting_schedule': false,
                'attending_meeting': true,
                'required_documents': false,
                'visa_appointment': false,
                'opportunity': false,
                'select_offer': false,
                'qualifying': false,
    
            }
            const handleSuccess = () =>{
                Toast.show({
                    message: "Attend meeting notification send successfully!",
                    timeout: 4000,
                    intent: 'success'
                  })
            }
            const handleFailure = () =>{
                Toast.show({
                    message: "Attend meeting notification send unsuccessfull!",
                    timeout: 4000,
                    intent: 'danger'
                  })
            }
            this.props.actions.taskAllocation(taskList, () => { }, () => { })
            if(ZAtaskList.user_id){
                this.props.actions.ZAtaskAllocation(ZAtaskList, handleSuccess, handleFailure)
            }
            
    }
    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors: {}
        })

    }

    render() {
        const { description, errors, subject } = this.state;
        const { attendMeeting } = this.props

        return (
            <div className="balance-fee">
                {attendMeeting && attendMeeting ?
                    <>
                        <Col>
                            <h5>Meeting Subject :</h5> <p>{attendMeeting.meeting_subject}</p>
                        </Col>
                        <Col>
                            <h5> Meeting Description :</h5> <p>{attendMeeting.meeting_description}</p>
                        </Col>
                        <Col>
                            <h5> Meeting Time :</h5> <p>{moment(attendMeeting.meeting_time).format('MMMM Do YYYY, h:mm a')}</p>
                        </Col>
                        <Col>
                            <h5> Meeting Link : </h5><a href={attendMeeting.meeting_link} target="_blank">{attendMeeting.meeting_link}</a>
                        </Col>
                        <Col>
                            <h5> Created At :</h5> <p>{moment(attendMeeting.created_at).format('MMMM Do YYYY, h:mm a')}</p>
                        </Col>
                        <Col className="button-save">
                            <Button className="save-offer" onClick={() => this.onSubmit()}>Attend Notification</Button>
                        </Col>
                    </> :
                    ''}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        attendMeeting: state.students.attendMeeting,
        student: state.students.student,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AttendingMeeting));
