import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReportMarkup from '../components/ReportMarkup';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import actions from '../modules/actions'
import Denied from '../../../shared/denied/Denied'
import moment from 'moment';
class Cohort extends Component {
	constructor(props) {
		super(props);
		this.state = {
			viewAccess: true,
			AllstudentApplicationseries: [],
			AllstudentApplicationoptions: {},
			APAstudentApplicationseries: [],
			APAstudentApplicationoptions: {},
			AllstudentApplicationLabel:[],
			APAstudentApplicationLabel:[]
		};
	}


	static getDerivedStateFromProps(props, state) {
		const { user, cohortdata } = props
		let AllstudentApplicationseries = []
		let AllstudentApplicationoptions = {}
		let APAstudentApplicationseries = []
		let APAstudentApplicationoptions = {}
		let AllstudentApplicationLabel=[]
		let APAstudentApplicationLabel=[]

		const access = user.organization.access

		let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }, {
			"name": "DASHBOARD",
			"operations": [
				1,
				0,
				0,
				0
			]
		}, {
			"name": "COUNSELOR-REPORT",
			"operations": [
				1,
				0,
				0,
				0
			]
		}]
		const dashboard = _.find(finalData, { "name": "COUNSELOR-REPORT" })

		let viewAccess = false

		if (dashboard && dashboard) {
			if (dashboard.operations[0] == 1) {
				viewAccess = true
			}
		}

		if (!user.user.is_counselor) {
			viewAccess = true
		}

		if (cohortdata && cohortdata.all_students_applications && cohortdata.all_students_applications.length) {
			let sorted = _.sortBy(cohortdata.all_students_applications, ['std_created_app_date']);
			let dateArr = []
			let countArr = []
			let viewAccess = false

			if (dashboard && dashboard) {
				if (dashboard.operations[0] == 1) {
					viewAccess = true
				}
			}
			if (!user.user.is_counselor) {
				viewAccess = true
			}
			for (let i = 0; i < sorted.length; i++) {

				dateArr.push(sorted[i].std_created_app_date)
				countArr.push(sorted[i].count)

			}
			console.log(dateArr, "dateArr", countArr, "count");
			AllstudentApplicationLabel = dateArr;
			AllstudentApplicationoptions.chart = {
				height: 350,
				type: 'area',
				toolbar: {
					export: {
					  csv: {
						filename:'All_students_application',
						headerCategory: 'Date;Time',
						columnDelimiter: ';',
						// dateFormatter: function(timestamp) {
						//   var date = dayjs(new Date(timestamp));
						//   var format = 'ddd D. MMM;HH:mm'  // sic: Delimiter in here on purpose
						//   var nextHour = Number(date.hour()) + 1;
						//   var text = date.format(format) + ' - ' + nextHour + ':00';
						//   return text;
						// },
					  }
					},
				}
			}
			AllstudentApplicationoptions.dataLabels = {
				enabled: false
			}
			AllstudentApplicationoptions.stroke = {
				curve: 'smooth'
			}
			console.log("109 dateArr",dateArr)
			AllstudentApplicationoptions.xaxis = {
				type: 'date',
				categories: dateArr
			}
			AllstudentApplicationoptions.tooltip = {
				x: {
					format: 'dd/MM/yy HH:mm'
				}
			}
			AllstudentApplicationoptions.title = {
				text: 'Number of All Applications',
				align: 'left'
			}
			AllstudentApplicationseries.push({
				name: 'Applications',
				data: countArr
			})

		}

		if (cohortdata && cohortdata.APA_students_applications && cohortdata.APA_students_applications.length) {
			let sorted = _.sortBy(cohortdata.APA_students_applications, ['std_created_app_date']);
			let dateArr = []
			let countArr = []
			let viewAccess = false

			if (dashboard && dashboard) {
				if (dashboard.operations[0] == 1) {
					viewAccess = true
				}
			}
			if (!user.user.is_counselor) {
				viewAccess = true
			}
			for (let i = 0; i < sorted.length; i++) {

				dateArr.push(sorted[i].std_created_app_date)
				countArr.push(sorted[i].count)

			}
			console.log(dateArr, "dateArr", countArr, "count");
			APAstudentApplicationLabel = dateArr;
			APAstudentApplicationoptions.chart = {
				height: 350,
				type: 'area',
				toolbar: {
					export: {
					  csv: {
						filename:'APA_students_application',	
						headerCategory: 'category',
						headerValue: 'value',
						dateFormatter: (timestamp) => {
							console.log("timestamp",timestamp)
						//   var date = dayjs(new Date(timestamp));
						//   var format = 'ddd D. MMM;HH:mm'  // sic: Delimiter in here on purpose
						//   var nextHour = Number(date.hour()) + 1;
						//   var text = date.format(format) + ' - ' + nextHour + ':00';
						  return timestamp;
						},
					  }
					},
				}
			}
			APAstudentApplicationoptions.dataLabels = {
				enabled: false
			}
			APAstudentApplicationoptions.stroke = {
				curve: 'smooth'
			}
			APAstudentApplicationoptions.xaxis = {
				type: 'date',
				categories: dateArr
			}
			APAstudentApplicationoptions.tooltip = {
				x: {
					format: 'dd/MM/yy HH:mm'
				}
			}
			APAstudentApplicationoptions.title = {
				text: 'Number of APA Applications',
				align: 'left'
			}
			APAstudentApplicationseries.push({
				name: 'Applications',
				data: countArr
			})

		}
		
		return {
			AllstudentApplicationseries,
			AllstudentApplicationoptions,
			APAstudentApplicationseries,
			APAstudentApplicationoptions,
			AllstudentApplicationLabel,
			APAstudentApplicationLabel,
			viewAccess
		}
	}

	componentDidMount() {
		this.props.actions.getCohortReport();
	}

	render() {
		const { viewAccess, AllstudentApplicationseries,AllstudentApplicationLabel,APAstudentApplicationLabel, AllstudentApplicationoptions, APAstudentApplicationseries, APAstudentApplicationoptions } = this.state
		const { isLoading, cohortdata } = this.props
		return (
			viewAccess ?
				<>
					<ReportMarkup
						isLoading={isLoading}
						cohortAlldataSeries={cohortdata.all_students_registration && cohortdata.all_students_registration.data}
						cohortAlldataOptions={cohortdata.all_students_registration && cohortdata.all_students_registration.categories ? cohortdata.all_students_registration.categories : []}
						cohortAPAdataSeries={cohortdata.APA_registration && cohortdata.APA_registration.data}
						cohortAPAdataOptions={cohortdata.APA_registration && cohortdata.APA_registration.categories ? cohortdata.APA_registration.categories : []}
						AllstudentApplicationseries={AllstudentApplicationseries}
						AllstudentApplicationoptions={AllstudentApplicationoptions}
						APAstudentApplicationseries={APAstudentApplicationseries}
						APAstudentApplicationoptions={APAstudentApplicationoptions}
						AllstudentApplicationLabel={AllstudentApplicationLabel}
						APAstudentApplicationLabel={APAstudentApplicationLabel}
					/>
				</>
				: <Denied />
		)
	}
}
const mapStateToProps = (state) => {
	return {
		isLoading: state.cohort.isLoading,
		user: state.Auth.user,
		cohortdata: state.cohort.cohortdata,
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch),
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cohort));