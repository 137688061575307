import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Toast } from '../../../../shared'
import actions from '../../modules/actions'
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import './SchedulingMeeting.scss';

class SchedulingMeeting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            description: '',
            subject: '',
            id: '',
            errors: {}
        }
    }
    componentDidMount() {
        const { match: { params }, userProfile, student } = this.props;
        this.props.actions.getStudent(params.id);
    }

    static getDerivedStateFromProps(props, state) {

    }

    isvalid = () => {
        const { description, subject } = this.state;
        let errors = {};

        if (description == '') {
            errors.description = true;
            this.setState({ errors });
            return false;
        }
        if (subject == '') {
            errors.subject = true;
            this.setState({ errors });
            return false;
        }


        return true;
    };

    onSubmit = () => {
        const { match: { params }, userProfile, student , onCloseModal} = this.props;
        const { description, subject } = this.state;
        console.log("asdasdasd")
        if (this.isvalid()) {

            const taskList = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'meeting_schedule': true,
                'qualifying':false,
                'attending_meeting':false,
                'required_documents': false,
                'visa_appointment': false,
                'opportunity': false,
                'select_offer': false,
            }

            const data = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                // 'organization_id': userProfile.organization.organization_id,
                "meeting_time": "",
                "meeting_link": "",
                "meeting_subject": subject,
                "meeting_description": description,
                "meeting_feedback":{}
            }

            const ZAtaskList = {
                'user_id': student ? student.za_id : null,
                'meeting_schedule': true,
                'qualifying':false,
                'attending_meeting':false,
                'required_documents': false,
                'visa_appointment': false,
                'opportunity': false,
                'select_offer': false,
            }
            const zadata = {
                'user_id': student ? student.za_id : null,
                'counsellor_id': userProfile.organization.user_id,
                "meeting_time": "",
                "meeting_link": "",
                "meeting_subject": subject,
                "meeting_description": description,
                "meeting_date":'',
                "user_email":''
            }
            const handlesuccessfull = () => {
                this.setState({
                    description: '',
                    subject: '',
                    id: ''
                })
                Toast.show({
                    message: "Task allocate successfully!",
                    timeout: 4000,
                    intent: 'success'
                  })
                onCloseModal()
            }
            const handleFailure = () => {
                this.setState({
                    description: '',
                    subject: '',
                    id: ''
                })
                Toast.show({
                    message: "Task allocate unsuccessfully!",
                    timeout: 4000,
                    intent: 'danger'
                  })
                onCloseModal()
            }

            this.props.actions.taskAllocation(taskList, () => { }, () => { })
            this.props.actions.consellorMeeting(data, handlesuccessfull, handleFailure)
            if(ZAtaskList.user_id){
                this.props.actions.ZAtaskAllocation(ZAtaskList, () => { }, () => { })
                this.props.actions.ZAconsellorMeeting(zadata, handlesuccessfull, handleFailure)
            }
           


        }
    }
    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors: {}
        })

    }
    render() {
        const { description, errors, subject } = this.state;
        const { } = this.props

        return (
            <div className="balance-fee">
                <Col>
                    <Form.Label className="title">Meeting Subject *</Form.Label>
                    <Form.Control
                        placeholder="Enter Subject"
                        type="text"
                        className="form-control text-box"
                        value={subject}
                        onChange={(event) => this.onChangeInput('subject', event.target.value)}
                    />
                    {errors.subject ? <span className="errors">Please Enter Meeting Subject</span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Meeting Description *</Form.Label>
                    <Form.Control
                        as='textarea'
                        row='2'
                        placeholder="Enter Description"
                        type="text"
                        className="form-control text-box"
                        value={description}
                        onChange={(event) => this.onChangeInput('description', event.target.value)}
                    />
                    {errors.description ? <span className="errors">Please Enter Description</span> : ''}
                </Col>

                <Col className="button-save">
                    <Button className="save-offer" onClick={() => this.onSubmit()}>Allocate</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        student: state.students.student,

    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SchedulingMeeting));
