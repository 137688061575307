import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Accordion, Card, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { CLOUDFROND_ASSETS3_URL } from '../../../../../env'
import integrationAction from '../../../../Integration/modules/actions'
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './Lost.scss';


class Lost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lostValue: '',
            other_agent: false,
            not_satisfied: false,
            not_qualified: false,
            onter_uni: false,
            other: false,
            id: '',
            docUrl: '',
            docFile: '',
            errors:{}
        }
    }
    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppLost(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.lost && props.lost.length) {
            if (props.lost[0].id != state.id) {
                return {
                    lostValue: props.lost[0].lost_comment,
                    other_agent: props.lost[0].other_agent,
                    not_satisfied: props.lost[0].not_satisfied,
                    not_qualified: props.lost[0].not_qualified,
                    onter_uni: props.lost[0].other_uni,
                    other: props.lost[0].other,
                    docUrl: props.lost[0].lost_doc,
                    id: props.lost[0].id
                };
            }
        }
    }
    clearForm = () =>{
        this.setState({
            lostValue: '',
            other_agent: false,
            not_satisfied: false,
            not_qualified: false,
            onter_uni: false,
            other: false,
            id: '',
            docUrl: '',
            docFile: '',
            errors:{} 
        })
        this.props.setClearForm()
    }
    isvalid = () => {
        const { lostValue, other_agent, not_satisfied, not_qualified, other, onter_uni, docFile } = this.state;
        let errors = {};
        if (onter_uni == '' && other_agent == '' && not_satisfied == '' && not_qualified == '' && other == '') {
            errors.losterror = true;
            this.setState({ errors });
            return false;
        }
        if(/* !other == '' &&  */lostValue == ''){
            errors.lostValue = true;
            this.setState ({ errors });
            return false;
          }
        if (docFile == '') {
            errors.docFile = true;
            this.setState({ errors });
            return false;
        }
        
        return true;
    };
    onSubmit = () => {
        const { lostValue, other_agent, not_satisfied, not_qualified, other, onter_uni, docUrl, docFile } = this.state;
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if(this.isvalid()){
        if (docFile) {

            const formData = new FormData()
            formData.append('doc_file', docFile);

            const handleSuccess = (response) => {
                const data = {
                    'student_id': params.id,
                    'user_id': userProfile.organization.user_id,
                    'organization_id': userProfile.organization.organization_id,
                    'lost_comment': lostValue,
                    'other_agent': other_agent,
                    'not_satisfied': not_satisfied,
                    'not_qualified': not_qualified,
                    'other': other,
                    'other_uni': onter_uni,
                    'lost_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                    'application_id': applicationStatusId
                }
                const handlesuccessfull = () => {
                    Toast.show({
                        message: 'Save successfully',
                        timeout: 4000,
                        intent: 'success'
                    });
                    onCloseModal()
                                  this.setState({
                                    lostValue: '',
                                    other_agent: false,
                                    not_satisfied: false,
                                    not_qualified: false,
                                    onter_uni: false,
                                    other: false,
                                    id: '',
                                    docUrl: '',
                                    docFile: '', 
                    })
                };
                const handleFailure = () => {
                    Toast.show({
                        message: 'Save unsuccessfully',
                        timeout: 4000,
                        intent: 'danger'
                    });
                    onCloseModal()
                                  this.setState({
                                    lostValue: '',
                                    other_agent: false,
                                    not_satisfied: false,
                                    not_qualified: false,
                                    onter_uni: false,
                                    other: false,
                                    id: '',
                                    docUrl: '',
                                    docFile: '',  
                    })
                }
                this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'lost')
            }
            const handleDocFailure = () => { }
            this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formData)
        }
        else {
            const data = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'lost_comment': lostValue,
                'other_agent': other_agent,
                'not_satisfied': not_satisfied,
                'not_qualified': not_qualified,
                'other': other,
                'other_uni': onter_uni,
                'lost_doc': docUrl,
                'application_id': applicationStatusId
            }
            const handlesuccessfull = () => {
                Toast.show({
                    message: 'Save successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                onCloseModal()
                              this.setState({
                                lostValue: '',
                                other_agent: false,
                                not_satisfied: false,
                                not_qualified: false,
                                onter_uni: false,
                                other: false,
                                id: '',
                                docUrl: '',
                                docFile: '',  
                    })
            };
            const handleFailure = () => {
                Toast.show({
                    message: 'Save unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                onCloseModal()
                              this.setState({
                                lostValue: '',
                                other_agent: false,
                                not_satisfied: false,
                                not_qualified: false,
                                onter_uni: false,
                                other: false,
                                id: '',
                                docUrl: '',
                                docFile: '',  
                    })
            }
            this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'lost')
        }
    }
    }

    onChangeInput = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    userDocument = (doc_url) => {
        if (doc_url) {
            return (
                <a href={`${doc_url}`}>
                    <span>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        </OverlayTrigger>
                    </span>
                </a>

            );
        }
    }
    render() {
        const { lostValue, other_agent, not_satisfied, not_qualified, other, onter_uni, docUrl, errors } = this.state;
        const { clearModel } =this.props
        if(!clearModel){
            this.clearForm()
        }
        return (
            <div className="conditional">
                <Col>
                    <Form.Label className="title">Reason *</Form.Label>
                    <Row>
                        <Form.Group >
                            <Form.Check
                                name="other_uni"
                                type="checkbox"
                                label="Choose other uni"
                                className="check-box"
                                value={onter_uni}
                                checked={onter_uni ? true : false}
                                onChange={(event) => this.onChangeInput('onter_uni', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="other_agent"
                                type="checkbox"
                                label="Choose other agent"
                                className="check-box"
                                value={other_agent}
                                checked={other_agent ? true : false}

                                onChange={(event) => this.onChangeInput('other_agent', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="not_satisfied"
                                type="checkbox"
                                label="Not satisfied"
                                className="check-box"
                                value={not_satisfied}
                                checked={not_satisfied ? true : false}

                                onChange={(event) => this.onChangeInput('not_satisfied', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="not_qualified"
                                type="checkbox"
                                label="Not qualified"
                                className="check-box"
                                value={not_qualified}
                                checked={not_qualified ? true : false}

                                onChange={(event) => this.onChangeInput('not_qualified', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="other"
                                type="checkbox"
                                label="Other"
                                className="check-box"
                                value={other}
                                checked={other ? true : false}
                                onChange={(event) => this.onChangeInput('other', event.target.checked)}
                            />
                        </Form.Group>
                    </Row>
                    {errors.losterror ? <span className="errors">Please Select Appropriate Language Check Box</span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Comment *</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Click here enter reason"
                        className="form-control text-box"
                        value={lostValue}
                        onChange={(event) => this.onChangeInput('lostValue', event.target.value)}
                    />
                    {errors.lostValue ? <span className="errors">Please Enter the Reason</span> : ''}
                </Col>
                {
                    docUrl && docUrl ? <Col>
                        {this.userDocument(docUrl)}
                    </Col> : ''}
                <Col>
                    <Form.File
                        id="custom-file"
                        label="Upload Document *"
                        val
                        accept=".png, .jpg, .jpeg, .pdf"
                        name="docFile"
                        onChange={this.onDropFile}
                    />
                </Col>
                {errors.docFile ? <span className="errors">Please Upload Document File (".png, .jpg, .jpeg, .pdf")</span> : ''}
                <Col>
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        lost: state.students.lost
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lost));
