import {
    UPDATE_SOURCE_REQUEST,
    UPDATE_SOURCE_FAIL,
    UPDATE_SOURCE_SUCCESS
} from './type';

// import { HUBURL, URL } from '../../../config';
import {APISAAS_DOMAIN} from '../../../env'

const updateOrgUser = (data, id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: UPDATE_SOURCE_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/organizationusers/updateuser/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: UPDATE_SOURCE_FAIL,
                        payload: body.errorsupdatesource
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: UPDATE_SOURCE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}

const updateUser = (data, id, handleSuccess, handleFailure) => {
    return (dispatch, getState) => {
        const { Auth: { user: { token } } } = getState();
        dispatch({
            type: UPDATE_SOURCE_REQUEST
        });
        fetch(`${APISAAS_DOMAIN}api/user/update/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => response.json().then(body => ({ response, body })))
            .then(({ response, body }) => {
                if (!response.ok) {
                    dispatch({
                        type: UPDATE_SOURCE_FAIL,
                        payload: body.errorsupdatesource
                    });
                    handleFailure(response, body);
                } else {
                    dispatch({
                        type: UPDATE_SOURCE_SUCCESS,
                        payload: body
                    });
                    handleSuccess(body);
                }
            });
    }
}
export default {
  
    updateOrgUser,
    updateUser

}