import React from 'react'
import { withRouter } from 'react-router-dom';
import './LeftDrawer.scss'

import { OverlayTrigger, Popover } from 'react-bootstrap';
const LeftDrawer = (props) => {

    const userHandler = (userRoleAccess, act) => {
        console.log(userRoleAccess, "++++________))))))))")
        if (userRoleAccess) {
            for (let i = 0; i < userRoleAccess.length; i++) {
                if (act == "question" && userRoleAccess[i].name == "QUESION" && (userRoleAccess[i].operations[0] == 1 || userRoleAccess[i].operations[1] == 1 || userRoleAccess[i].operations[2] == 1 || userRoleAccess[i].operations[3] == 1)) {
                    return true
                }
                if (act == "interview" && userRoleAccess[i].name == "INTERVIEW" && (userRoleAccess[i].operations[0] == 1 || userRoleAccess[i].operations[1] == 1 || userRoleAccess[i].operations[2] == 1 || userRoleAccess[i].operations[3] == 1)) {
                    return true
                }
                if (act == "course" && userRoleAccess[i].name == "COURSE" && (userRoleAccess[i].operations[0] == 1 || userRoleAccess[i].operations[1] == 1 || userRoleAccess[i].operations[2] == 1 || userRoleAccess[i].operations[3] == 1)) {
                    return true
                }
                if (act = "student" && userRoleAccess[i].name == "STUDENTS" && (userRoleAccess[i].operations[0] == 1 || userRoleAccess[i].operations[1] == 1 || userRoleAccess[i].operations[2] == 1 || userRoleAccess[i].operations[3] == 1)) {
                    return true
                }

            }
        }
    }

    const { userRoleAccess } = props
    const access = userRoleAccess.organization.access
    let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }]
    const wizard = props.history.location.pathname == '/ziltersteps' ? true : false;
    console.log(userRoleAccess, "******************88")
    return (
        <>
            {wizard ? '' :
                <div className="left-drawer">
                    <div className="inner-container-drawer">
                        {/* <a href="/dashboard">
                    <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Popover className="tool-tip-drawer" id="tooltip-disabled">Dashboard</Popover>}
                >
                    <span icon="application" class="bp3-icon bp3-icon-application bp3-tree-node-icon"><svg data-icon="application" width="16" height="16" viewBox="0 0 16 16"><desc>application</desc><path d="M3.5 7h7c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-7c-.28 0-.5.22-.5.5s.22.5.5.5zM15 1H1c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1zm-1 12H2V5h12v8zM3.5 9h4c.28 0 .5-.22.5-.5S7.78 8 7.5 8h-4c-.28 0-.5.22-.5.5s.22.5.5.5zm0 2h5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-5c-.28 0-.5.22-.5.5s.22.5.5.5z" fill-rule="evenodd"></path></svg></span>
                </OverlayTrigger>
                </a> */}
                        {userRoleAccess.user.is_superuser ? <a href="/integration"><OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Popover className="tool-tip-drawer" id="tooltip-disabled">Integration</Popover>}
                        >
                            <span icon="link" class="bp3-icon bp3-icon-link bp3-tree-node-icon"><svg data-icon="link" width="16" height="16" viewBox="0 0 16 16"><desc>link</desc><path d="M4.99 11.99c.28 0 .53-.11.71-.29l6-6a1.003 1.003 0 00-1.42-1.42l-6 6a1.003 1.003 0 00.71 1.71zm3.85-2.02L6.4 12.41l-1 1-.01-.01c-.36.36-.85.6-1.4.6-1.1 0-2-.9-2-2 0-.55.24-1.04.6-1.4l-.01-.01 1-1 2.44-2.44c-.33-.1-.67-.16-1.03-.16-1.1 0-2.09.46-2.81 1.19l-.02-.02-1 1 .02.02c-.73.72-1.19 1.71-1.19 2.81 0 2.21 1.79 4 4 4 1.1 0 2.09-.46 2.81-1.19l.02.02 1-1-.02-.02c.73-.72 1.19-1.71 1.19-2.81 0-.35-.06-.69-.15-1.02zm7.15-5.98c0-2.21-1.79-4-4-4-1.1 0-2.09.46-2.81 1.19l-.02-.02-1 1 .02.02c-.72.72-1.19 1.71-1.19 2.81 0 .36.06.69.15 1.02l2.44-2.44 1-1 .01.01c.36-.36.85-.6 1.4-.6 1.1 0 2 .9 2 2 0 .55-.24 1.04-.6 1.4l.01.01-1 1-2.43 2.45c.33.09.67.15 1.02.15 1.1 0 2.09-.46 2.81-1.19l.02.02 1-1-.02-.02a3.92 3.92 0 001.19-2.81z" fill-rule="evenodd"></path></svg></span>
                        </OverlayTrigger>
                        </a> : ''}

                        {userHandler(finalData, "question") ? <a href="/questions"><OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Popover className="tool-tip-drawer" id="tooltip-disabled">Question</Popover>}
                        >
                            <span icon="help" class="bp3-icon bp3-icon-help bp3-tree-node-icon"><svg data-icon="help" width="16" height="16" viewBox="0 0 16 16"><desc>help</desc><path d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13H7v-2h2v2zm1.93-6.52c-.14.32-.35.64-.62.97L9.25 8.83c-.12.15-.24.29-.28.42-.04.13-.09.3-.09.52V10H7.12V8.88s.05-.51.21-.71L8.4 6.73c.22-.26.35-.49.44-.68.09-.19.12-.38.12-.58 0-.3-.1-.55-.28-.75-.18-.19-.44-.28-.76-.28-.33 0-.59.1-.78.29-.19.19-.33.46-.4.81-.03.11-.1.15-.2.14l-1.7-.25c-.12-.01-.16-.08-.14-.19.12-.82.46-1.47 1.03-1.94.57-.48 1.32-.72 2.25-.72.47 0 .9.07 1.29.22s.72.34 1 .59c.28.25.49.55.65.89.15.35.22.72.22 1.12s-.07.75-.21 1.08z" fill-rule="evenodd"></path></svg></span>
                        </OverlayTrigger>
                        </a> : ''}
                        {userHandler(finalData, "interview") ? <a href="/interviews"><OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Popover className="tool-tip-drawer" id="tooltip-disabled">Interview</Popover>}
                        >
                            <span icon="mobile-video" class="bp3-icon bp3-icon-people bp3-tree-node-icon"> <svg data-icon="mobile-video" width="16" height="16" viewBox="0 0 16 16"><desc>mobile-video</desc><path d="M15 4c-.28 0-.53.11-.71.29L12 6.59V4c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V9.41l2.29 2.29c.18.19.43.3.71.3.55 0 1-.45 1-1V5c0-.55-.45-1-1-1z" fill-rule="evenodd"></path></svg></span>
                        </OverlayTrigger>
                        </a> : ''}
                        {userHandler(finalData, "course") ? <a href="/course">
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Popover className="tool-tip-drawer" id="tooltip-disabled">Course</Popover>}
                            >
                                <span icon="learning" class="bp3-icon bp3-icon-learning bp3-tree-node-icon"><svg data-icon="learning" width="16" height="16" viewBox="0 0 16 16"><desc>learning</desc><path d="M8.441 1.104a.985.985 0 00-.882 0L.365 5c-.487.253-.487.747 0 1L7.56 9.896a.985.985 0 00.882 0L15.635 6c.487-.253.487-.747 0-1L8.44 1.104z" fill-rule="evenodd"></path><path d="M14 5.5l.016 4.514c.002.548.447.99.994.99a.99.99 0 00.99-.99V5.5h-2zM3.371 9.047l4.387 2.432a.5.5 0 00.485 0l4.39-2.432a.25.25 0 01.371.218v2.955a.25.25 0 01-.134.222l-4.635 2.436a.5.5 0 01-.466 0l-4.635-2.436A.25.25 0 013 12.22V9.265a.25.25 0 01.371-.218z" fill-rule="evenodd"></path></svg></span>
                            </OverlayTrigger>
                        </a> : ''}
                        {userHandler(finalData, "student") ? <a href="/students"> <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Popover className="tool-tip-drawer" id="tooltip-disabled">Students</Popover>}
                        >
                            <span icon="people" class="bp3-icon bp3-icon-people bp3-tree-node-icon"><svg data-icon="people" width="16" height="16" viewBox="0 0 16 16"><desc>people</desc><path d="M13.69 13.98c-.05-.24-.14-.5-.25-.76-.36-.86-1.12-1.33-2.69-2-.14-.06-.59-.25-.6-.25-.21-.09-.36-.15-.5-.22.02-.1.02-.2.03-.31 0-.04.01-.08.01-.13-.07-.06-.13-.12-.19-.19.22-.32.4-.67.54-1.05.02-.06.02-.06.03-.1.29-.23.48-.57.59-.96.16-.33.25-.73.21-1.16-.03-.4-.16-.76-.37-1.03-.02-.53-.07-1.13-.15-1.54-.01-.06-.02-.12-.03-.19.23-.06.48-.09.72-.09.49 0 1.05.16 1.44.46.38.29.67.7.8 1.17.03.1.05.21.07.31.07.37.11.94.11 1.33v.05c.14.06.27.21.29.51.02.25-.07.45-.13.54-.05.21-.16.44-.38.48-.02.1-.05.2-.09.3 0 .01-.01.03-.01.03-.17.44-.43.83-.75 1.11v.14c.03.35-.09.59.83 1 .93.41 2.32.84 2.6 1.5.29.66.17 1.04.17 1.04h-2.3zm-1.17-.38c.37.86.22 1.36.22 1.36H.06s-.14-.5.22-1.36 2.13-1.43 3.31-1.96c1.17-.54 1.05-.86 1.09-1.3 0-.05.01-.11.01-.17-.41-.35-.75-.86-.97-1.45v-.01s-.01-.01-.01-.02c-.04-.12-.09-.26-.12-.39-.28-.05-.44-.36-.5-.64-.06-.12-.19-.39-.16-.71.04-.41.21-.6.39-.68v-.06c0-.51.05-1.26.14-1.74.02-.13.05-.27.09-.4.17-.6.54-1.13 1.02-1.51.5-.39 1.21-.6 1.84-.6s1.34.21 1.84.6c.48.38.85.91 1.02 1.52.04.13.07.27.09.4.09.48.14 1.22.14 1.73v.07c.18.08.34.27.37.67.03.32-.09.59-.16.71-.06.28-.21.58-.48.63-.03.13-.07.26-.12.39 0 .01-.01.04-.01.04-.22.58-.55 1.08-.95 1.45v.18c.04.45-.12.77 1.06 1.3 1.18.53 2.95 1.09 3.31 1.95z" fill-rule="evenodd"></path></svg></span>
                        </OverlayTrigger>
                        </a> : ''}



                        {userRoleAccess.user.is_superuser ? <a href="/members"><OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Popover className="tool-tip-drawer" id="tooltip-disabled">Members</Popover>}
                        >
                            <span icon="inherited-group" class="bp3-icon bp3-icon-inherited-group bp3-tree-node-icon"><svg data-icon="inherited-group" width="15" height="15" viewBox="0 0 20 20"><desc>inherited-group</desc><path d="M1 9c.55 0 1 .45 1 1v4c0 1.1.9 2 2 2h2.59l-.3-.29a1.003 1.003 0 011.42-1.42l2 2c.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2 2A1.003 1.003 0 016 19c0-.28.11-.53.3-.71l.29-.29H4c-2.21 0-4-1.79-4-4v-4c0-.55.45-1 1-1zm6.996-9c.79 0 1.68.25 2.309.73a3.63 3.63 0 011.4 2.36c.11.6.17 1.52.17 2.15v.09c.22.09.42.32.47.82.03.39-.12.73-.2.87-.07.34-.27.71-.61.78-.04.16-.09.33-.15.48-.01.01-.02.05-.02.05-.27.71-.68 1.33-1.19 1.78 0 .08 0 .16.01.23.05.55-.15.95 1.33 1.6 1.469.66 3.698 1.35 4.178 2.39.45 1.05.27 1.67.27 1.67h-5.227a1.982 1.982 0 00-.319-.417l-2-2A2.003 2.003 0 005 15H4c-.548 0-1-.452-1-1v-1.462c.511-.213 1.023-.413 1.468-.608 1.479-.65 1.329-1.05 1.379-1.59l.01-.21c-.52-.45-.95-1.08-1.22-1.8l-.01-.01-.01-.03c-.07-.15-.12-.32-.16-.49-.34-.06-.54-.43-.62-.78-.08-.14-.24-.48-.2-.87.05-.51.26-.74.49-.83v-.08c0-.64.05-1.55.17-2.15a3.648 3.648 0 011.4-2.36C6.317.25 7.207 0 7.996 0zm5.997 3c.59 0 1.26.19 1.73.55.46.35.8.85.97 1.4.04.13.07.25.08.38.08.45.13 1.14.13 1.61v.07c.16.07.31.24.35.62.02.29-.09.55-.15.65-.05.26-.2.53-.46.59-.03.12-.07.25-.11.36-.01.01-.01.04-.01.04-.2.53-.51 1-.89 1.34 0 .06 0 .12.01.17.04.41-.11.71 1 1.19 1.099.5 2.768 1.01 3.128 1.79.34.79.2 1.25.2 1.25h-3.039V15c-.06-.33-.17-.69-.33-1.06-.45-.97-1.37-1.52-3.238-2.3-.17-.07-.76-.31-.77-.32-.1-.04-.2-.08-.28-.12.05-.14.04-.29.06-.45l.01-.16c-.25-.21-.47-.48-.65-.79.22-.34.41-.71.56-1.12l.028-.078-.002.013-.006.035.06-.15c.36-.26.6-.67.72-1.13.18-.37.29-.82.25-1.3-.05-.5-.21-.92-.47-1.22-.02-.53-.06-1.11-.12-1.59.38-.17.83-.26 1.24-.26z" fill-rule="evenodd"></path></svg></span>
                        </OverlayTrigger>
                        </a> : ''}
                        {/* <a href="/report"><OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Popover className="tool-tip-drawer" id="tooltip-disabled">Members</Popover>}
                    >
                        <span icon="chart" class="bp3-icon bp3-icon-chart bp3-tree-node-icon"><svg data-icon="chart" width="16" height="16" viewBox="0 0 16 16"><desc>chart</desc><path d="M0 15c0 .55.45 1 1 1h2c.55 0 1-.45 1-1V9.4L0 11v4zm6-5.5V15c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-5l-1 1-3-1.5zM13 7l-1 1v7c0 .55.45 1 1 1h2c.55 0 1-.45 1-1V7.88c-.26.07-.58.12-1 .12-1.96 0-2-1-2-1zm2-6h-3c-.55 0-1 .45-1 1s.45 1 1 1h.59L8.8 6.78 5.45 5.11v.01C5.31 5.05 5.16 5 5 5s-.31.05-.44.11V5.1l-4 2v.01C.23 7.28 0 7.61 0 8c0 .55.45 1 1 1 .16 0 .31-.05.44-.11v.01L5 7.12 8.55 8.9v-.01c.14.06.29.11.45.11.28 0 .53-.11.71-.29L14 4.41V5c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1z" fill-rule="evenodd"></path></svg></span>
                    </OverlayTrigger>
                    </a>             */}
                    </div>
                </div>
            }
        </>
    )
}

export default withRouter(LeftDrawer);