import React, { Component } from "react";
import { DateInput } from "@blueprintjs/datetime";
import { RadioGroup, Radio } from "@blueprintjs/core";
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { CLOUDFROND_ASSETS3_URL } from '../../../../../env'
import integrationAction from '../../../../Integration/modules/actions'
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './CasApplied.scss';
class CasApplied extends Component {
    constructor(props) {
        super(props)
        this.state = {
            casAppliedDate: new Date(),
            id: '',
            docFile: '',
            docUrl: '',
            bankdocFile: '',
            bankdocUrl: '',
            errors: {}
        }
    }

    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppCasApplied(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.casApplied && props.casApplied.length) {
            if (props.casApplied[0].id != state.id) {
                return {
                    casAppliedDate: props.casApplied[0].cas_applied_date ? new Date(props.casApplied[0].cas_applied_date): new Date(),
                    docUrl: props.casApplied[0].cas_applied_tb_doc,
                    bankdocUrl: props.casApplied[0].cas_bank_statement_doc,
                    id: props.casApplied[0].id
                };
            }
        }
    }
    onDropFile = (event) => {
        let name = event.target.name
        this.setState({
            [name]: event.target.files[0]
        });
    }
    clearForm = () =>{
        this.setState({
            casAppliedDate: new Date(),
            id: '',
            docFile: '',
            docUrl: '',
            bankdocFile: '',
            bankdocUrl: '',
            errors: {}
        })
        this.props.setClearForm()
    }
    handlesuccessfull = () => {
       const { onCloseModal } = this.props;
        Toast.show({
            message: 'Save successfully',
            timeout: 4000,
            intent: 'success'
        });
        onCloseModal()
        this.setState({
            casAppliedDate: new Date(),
            id: '',
            docFile: '',
            docUrl: '',
            bankdocFile: '',
            bankdocUrl: ''
        })
    };
    handleFailure = () => {
        const { onCloseModal } = this.props;
        
        Toast.show({
            message: 'Save unsuccessfully',
            timeout: 4000,
            intent: 'danger'
        });
        onCloseModal()
        this.setState({
            casAppliedDate: new Date(),
            id: '',
            docFile: '',
            docUrl: '',
            bankdocFile: '',
            bankdocUrl: '',
            errors: {}
        })
    }
    isvalid = () => {
        const { casAppliedDate, docFile, bankdocFile } = this.state;
        let errors = {};
        if (casAppliedDate == '') {
            errors.casAppliedDate = true;
            this.setState({ errors });
            return false;
        }
        if (docFile == '') {
            errors.docFile = true;
            this.setState({ errors });
            return false;
        }
        if (bankdocFile == '') {
            errors.bankdocFile = true;
            this.setState({ errors });
            return false;
        }
        return true;
    };

    onSubmit = () => {
        const { casAppliedDate, docFile, docUrl, bankdocFile, bankdocUrl } = this.state
        const { match: { params }, userProfile, applicationStatusId } = this.props;
        if (this.isvalid()) {
            if (docFile && bankdocFile) {
                const formData = new FormData()
                formData.append('doc_file', docFile);

                const formBankData = new FormData()
                formBankData.append('doc_file', bankdocFile);

                let docResponce = ''

                const handleSuccess = (response) => {
                    const data = {
                        'student_id': params.id,
                        'user_id': userProfile.organization.user_id,
                        'organization_id': userProfile.organization.organization_id,
                        'cas_applied_date': casAppliedDate,
                        'cas_applied_tb_doc': `${CLOUDFROND_ASSETS3_URL}/${docResponce.data}`,
                        'application_id': applicationStatusId,
                        'cas_bank_statement_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`
                    }

                    this.props.action.createApplicationStatus(data, this.handlesuccessfull, this.handleFailure, 'casapplied')
                }
                const handleDocFailure = () => { }

                const handleDocSuccess = (response) => {
                    docResponce = response
                    this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formBankData)
                }
                this.props.integrationAction.documentUpload(handleDocSuccess, handleDocFailure, formData)
            } else if (docFile) {
                const formData = new FormData()
                formData.append('doc_file', docFile);
                const handleSuccess = (response) => {
                    const data = {
                        'student_id': params.id,
                        'user_id': userProfile.organization.user_id,
                        'organization_id': userProfile.organization.organization_id,
                        'cas_applied_date': casAppliedDate,
                        'cas_applied_tb_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                        'application_id': applicationStatusId,
                        'cas_bank_statement_doc': bankdocUrl
                    }

                    this.props.action.createApplicationStatus(data, this.handlesuccessfull, this.handleFailure, 'casapplied')
                }
                const handleDocFailure = () => { }

                this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formData)

            } else if (bankdocFile) {

                const formBankData = new FormData()
                formBankData.append('doc_file', bankdocFile);

                const handleSuccess = (response) => {
                    const data = {
                        'student_id': params.id,
                        'user_id': userProfile.organization.user_id,
                        'organization_id': userProfile.organization.organization_id,
                        'cas_applied_date': casAppliedDate,
                        'cas_applied_tb_doc': docUrl,
                        'application_id': applicationStatusId,
                        'cas_bank_statement_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`
                    }

                    this.props.action.createApplicationStatus(data, this.handlesuccessfull, this.handleFailure, 'casapplied')
                }
                const handleDocFailure = () => { }

                this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formBankData)

            } else {
                const data = {
                    'student_id': params.id,
                    'user_id': userProfile.organization.user_id,
                    'organization_id': userProfile.organization.organization_id,
                    'cas_applied_date': casAppliedDate,
                    'cas_applied_tb_doc': docUrl,
                    'application_id': applicationStatusId,
                    'cas_bank_statement_doc': bankdocUrl
                }

                this.props.action.createApplicationStatus(data, this.handlesuccessfull, this.handleFailure, 'casapplied')
            }
        }
    }

    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors: {}
        })
    }
    userDocument = (doc_url) => {
        if (doc_url) {
            return (
                <a href={`${doc_url}`}>
                    <span>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        </OverlayTrigger>
                    </span>
                </a>

            );
        }
    }
    render() {
        const { casAppliedDate, docUrl, bankdocUrl, errors } = this.state;
        const { clearModel } =this.props
        if(!clearModel){
            this.clearForm()
        }
        return (
            <div className="visa-applied">
                <Col className="cas-applied-date">
                    <Label className="applied-date" htmlFor="input-b">CAS applied date *
                <DateInput
                            formatDate={date => moment(date).format("DD/MM/YYYY")}
                            onChange={(date) => this.onChangeInput("casAppliedDate", date)}
                            parseDate={str => new Date(str)}
                            placeholder={"DD/MM/YYYY"}
                            defaultValue={new Date()}
                            maxDate={new Date('1/1/2030')}
                            value={casAppliedDate}
                        />
                    </Label>
                    {errors.casAppliedDate ? <span className="errors">Please Select Appropriate CAS Applied Date</span> : ''}
                </Col>
                {
                    docUrl && docUrl ?
                        <Col className="download-doc">
                            {this.userDocument(docUrl)} Download
                        </Col>
                        : ''}
                <Col className="upload-document">
                    <Form.File
                        id="custom-file"
                        label="TB certificate *"
                        val
                        accept=".png, .jpg, .jpeg, .pdf"
                        name="docFile"
                        onChange={this.onDropFile}
                    />
                </Col>
                {errors.docFile ? <span className="errors">Please Upload Document File (".png, .jpg, .jpeg, .pdf")</span> : ''}
                {
                    bankdocUrl && bankdocUrl ?
                        <Col className="download-doc">
                            {this.userDocument(bankdocUrl)} Download
                        </Col>
                        : ''}
                <Col className="upload-document">
                    <Form.File
                        id="custom-file"
                        label="Bank statement *"
                        val
                        accept=".png, .jpg, .jpeg, .pdf"
                        name="bankdocFile"
                        onChange={this.onDropFile}
                    />
                </Col>
                {errors.bankdocFile ? <span className="errors">Please Upload Document File (".png, .jpg, .jpeg, .pdf")</span> : ''}
                <Col className="button-save">
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        casApplied: state.students.casApplied
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch),

    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CasApplied));
