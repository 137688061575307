import React, { Component } from "react";
import { Row, Col, Button, Image, Accordion, Card, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CLOUDFROND_ASSETS3_URL } from '../../../../../env'
import { bindActionCreators } from 'redux';
import integrationAction from '../../../../Integration/modules/actions'
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './FeeDeposit.scss';


class FeeDeposit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currencyType: '',
            totalTutionFee: '',
            firstYearFee: '',
            feeDeposit: '',
            scholarship: '',
            id: '',
            docFile: '',
            docUrl: '',
            errors:{}

        }
    }
    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppFeedeposit(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.feeDeposit && props.feeDeposit.length) {
            if (props.feeDeposit[0].id != state.id) {
                return {
                    currencyType: props.feeDeposit[0].currency_type,
                    totalTutionFee: props.feeDeposit[0].total_tuition_fee,
                    firstYearFee: props.feeDeposit[0].first_year_fee,
                    feeDeposit: props.feeDeposit[0].fee_deposit,
                    scholarship: props.feeDeposit[0].scholarship_discount,
                    id: props.feeDeposit[0].id,
                    docUrl: props.feeDeposit[0].receipt_doc
                };
            }
        }
    }
    onDropFile = (event) => {
        this.setState({
            docFile: event.target.files[0]
        });
    }
    clearForm = () =>{
        this.setState({
            currencyType: '',
            totalTutionFee: '',
            firstYearFee: '',
            feeDeposit: '',
            scholarship: '',
            id: '',
            docFile: '',
            docUrl: '',
            errors:{}
        })
        this.props.setClearForm()
    }
    isvalid = () => {
        const { currencyType, totalTutionFee, firstYearFee, feeDeposit, scholarship, docFile } =this.state;
        let errors = {};
        if(totalTutionFee == ''){
            errors.totalTutionFee = true;
            this.setState ({ errors });
            return false;
          }
          if(firstYearFee == ''){
            errors.firstYearFee = true;
            this.setState ({ errors });
            return false;
          }
          if(feeDeposit == ''){
            errors.feeDeposit = true;
            this.setState ({ errors });
            return false;
          }
          if(scholarship == ''){
            errors.scholarship = true;
            this.setState ({ errors });
            return false;
          }
          if(docFile == ''){
            errors.docFile = true;
            this.setState ({ errors });
            return false;
          }
          return true;
    };
    onSubmit = () => {
        const { currencyType, totalTutionFee, firstYearFee, feeDeposit, scholarship, docFile, docUrl } = this.state;
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if(this.isvalid()){
        if (docFile) {

            const formData = new FormData()
            formData.append('doc_file', docFile);

            const handleSuccess = (response) => {
                const data = {
                    'student_id': params.id,
                    'user_id': userProfile.organization.user_id,
                    'organization_id': userProfile.organization.organization_id,
                    'currency_type': currencyType,
                    'total_tuition_fee': totalTutionFee,
                    'fee_deposit': feeDeposit,
                    'first_year_fee': firstYearFee,
                    'scholarship_discount': scholarship,
                    'receipt_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                    'application_id': applicationStatusId
                }
                const handlesuccessfull = () => {
                    Toast.show({
                        message: 'Save successfully',
                        timeout: 4000,
                        intent: 'success'
                    });
                    onCloseModal()
                    this.setState({
                        currencyType: '',
            totalTutionFee: '',
            firstYearFee: '',
            feeDeposit: '',
            scholarship: '',
            id: '',
            docFile: '',
            docUrl: ''
                    })
                };
                const handleFailure = () => {
                    Toast.show({
                        message: 'Save unsuccessfully',
                        timeout: 4000,
                        intent: 'danger'
                    });
                    onCloseModal()
                    this.setState({
                        currencyType: '',
                        totalTutionFee: '',
                        firstYearFee: '',
                        feeDeposit: '',
                        scholarship: '',
                        id: '',
                        docFile: '',
                        docUrl: ''
                    })
                }
                this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'feedeposit')
            }

            const handleDocFailure = () => { }
            this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formData)
        }
        else {
            const data = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'currency_type': currencyType,
                'total_tuition_fee': totalTutionFee,
                'fee_deposit': feeDeposit,
                'first_year_fee': firstYearFee,
                'scholarship_discount': scholarship,
                'receipt_doc': docUrl,
                'application_id': applicationStatusId
            }
            const handlesuccessfull = () => {
                Toast.show({
                    message: 'Save successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                onCloseModal()
                this.setState({
                    currencyType: '',
        totalTutionFee: '',
        firstYearFee: '',
        feeDeposit: '',
        scholarship: '',
        id: '',
        docFile: '',
        docUrl: ''
                })
            };
            const handleFailure = () => {
                Toast.show({
                    message: 'Save unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                onCloseModal()
                this.setState({
                    currencyType: '',
        totalTutionFee: '',
        firstYearFee: '',
        feeDeposit: '',
        scholarship: '',
        id: '',
        docFile: '',
        docUrl: ''
                })
            }
            this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'feedeposit')
        }
    }

    }
    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors:{}
        })
    }
    userDocument = (doc_url) => {
        if (doc_url) {
            return (
                <a href={`${doc_url}`}>
                    <span>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        </OverlayTrigger>
                    </span>
                </a>

            );
        }
    }
    render() {

        const { currencyType, docUrl, totalTutionFee, firstYearFee, feeDeposit, scholarship, errors } = this.state;
        const { clearModel } =this.props
        if(!clearModel){
            this.clearForm()
        }
        return (
            <div className="unconditional-offer">
                <Col>
                    <Form.Label className="title">Currency Type </Form.Label>
                    <Form.Control
                        as="select"
                        placeholder="currency"
                        className="form-control text-box"
                        value={currencyType}
                        onChange={(event) => this.onChangeInput('currencyType', event.target.value)}
                    >
                        <option value="">Select currency</option>
                        <option value="USD" selected="selected">United States Dollars</option>
                        <option value="EUR">Euro</option>
                        <option value="GBP">United Kingdom Pounds</option>
                        <option value="AUD">Australia Dollars</option>
                        <option value="INR">India Rupees</option>
                    </Form.Control>
                </Col>
                <Col>
                    <Form.Label className="title">Total tution fee for the course *</Form.Label>
                    <Form.Control
                        placeholder="Enter Total Amount of tution fee"
                        type="text"
                        className="form-control text-box"
                        value={totalTutionFee}
                        onChange={(event) => this.onChangeInput('totalTutionFee', event.target.value)}
                    />
                    {errors.totalTutionFee ? <span className="errors">Please Enter Total tution fee </span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Tution fee for the first year *</Form.Label>
                    <Form.Control
                        placeholder="Enter Amount of Tution fee for the first year"
                        type="text"
                        className="form-control text-box"
                        value={firstYearFee}
                        onChange={(event) => this.onChangeInput('firstYearFee', event.target.value)}
                    />
                    {errors.firstYearFee ? <span className="errors">Please Enter Tution fee for first year</span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Fee deposit *</Form.Label>
                    <Form.Control
                        placeholder="Enter Deposit Amount"
                        type="text"
                        className="form-control text-box"
                        value={feeDeposit}
                        onChange={(event) => this.onChangeInput('feeDeposit', event.target.value)}
                    />
                    {errors.feeDeposit ? <span className="errors">Please Enter Deposited fee amount </span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Schloarship / discount *</Form.Label>
                    <Form.Control
                        placeholder="Schloarship / discount"
                        type="text"
                        className="form-control text-box"
                        value={scholarship}
                        onChange={(event) => this.onChangeInput('scholarship', event.target.value)}
                    />
                    {errors.scholarship ? <span className="errors">Please Enter Schloarship / Discount </span> : ''}
                </Col>
                {
                    docUrl && docUrl ? <Col>
                        {this.userDocument(docUrl)}
                    </Col> : ''}
                <Col>
                    <Form.File
                        id="custom-file"
                        label="Upload Document *"
                        val
                        accept=".png, .jpg, .jpeg, .pdf"
                        name="docFile"
                        onChange={this.onDropFile}
                    />
                    {errors.docFile ? <span className="errors">Please Upload Document File (".png, .jpg, .jpeg, .pdf")</span> : ''}
                </Col>
                <Col className="button-save">
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        feeDeposit: state.students.feeDeposit
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch),

    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FeeDeposit));
