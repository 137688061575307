import {
    
    GET_COHORT_REPORT_FAIL,
    GET_COHORT_REPORT_REQUEST,
    GET_COHORT_REPORT_SUCCESS,  
    GET_DIRECT_INDIRECT_FAIL,
    GET_DIRECT_INDIRECT_REQUEST,
    GET_DIRECT_INDIRECT_SUCCESS,
    GET_SOURSE_REPORT_FAIL,
    GET_SOURSE_REPORT_REQUEST,
    GET_SOURSE_REPORT_SUCCESS,
    GET_ASSINGED_LOGS_FAIL,
    GET_ASSINGED_LOGS_REQUEST,
    GET_ASSINGED_LOGS_SUCCESS,
    GET_APA_LOGS_FAIL,
    GET_APA_LOGS_REQUEST,
    GET_APA_LOGS_SUCCESS
} from './type';

const INITIAL_STATE = {
    isLoading: false,
    error: '',
    contactList: [],
    deals: [],
    leads:{},
    callLogs:{},
    sourseReport:{},
    assignedLog:{},
    APALogs:{},
    cohortdata:{}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_COHORT_REPORT_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_COHORT_REPORT_REQUEST:
            return { ...state, isLoading: true };
        case GET_COHORT_REPORT_SUCCESS:
            return { ...state, isLoading: false, error: '',cohortdata:action.payload };      
        case GET_DIRECT_INDIRECT_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_DIRECT_INDIRECT_REQUEST:
            return { ...state, isLoading: true };
        case GET_DIRECT_INDIRECT_SUCCESS:
            return { ...state, isLoading: false, error: '',directIndirectData:action.payload };
        case GET_SOURSE_REPORT_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_SOURSE_REPORT_REQUEST:
            return { ...state, isLoading: true };
        case GET_SOURSE_REPORT_SUCCESS:
            return { ...state, isLoading: false, error: '',sourseReport:action.payload };

        case GET_ASSINGED_LOGS_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_ASSINGED_LOGS_REQUEST:
            return { ...state, isLoading: true };
        case GET_ASSINGED_LOGS_SUCCESS:
            return { ...state, isLoading: false, error: '',assignedLog:action.payload };
        case GET_APA_LOGS_FAIL:
            return { ...state, isLoading: false, error: action.payload, leads: {} };
        case GET_APA_LOGS_REQUEST:
            return { ...state, isLoading: true };
        case GET_APA_LOGS_SUCCESS:
            return { ...state, isLoading: false, error: '',APALogs:action.payload };
        default:
            return state;
    }
};