import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import actions from '../../Auth/modules/actions';
import profileActions from '../module/actions';
import userRoleActions from '../../UserRole/modules/actions'
import { Toast } from "../../../shared/toast/Toast";
import _ from 'lodash';
import ProfileDetailsMarkup from '../components/ProfileDetailsMarkup';

class ProfileDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:null,
            mode: null,   
            inputs: {
                user_id:"",
                email:"",                
                first_name: "",
                last_name: "",
                phone_number: "",
                organization_name: "",
                studentLimit:0,
                countryName:'',
                selectedInterest: [],
                studentLimitStatus: false,
                googleAccessToken:'',
                googleId:'',
                googleSync:''
            },
            user:{},
            profile:[]
        }
    }
   
    static getDerivedStateFromProps(props, state) {      
        const { user:{user,organization}} = props;

        console.log("user,organization", user,organization)
        if (state.user.id !== props.user.user.id) { 
        if(user.id) {
            const inputs = {...user};
            let somedata = organization.counsellor_interest && organization.counsellor_interest ? organization.counsellor_interest :''
            if (somedata){
                let data = organization.counsellor_interest.replace(/'/g,'"')
                somedata = JSON.parse(data)
            }
            return {
                inputs: { 
                            id:inputs.id, 
                            email:inputs.email,
                            first_name: inputs.first_name?inputs.first_name:'',
                            last_name: inputs.last_name? inputs.last_name:'',
                            phone_number:inputs.phone_number,
                            organization_name:organization.organization_name,
                            studentLimit: organization.student_limit ? organization.student_limit : 0,
                            countryName: organization.counsellor_countries ? organization.counsellor_countries : '',
                            selectedInterest: somedata,
                            studentLimitStatus: organization.no_limit,
                            googleAccessToken:inputs.google_access_token,
                            googleId:inputs.google_id,
                            googleSync:inputs.google_sync
                        },
                user: {...user}
            }
        }
    }
    }
    componentDidMount() {

        const { location: { pathname } } = this.props;
        const urlParts = pathname.split("/");
       this.setState({ mode: urlParts[2] ,id: urlParts[3]})
    }
    onFailure = (error) => {

    };
    socialResponse = (response, provider, error) => {
        const { inputs } = this.state;

        const userData = {
            google_access_token: response ? response.accessToken : '',
            google_id: response ? response.googleId : '',
            google_sync: response ? response : ''
        }
        const handleSuccess = () => {
            Toast.show({
                message: "User update successfully..!",
                timeout: 4000,
                intent: 'success'
            })
        }
        const handleFailure = () => {
            Toast.show({
                message: "User update failed",
                timeout: 10000,
                intent: 'danger'

            })
        }
        if(response){
            this.props.profileActions.updateUser(userData, inputs.id, handleSuccess, handleFailure)
        }
    };
    
    update = () => {
         const { user: { user, organization } } = this.props;
        if (user) {
            const { inputs } = this.state;
            const request = {
                // user_id: user.id,
                // organization_id: organization.organization_id,
                id: inputs.id,        
                first_name: inputs.first_name,
                last_name: inputs.last_name,
                phone_number: inputs.phone_number,
            }
            let orgUserData = {
                // organization_name: inputs.organization_name,
                student_limit: inputs.studentLimit,
                counsellor_countries: inputs.countryName,
                counsellor_interest: JSON.stringify(inputs.selectedInterest),
                no_limit: inputs.studentLimitStatus ? 1 : 0
            }
            const handleFailure = (res, body) => {
                this.setState({
                    loginErrorMessage: Toast.show({
                        message: "Update Failed Please try again",
                        timeout: 4000,
                        intent: 'danger'
                    })
                })
            }
            const handleSuccess = () => {
                this.props.history.push(`/profile/view/${inputs.id}`);
                Toast.show({
                    message: "Profile updated successfully !!!!",
                    timeout: 3000,
                    intent: 'success'
                })
            };
            // this.props.actions.update(handleSuccess, handleFailure, request);
            this.props.profileActions.updateOrgUser(orgUserData, inputs.id, ()=>{}, ()=>{})
        }
    }
  
    onChange = (value, input) => {
        const { inputs } = this.state;
        inputs[input] = value;
        this.setState({ inputs });
    }

    onEdit = (id) => {
        this.props.history.push(`/Profile/edit/${id}`);
    }
    onCounsellorInterest = (selceted)=>{
        const { inputs } = this.state
        inputs.selectedInterest.indexOf(selceted) === -1 ? inputs.selectedInterest.push(selceted) : console.log("This item already exists");
        this.setState({ inputs })
    }

    onUnselectInterest=(unSelceted)=>{
        const { inputs } = this.state

        let index = inputs.selectedInterest.indexOf(unSelceted)
        console.log("inputs.index", inputs, index)

        inputs.selectedInterest.slice(index, 1)
        
        this.setState({ inputs })
    }

    onChangeInput=(value)=>{
        const { inputs } = this.state
        console.log("onChangeInput/////////value", value)
        inputs.selectedInterest = value
        this.setState({ inputs })
    }

    render() {
        const { mode , inputs} =this.state
        return(
              <ProfileDetailsMarkup
                 inputs = {inputs}
                 disabled={mode === 'view'}
                 edit={mode === 'edit'}
                 onEdit = { this.onEdit }
                 update= {this.update}
                 onChange={ this.onChange}
                 onUnselectInterest={this.onUnselectInterest}
                 onCounsellorInterest={this.onCounsellorInterest} 
                 onChangeInput={this.onChangeInput}
                 socialResponse={this.socialResponse}
                 onFailure={this.onFailure}
              />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.Auth.user,
        isLoading: state.userRole.isLoading,
        userData: state.userRole.userData

    }
};
const mapDispatchToProps = (dispatch) => {
    return {
       actions: bindActionCreators(actions, dispatch),
       profileActions : bindActionCreators(profileActions, dispatch),
       userRoleActions: bindActionCreators(userRoleActions, dispatch)

    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDetails));