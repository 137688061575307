import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { DateInput } from "@blueprintjs/datetime";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Toast } from '../../../../shared'
import actions from '../../modules/actions'
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import './Visa.scss';

class Visa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visaDate: '',
            id: '',
            errors: {},
            multiple: [1]
        }
    }
    componentDidMount() {
        const { student } = this.props
        this.props.actions.getTaskVisaAppointment(student.id);
    }

    static getDerivedStateFromProps(props, state) {

    }

    isvalid = () => {
        const { visaDate } = this.state;
        let errors = {};

        if (visaDate == '') {
            errors.visaDate = true;
            this.setState({ errors });
            return false;
        }


        return true;
    };
    onAdd = () => {
        const { multiple } = this.state
        multiple.push(1)
        this.setState({
            multiple
        })
    }
    onSubmit = () => {
        const { match: { params }, userProfile, student, onCloseModal } = this.props;
        const { visaDate, multiple } = this.state;
        let data = []
        let zadata = []

        for (let i = 0; i <= multiple.length - 1; i++) {
            console.log("this.state[`visaDate${i}`]", multiple.length, i, this.state[`visaDate${i}`])
            let visaData = {}
            visaData = {
                "appointment_date": this.state[`visaDate${i}`],
                "confirm_date": 0,
                "student_id": params.id,
                "user_id": userProfile.organization.user_id
            }
            data.push(visaData)

            let visaZAData = {}
            visaZAData = {
                "appointment_date": this.state[`visaDate${i}`],
                "confirm_date": 0,
                "student_id": params.id,
                'user_id': student ? student.za_id : null,
            }
            zadata.push(visaZAData)

        }
        const taskList = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'qualifying': false,
            'meeting_schedule': false,
            'required_documents': false,
            'visa_appointment': true,
            'opportunity': false,
            'select_offer': false,
            'attending_meeting': false,

        }
        const ZAtaskList = {
            'user_id': student ? student.za_id : null,
            'meeting_schedule': false,
            'qualifying': false,
            'attending_meeting': false,
            'required_documents': false,
            'visa_appointment': true,
            'opportunity': false,
            'select_offer': false,
        }

        const handlesuccessfull = () => {
            onCloseModal()
            Toast.show({
                message: "Task allocate successfully!",
                timeout: 4000,
                intent: 'success'
            })

        }
        const handleFailure = () => {
            onCloseModal()
            Toast.show({
                message: "Task allocate unsuccessfull!",
                timeout: 4000,
                intent: 'danger'
            })
        }

        this.props.actions.taskAllocation(taskList, () => { }, () => { })
        this.props.actions.counsellorVisaAppointment(data, handlesuccessfull, handleFailure)
        if (ZAtaskList.user_id) {
            this.props.actions.ZAtaskAllocation(ZAtaskList, () => { }, () => { })
            this.props.actions.ZAcounsellorVisaAppointment(zadata, handlesuccessfull, handleFailure)
        }
    }
    onChangeInput = (name, value) => {
        console.log("name, value", name, value)
        this.setState({
            [name]: value, errors: {}
        })

    }
    onAllocate = () => {
        const { match: { params }, userProfile, student, onCloseModal } = this.props;
        const { visaDate, multiple } = this.state;
        let data = []
        let zadata = []
        const taskList = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'qualifying': false,
            'meeting_schedule': false,
            'required_documents': false,
            'visa_appointment': true,
            'opportunity': false,
            'select_offer': false,
            'attending_meeting': false,

        }
        const ZAtaskList = {
            'user_id': student ? student.za_id : null,
            'meeting_schedule': false,
            'qualifying': false,
            'attending_meeting': false,
            'required_documents': false,
            'visa_appointment': true,
            'opportunity': false,
            'select_offer': false,
        }
        const handlesuccessfull = () => {
            onCloseModal()
            Toast.show({
                message: "Task allocate successfully!",
                timeout: 4000,
                intent: 'success'
            })

        }
        const handleFailure = () => {
            onCloseModal()
            Toast.show({
                message: "Task allocate unsuccessfull!",
                timeout: 4000,
                intent: 'danger'
            })
        }
        this.props.actions.taskAllocation(taskList, () => { }, () => { })
        if (ZAtaskList.user_id) {
            this.props.actions.ZAtaskAllocation(ZAtaskList, handlesuccessfull, handleFailure)
        }
    }
    render() {
        const { errors, visaDate, multiple } = this.state;
        const { student, taskVisaAppointment } = this.props
        console.log("taskVisaAppointmenttaskVisaAppointment", taskVisaAppointment)
        return (
            <div className="visa-appointment">
                {
                    student && student.visaapt_by_std != null ?
                        <>
                            {
                                student.visaapt_by_std == 1 ?
                                    <>
                                        {
                                            multiple.map((input, index) => {
                                                return (
                                                    <div>
                                                        <Col>
                                                            <Form.Label className="title">Appointment Date *</Form.Label>
                                                            <DateInput
                                                                formatDate={date => moment(date).format("DD/MM/YYYY HH:mm A")}
                                                                onChange={(date) => this.onChangeInput(`visaDate${index}`, date)}
                                                                parseDate={str => new Date(str)}
                                                                placeholder={"DD/MM/YYYY"}
                                                                defaultValue={new Date()}
                                                                maxDate={new Date('1/1/2030')}
                                                                value={this.state[`visaDate${index}`]}
                                                                timePickerProps={{ showArrowButtons: true }}
                                                                closeOnSelection={true}
                                                            />
                                                            {this.state.errors[`visaDate${index}`] ? <span className="errors">Please Enter Course Name</span> : ''}
                                                        </Col>

                                                    </div>)
                                            })

                                        }
                                        <Col>
                                            <Button onClick={() => this.onAdd()}>Add</Button>
                                        </Col>
                                        <Col className="button-save">
                                            <Button className="save-offer" onClick={() => this.onSubmit()}>Allocate</Button>
                                        </Col>
                                        {taskVisaAppointment && taskVisaAppointment.length ?
                                            <Col className='visa-app-info'>
                                                <h5>Student Visa appointment info :-</h5>
                                                {taskVisaAppointment && taskVisaAppointment.length ? taskVisaAppointment.map((visa, index) => {
                                                    return (
                                                        <div>
                                                            <ul>
                                                            {visa.confirm_date == 1?
                                                            <li className="small-text"><img className="iconImageCheck" src={"https://zoomabroad-assets.s3.amazonaws.com/assets/img/dashboard-check-icon.png"} />{visa.appointment_date ? moment(visa.appointment_date).format('LLLL') : ''}</li>
                                                            :
                                                            <li className="small-text"><img className="iconImage" src={"https://zoomabroad-assets.s3.amazonaws.com/assets/img/dashboard-refresh-icon.png"} />{visa.appointment_date ? moment(visa.appointment_date).format('LLLL') : ''}</li>

                                                        }
                                                            </ul>
                                                        </div>
                                                    )
                                                }
                                                ) : ''}
                                            </Col>
                                            : ''}
                                    </>
                                    :
                                    <div>
                                        <h5>Student Visa appointment info :-</h5>
                                        {taskVisaAppointment && taskVisaAppointment.length ? taskVisaAppointment.map((visa) => {
                                            return (
                                                <div>
                                                    <p>{visa.appointment_date}</p>
                                                </div>
                                            )
                                        }
                                        ) : ''}
                                        {/* <Col className="button-save">
                                            <Button className="save-offer" onClick={() => this.onSubmit()}>Allocate</Button>
                                        </Col> */}
                                    </div>
                            }
                        </>
                        :
                        <div>
                            <h5>Ask :- Are you want to process visa applications via an advisor ?</h5>
                            <Col className="button-save">
                                <Button className="save-offer" onClick={() => this.onAllocate()}>Allocate</Button>
                            </Col>
                        </div>
                }


            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        student: state.students.student,
        taskVisaAppointment: state.students.taskVisaAppointment
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Visa));
