import React from 'react';
import Chart from 'react-apexcharts'
import { ZLoader } from '../../../shared/loader/ZLoader'
const CounslerWiseChart = (props) => {
	const { isLoading, cohortdataSeries, cohortdataOptions, chartitle } = props
	return (
		<div className="call-log-container">
			{isLoading ? <ZLoader /> : null}
			{cohortdataSeries.length && cohortdataSeries[0].data && cohortdataSeries[0].data.length ? <div id="chart">
				<Chart options={{
					chart: {
						type: 'bar',
						height: 350,
						stacked: true,
						toolbar: {
							show: true
						},
						zoom: {
							enabled: true
						}
					},
					title: {
						text: chartitle,
						align: 'left'
					},
					responsive: [{
						breakpoint: 480,
						options: {
							legend: {
								position: 'bottom',
								offsetX: -10,
								offsetY: 0
							}
						}
					}],
					plotOptions: {
						bar: {
							horizontal: false,
						},
					},
					xaxis: {
						categories: cohortdataOptions,
					},
					legend: {
						position: 'right',
						offsetY: 40
					},
					fill: {
						opacity: 1
					}
				}} series={cohortdataSeries} type="bar" height={500} />
			</div>
				:
				<>
					<p style={{ fontWeight: '500' }}>{chartitle}</p>
					<h4>No data available</h4>
				</>
			}
		</div>
	)
}

export default CounslerWiseChart;