import React, { Component } from 'react';
import {
  Row, Col, Button, Container, Form, Table,
} from 'react-bootstrap';
import _ from 'lodash';
import VirtualizedSelect from 'react-virtualized-select'
import './EducationUpdate.scss';
import yearsjson from '../../modules/years.json';
import monthsjson from '../../modules/months.json';
// let yearsjson= []
const years = yearsjson ? yearsjson : [];
const months = monthsjson ? monthsjson : [];
class EducationUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {

    const {
      errors,
      eduStartMonth,
      eduEndMonth,
      eduInstitute,
      educationDetails,
      eduDescription,
      eduStart,
      eduEnd,
      addFile,
      degreeType,
      deleteEducation,
      eduOption,
      onEducationUpdate,
      onChangetext,
      eduCourse,
      eduMark,
      eduName,
      onChangeInput,
      addEduFile,
      eduacationEdit
    } = this.props;
    const handleCourse = (education) => {
      if (education.name == 'secondary') {
        return 'Secondry Education';
      }
      if (education.name == 'higherSecondary') {
        return 'Higher Secondry Education';
      }
      return education.course;
    };
    // const handleNameHeader = (education) => {
    //     if (education.name == 'secondary') {
    //         return 'Secondry Education';
    //     }
    //     if (education.name == 'higherSecondary') {
    //         return 'Higher Secondry Education';
    //     } else {
    //         return education.name;
    //     }
    // };
    const handleName = (education) => {
      if (education.name == 'secondary') {
        return '';
      }
      if (education.name == 'higherSecondary') {
        return '';
      }
      return education.name;
    };

console.log("errors",errors,"option",eduOption)

    return (
      <div>
        <Container className='education-update-container'>
          <Form>
            <Form.Group>
              <Form.Label>Degree Type *</Form.Label>
              <Form.Control
                name="degreeType"
                as="select"
                placeholder="Start Year"
                value={degreeType}
                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              >
               
                <option value="">Select Degree Type</option>
                <option value="1">10th standard / Secondary school</option>
                <option value="2">12th standard / Higher Secondary</option>
                <option value="3">Bachelors / Diploma</option>
                <option value="4">Masters / Phd</option>
              </Form.Control>
              {errors.degreeType ? <span className="error">Please Select Degree Type</span> : ''}
            </Form.Group>
            <Form.Group>
            <Form.Label>School/College Name *</Form.Label>
              <Form.Control
                name="eduInstitute"
                value={eduInstitute}
                className="form-input-field"
                placeholder="School/College *"
                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              />
              {errors.eduInstitute ? <span className="error">Please Enter School/College Name</span> : ''}
            </Form.Group>
            {degreeType == 1 || degreeType == 2 ? null : (
              <>
                <Form.Group>
                  <Form.Control
                    className="form-input-field"
                    name="eduName"
                    placeholder="Degree"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    value={eduName}
                  />
                  {errors.eduName ? <span className="error">Please Enter Degree</span> : ''}
                </Form.Group>
                <Form.Group>
                <Form.Label>Field of study *</Form.Label>
                  <Form.Control
                    className="form-input-field"
                    name="eduCourse"
                    placeholder="Field of study"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    value={eduCourse}
                  />
                  {errors.eduCourse ? <span className="error">Please Enter Field of Study</span> : ''}
                </Form.Group>
              </>)}
            <Row>
              <Row className="date-row">
                <Col md="12" lg="12" sm="12" xs="12">
                  <Form.Label>Start Date *</Form.Label>
                  <Row>
                    <Col md="6" lg="6" sm="6" xs="6">

                    <VirtualizedSelect
                        options={months}
                        className={'years-select'}
                        onChange={(selectedOption) => onChangeInput('eduStartMonth', selectedOption ? selectedOption.value : '')}
                        value={eduStartMonth}
                        placeholder={'Select Month'}
                      />
                      
                      {/* <Form.Control
                        name="eduStartMonth"
                        onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                        as="select"
                        value={eduStartMonth}
                        placeholder="Start Year"
                      > */}
                        {/* {
                       years && years.length ? years.map((year) => {
                       return (         <option value={year.label}>{year.label}</option>)}):''} */}
                        {/* <option value="00">Month</option>
                        <option value="01">Jan</option>
                        <option value="02">Feb</option>
                        <option value="03">Mar</option>
                        <option value="04">Apr</option>
                        <option value="05">May</option>
                        <option value="06">Jun</option>
                        <option value="07">Jul</option>
                        <option value="08">Aug</option>
                        <option value="09">Sept</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                      </Form.Control> */}
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="6">
                      <VirtualizedSelect
                        options={years}
                        className={'years-select'}
                        onChange={(selectedOption) => onChangeInput('eduStart', selectedOption ? selectedOption.value : '')}
                        value={eduStart}
                        placeholder={'Select Year'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col  md="12" lg="12" sm="12" xs="12">
                  <Form.Label>End Date &nbsp; *</Form.Label>

                  <Row>
                    <Col md="6" lg="6" sm="6" xs="6">
                    <VirtualizedSelect
                        options={months}
                        className={'years-select'}
                        onChange={(selectedOption) => onChangeInput('eduEndMonth', selectedOption ? selectedOption.value : '')}
                        value={eduEndMonth}
                        placeholder={'Select Month'}
                      /> 
                      {/* <Form.Control
                        name="eduEndMonth"
                        as="select"
                        onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                        value={eduEndMonth}
                        placeholder="Start Year"
                      >
                        <option value="00">Month</option>
                        <option value="01">Jan</option>
                        <option value="02">Feb</option>
                        <option value="03">Mar</option>
                        <option value="04">Apr</option>
                        <option value="05">May</option>
                        <option value="06">Jun</option>
                        <option value="07">Jul</option>
                        <option value="08">Aug</option>
                        <option value="09">Sept</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                      </Form.Control> */}
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="6">
                      <VirtualizedSelect
                        className={'years-select'}
                        options={years}
                        onChange={(selectedOption) => onChangeInput('eduEnd', selectedOption ? selectedOption.value : '')}
                        value={eduEnd}
                        placeholder={'Select Year'}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row>
              {errors && errors.dateError ?<span className="error">End Date should not comes before Start date</span>:''}
              {errors && errors.blankError ?<span className="error">Date should not be empty or same</span> : ''}
              {errors && errors.eduendError ?<span className="error">Please insert End Date</span> : ''}
              {errors && errors.edustartError ?<span className="error">Please insert Start Date</span> : ''}
              {/* {errors && errors.sameError ?<span className="error">Start Date and End Date Should not be same</span> : ''} */}
            </Row>
            <Row>
              <Col>
                <Form.Group>
                <Form.Label>Grade Type &nbsp; *</Form.Label>
                  <Form.Control
                    name="eduOption"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    value={eduOption}
                    as="select"
                    placeholder="Start Year"
                  >
                    <option value="">Select grade type</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Grade">Grade</option>
                    <option value="GPA">GPA</option>
                    <option value="Others">Others</option>
                  </Form.Control>
                  {errors.eduOption ? <span className="error">Please Select Grade Type</span> : ''}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                <Form.Label>Score &nbsp; *</Form.Label>
                  <Form.Control
                    // min="1"
                    // max="100"
                    // type="number"
                    className="form-input-field"
                    name="eduMark"
                    placeholder="Marks"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    value={eduMark}
                  />

                  {errors && errors.perError ?
                    <span className="error">Please enter valid percentage</span> : ''}

                  {errors && errors.gradeError ?
                    <span className="error">Please enter valid grade</span> : ''}

                  {errors && errors.gpaError ?
                    <span className="error">Please enter valid gpa</span> : ''}

                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
            <Form.Label>Description &nbsp;</Form.Label>
              <Form.Control
                value={eduDescription}
                name="eduDescription"
                onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                className="form-textarea-field"
                as="textarea"
                row="3"
                placeholder="Description"
              />
            </Form.Group>
            <Form.Group>
              <Form.File
                id="exampleFormControlFile1"
                label="Upload Document"
                val
                accept=".png, .jpg, .jpeg, .pdf"
                name="documentFile"
                onChange={addEduFile}
              />
            </Form.Group>
            <Row>
              {/* <Col><Button className="button-delete save" onClick={() => onEducationUpdate()}>Save</Button></Col>  */}
              <Col>
                <Button /* disabled={!(degreeType && eduInstitute  && eduName && eduCourse   && eduStartMonth && eduStart && eduEndMonth && eduEnd && eduOption && eduMark && eduDescription)}  */
                  className="button-delete save"
                  onClick={() => onEducationUpdate()}
                >
                  {eduacationEdit ? 'Update' : 'Save'}
              </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    )

  }
}
export default EducationUpdate;

