import React from "react";
import { Card } from "@blueprintjs/core";
import { Container, Row, Col, Button } from 'react-bootstrap';
import FormGenerator from '../../../shared/FormBuilder/FormGenerator';
import Summary from './Summery'
import { ZLoader } from '../../../shared/loader/ZLoader';

const Form = (props) => {
    const {onClickAddDoc, onDropFile, cards, updateValue, step, updateStep, submit, isLoading, isLoadingIntegration, summaryStep, onOpenEduModal, onClickAdd, educations, otherDetails, onClickRemove, onClickAddExp,onAddLor,onAddSop,onRemoveDoc,RemoveEducation,RemoveExperience } = props;

    const card = cards[step];

    return (
        <Container>
            { isLoadingIntegration? <ZLoader/>:null}
            <Row className="card-step-wrap">
    {cards.map((item, index) => <><Col className="text-center"><div className={step == index ? "cards-step inprogress" : step > index ? "cards-step completed" : "cards-step"}></div><div className="text-center cards-step-text">{item.name}</div></Col><Col>{ index < cards.length - 1 ? <div className="cards-step-line"></div> :  null }</Col></>)}
            </Row>
            <Card>
                <h5>{step > summaryStep ? null : card.title}</h5>
                {step > summaryStep ? <Summary submit={submit} cards={cards} /> : 
                <>
                <FormGenerator
                    formData={card.input}
                    updateValue={updateValue}
                    onDropFile={onDropFile}
                    step={step}
                    onRemoveDoc={onRemoveDoc}
                    RemoveEducation={RemoveEducation}
                    RemoveExperience={RemoveExperience}
                />
               {/* {step == 2? <Button style={{}} onClick={()=>onClickRemove(card.input)}>Remove</Button>:''} */}
               {step == 2? <Button style={{}} onClick={()=>onClickAdd(card.input)} disabled={!( cards[2].input[0].value &&  cards[2].input[1].value && cards[2].input[2].value && cards[2].input[3].value && cards[2].input[4].value && cards[2].input[7].value)}>Add Education</Button>:''}
               {step == 4? <Button style={{marginBottom:13}} onClick={()=>onClickAddExp(card.input)} disabled={!( cards[4].input[0].value &&  cards[4].input[1].value && cards[4].input[2].value)}>Add Experience</Button>:''}

               {step == 5? <Button style={{marginBottom:13}} onClick={()=>onClickAddDoc(card.input)}>Add Additional Document</Button>:''}
               {step == 5? <Button style={{marginLeft:12,marginBottom:13}} onClick={()=>onAddLor(card.input)}>Add LOR</Button>:''}
               {step == 5? <Button style={{marginLeft:12,marginBottom:13}} onClick={()=>onAddSop(card.input)}>Add SOP</Button>:''}
               {step == 4 || step==5? <Button style={{marginLeft:12,marginBottom:13}} onClick={()=>updateStep(step + 1)}>Skip</Button>:''}



                </>
                }
                {/* {step == 2?
                <div >
                    {
                          otherDetails.map((item, index) => {
                            const isFirstItem = index === 0;
                            return (<div className="form-group col-md-12" key={`${item}${index}`}>
                                                    <FormGenerator
                                        formData={cards[2].input}
                                        updateValue={updateValue}
                                        onDropFile={onDropFile}
                                    />
                              
                    
                                    <Button style={{}} onClick={()=>onClickAdd()}>Add Education</Button>

                                    
                            </div>
                            )
                        })

                    }
                </div>
                :''} */}
                <Row style={step > summaryStep?{marginTop:15}:{}}>
                    <Col><Button disabled={step === 0} variant="dark" className="pull-right" onClick={() => updateStep(step > summaryStep ?step - 1:step - 1)}>Go Back</Button></Col>
                    <Col><Button disabled={step === cards.length + 1} variant="dark" onClick={() => step > summaryStep ? submit() : updateStep(step + 1)}>{step > summaryStep ? "Submit" : "Next"}</Button></Col>
                </Row>
            </Card>
        </Container>
    )
}
export default Form