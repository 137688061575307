import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { Button, Form, Col, Row } from 'react-bootstrap';
import actions from '../../modules/actions';
import { bindActionCreators } from 'redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ZLoader } from "../../../../shared/loader/ZLoader"
import { Toast } from '../../../../shared';
import './EmailComposer.scss';


class EmailComposer extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    subject: '',
    courseName:''
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSubjectChange = (event) => {
    this.setState({
      subject: event.target.value,
    });
  };

  onChangeInput = (name, value)=>{
    this.setState({
      [name]: value,
  });
  }
  sendEmail = () => {
    const { editorState, subject, courseName } = this.state;
    const { userProfile, student, actions: { sendEmail, createLog }, closeModal, fromEmail, toEmail } = this.props;
    const data = {
      "from": fromEmail,
      "to": toEmail,
      "student_courses_id":courseName ? courseName : null,
      "std": student.id,
      "u_id": userProfile ? userProfile.organization.user_id : null,
      "subject": subject,
      "type": 'email',
      "note_status":"",
      "note": draftToHtml(convertToRaw(editorState.getCurrentContent())),
      "organization_id":userProfile ? userProfile.organization.organization_id: null,

    }
    const handleSuccess = () => {
      closeModal();
      Toast.show({
        message: "Email sent successfully!",
        timeout: 4000,
        intent: 'success'
      })
      // const log = {
      //   "user_id": student.id,
      //   "created_by": student.user_id,
      //   "subject": subject,
      //   "type": 'email',
      //   "note": draftToHtml(convertToRaw(editorState.getCurrentContent()))
      // }
      const page = 1
      this.props.actions.getLogs(student.id, page);

    }
    const handleError = () => {
      Toast.show({
        message: "Email sent failed!",
        timeout: 4000,
        intent: 'danger'
      })
    }
    // closeModal();
if(userProfile && userProfile.organization){
  if(userProfile.organization.organization_id == 'efdf7895-8dac-471b-b9d9-325920bf0847'){
    sendEmail(data, handleSuccess, handleError);
  }
}
  }

  render() {
    const { editorState, subject , courseName} = this.state;
    const { userProfile, student, isLoading, onChange, fromEmail, toEmail, courses } = this.props;
    return (<>
        {isLoading ? <ZLoader/>: null }
      <div className="email-composer">
        <Row>
          <Col>
            <div className="email-btn-toolbar">
              <strong>From: </strong> &nbsp;  <Form.Control placeholder="From" className="form-control modal-email" type="email" onChange={(event) => onChange('fromEmail', event)} value={fromEmail} />
            </div>
          </Col>
          <Col>

            <div className="email-btn-toolbar">
              <strong>To: </strong> &nbsp; <Form.Control placeholder="From" className="form-control modal-email" type="email" onChange={(event) => onChange('toEmail', event)} value={toEmail} />
            </div>
          </Col>
        </Row>
        {  courses && courses.length ? 
        <div className="select-name">
          <Form.Control placeholder="From" className="form-control modal-email" as="select" onChange={(event) => this.onChangeInput('courseName', event.target.value)} value={courseName} >
          <option value="">Select Course Name</option>
          {
                                    courses && courses.length ? courses.map((course) => {
                                        return (
                                            <option value={course.id}>{course.applied_courses}</option>
                                        )
                                    })
                                        : ''}
            
           
          </Form.Control>
        </div>
        :''}
        <div className="select-name">
          <Form.Control type="text" placeholder="Subject" value={subject} onChange={this.onSubjectChange} />
        </div>
        <Editor
          editorState={editorState}
          wrapperClassName="email-wrapper"
          editorClassName="email-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        <div className="email-btn-toolbar">
          <Button className="pull-right" variant="primary" onClick={() => this.sendEmail()} disabled={!(fromEmail && toEmail && subject && editorState)}>Send Email</Button>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),

  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailComposer));
