import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { DateInput } from "@blueprintjs/datetime";
import { RadioGroup, Radio } from "@blueprintjs/core";
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import { Toast } from "../../../../../shared/toast/Toast";
import action from '../../../modules/actions'
import './BalanceFeePayment.scss';

class BalanceFeePayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currencyBalanceFeeType: '',
            balanceFeeDate: new Date(),
            amountBalanceFee: '',
            id: '',
            errors:{}
        }
    }
    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        //     this.props.action.getBalanceFeePayment(applicationStatusId)
        // }        
    }
    
    static getDerivedStateFromProps(props, state) {
        if (props.balanceFee && props.balanceFee.length) {
            if (props.balanceFee[0].id != state.id) {
                return {
                    currencyBalanceFeeType: props.balanceFee[0].currency_balance_fee_type,
                    balanceFeeDate: props.balanceFee[0].balance_fee_date ? new Date(props.balanceFee[0].balance_fee_date) : new Date(),
                    amountBalanceFee: props.balanceFee[0].balance_fee_amount,
                    id: props.balanceFee[0].id
                };
            }
        }
    }
    clearForm = () =>{
        this.setState({
            currencyBalanceFeeType: '',
            balanceFeeDate: new Date(),
            amountBalanceFee: '',
            id: '',
            errors:{}
        })
        this.props.setClearForm()
    }
    isvalid = () => {
        const { currencyBalanceFeeType, balanceFeeDate, amountBalanceFee } = this.state;
        let errors = {};
         if (currencyBalanceFeeType == '' && !amountBalanceFee == '') {
            errors.currencyBalanceFeeType = true;
            this.setState({ errors });
            return false;
        } 
        if (currencyBalanceFeeType == 'USD' || currencyBalanceFeeType == 'EUR' || currencyBalanceFeeType == 'GBP' || currencyBalanceFeeType == 'AUD' || currencyBalanceFeeType == 'INR') {
            if (amountBalanceFee == '' || !/^[0-9]{2,9}$/.test(amountBalanceFee)) {
                errors.amountBalanceFee = true;
                this.setState({ errors });
                return false;
            }
        }

        if (balanceFeeDate == '') {
            errors.balanceFeeDate = true;
            this.setState({ errors });
            return false;
        }

        return true;
    };
   
    onSubmit = () => {
        const { match: { params }, userProfile, onCloseModal, balanceFee, applicationStatusId } = this.props;
        const { currencyBalanceFeeType, balanceFeeDate, amountBalanceFee } = this.state;
        if (this.isvalid()) {
            const data = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'balance_fee_date': balanceFeeDate,
                'balance_fee_amount': amountBalanceFee,
                'currency_balance_fee_type': currencyBalanceFeeType,
                'application_id': applicationStatusId
            }
            const handlesuccessfull = () => {
                Toast.show({
                    message: 'Save successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                onCloseModal()
                this.setState({
                    currencyBalanceFeeType: '',
                    balanceFeeDate: new Date(),
                    amountBalanceFee: '',
                    id: ''
                })
            };
            const handleFailure = () => {
                Toast.show({
                    message: 'Save unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                onCloseModal()
                this.setState({
                    currencyBalanceFeeType: '',
                    balanceFeeDate: new Date(),
                    amountBalanceFee: '',
                    id: ''
                })
            }
            this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'balancefee')
        }
    }
    onChangeInput = (name, value) => {
        if (name == 'currencyBalanceFeeType') {
            this.setState({
                [name]: value, errors: {},
                amountBalanceFee: ''
            });
        }
        else {
            this.setState({
                [name]: value, errors: {}
            })
        }
    }
    render() {
        const { currencyBalanceFeeType, balanceFeeDate, amountBalanceFee, errors } = this.state;
        const { clearModel } =this.props
        if(!clearModel){
            this.clearForm()
        }
        return (
            <div className="balance-fee">
                <Col>
                    <Form.Label className="title">Currency Type </Form.Label>
                    <Form.Control
                        as="select"
                        placeholder="currency"
                        className="form-control text-box"
                        value={currencyBalanceFeeType}
                        onChange={(event) => this.onChangeInput('currencyBalanceFeeType', event.target.value)}
                    >
                        <option value="">Select currency</option>
                        <option value="USD" selected="selected">United States Dollars</option>
                        <option value="EUR">Euro</option>
                        <option value="GBP">United Kingdom Pounds</option>
                        <option value="AUD">Australia Dollars</option>
                        <option value="INR">India Rupees</option>
                    </Form.Control>
                    {errors.currencyBalanceFeeType ? <span className="errors">Please Select Appropriate Currency Type</span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Amount *</Form.Label>
                    <Form.Control
                        disabled={!currencyBalanceFeeType}
                        placeholder="Enter Amount"
                        type="text"
                        className="form-control text-box"
                        value={amountBalanceFee}
                        onChange={(event) => this.onChangeInput('amountBalanceFee', event.target.value)}
                    />
                    {errors.amountBalanceFee ? <span className="errors">Please Enter Amount</span> : ''}
                </Col>
                <Col>
                    <Label className="applied-date title" htmlFor="input-b"> Date 
                <DateInput
                            formatDate={date => moment(date).format("DD/MM/YYYY")}
                            onChange={(date) => this.onChangeInput("balanceFeeDate", date)}
                            parseDate={str => new Date(str)}
                            placeholder={"DD/MM/YYYY"}
                            defaultValue={new Date()}
                            maxDate={new Date('1/1/2030')}
                            value={balanceFeeDate}
                        />
                    </Label>
                </Col>
                {errors.balanceFeeDate ? <span className="errors">Please Enter valid Date </span> : ''}
                <Col className="button-save">
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        balanceFee: state.students.balanceFee
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(BalanceFeePayment));
