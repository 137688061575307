import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { CLOUDFROND_ASSETS3_URL } from '../../../../../env'
import integrationAction from '../../../../Integration/modules/actions'
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './ApplicationRejected.scss';
class ApplicationRejected extends Component {
    constructor(props) {
        super(props)
        this.state = {
            VisaRefusedVal: '',
            id: '',
            errors:{}
        }
    }
    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppVisaRefused(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        console.log("appRejectedappRejected", props.appRejected)
        if (props.appRejected && props.appRejected.length) {
            if (props.appRejected[0].id != state.id) {
                return {
                    VisaRefusedVal: props.appRejected[0].comment,
                    id: props.appRejected[0].id
                };
            }
        }
    }

    clearForm = () =>{
        this.setState({
            VisaRefusedVal: '',
            id: '',
            errors:{}
        })
        this.props.setClearForm()
    }
    isvalid = () => {
        const { VisaRefusedVal, financial, credibility_interview, immigration_history, language, other, docFile } = this.state;
        let errors = {};
       
        if(/* !other == '' &&  */VisaRefusedVal == ''){
            errors.VisaRefusedVal = true;
            this.setState ({ errors });
            return false;
          }
        return true;
    };
    onSubmit = () => {
        const { VisaRefusedVal } = this.state
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if(this.isvalid()){
    
            const data = {
                'student_id': params.id,
                'user_id': userProfile.organization.user_id,
                'organization_id': userProfile.organization.organization_id,
                'comment': VisaRefusedVal,
                'application_id': applicationStatusId
            }
            const handlesuccessfull = () => {
                Toast.show({
                    message: 'Save successfully',
                    timeout: 4000,
                    intent: 'success'
                });
                onCloseModal()
                this.setState({
                    VisaRefusedVal: '',
                    id: '',
                })
            };
            const handleFailure = () => {
                Toast.show({
                    message: 'Save unsuccessfully',
                    timeout: 4000,
                    intent: 'danger'
                });
                onCloseModal()
                this.setState({
                    VisaRefusedVal: '',
                    id: '',
                })
            }
            this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'apprejected')
        }
    }


    onChangeInput = (name, value) => {
        this.setState({
            [name]: value,errors:{}
        })
    }

    render() {
        const { VisaRefusedVal, errors } = this.state;
        const { clearModel } =this.props
        if(!clearModel){
            this.clearForm()
        }
        return (
            <div className="conditional">
    
                <Col>
                    <Form.Label className="title">Comment *</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Reason for application rejected"
                        className="form-control text-box"
                        value={VisaRefusedVal}
                        onChange={(event) => this.onChangeInput('VisaRefusedVal', event.target.value)}
                    />
                    {errors.VisaRefusedVal ? <span className="errors">Please Enter Reason for application rejection</span> : ''}
                </Col>
                <Col>
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        appRejected: state.students.appRejected
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch)
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationRejected));
