import mirrorkey from 'mirrorkey';
export default mirrorkey([
    'GET_SOURCE_REQUEST',
    'GET_SOURCE_SUCCESS',
    'GET_SOURCE_FAIL',
    'POST_SOURCE_REQUEST',
    'POST_SOURCE_FAIL',
    'POST_SOURCE_SUCCESS',
    'UPDATE_SOURCE_REQUEST',
    'UPDATE_SOURCE_FAIL',
    'UPDATE_SOURCE_SUCCESS',
    'DELETE_SOURCE_REQUEST',
    'DELETE_SOURCE_FAIL',
    'DELETE_SOURCE_SUCCESS',
])