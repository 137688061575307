import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import ResultMarkup from "../components/ResultMarkup"
import { Card, Elevation, MenuItem, Toaster } from "@blueprintjs/core";
import staticData from "../components/staticFile"
import actions from '../modules/actions';
import courseAction from '../../Course/modules/action'
import interviewActions from '../../Interview/modules/actions';
import integrationActions from '../../Integration/modules/actions';
import { Toast } from '../../../shared';
import { Omnibar } from "@blueprintjs/select";
import "./Result.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button } from 'react-bootstrap';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            acdRadios: {},
            expRadios: {},
            stateCourseList:[],
            data:{},
            sop_val:'',
            interview_val:'',
            isOpen:false,
            scrollToActiveItem:true,
            onClose:false,
            selectedInterview:'',
            interviewScore:'',

            link:"",
            search: "",
            stateInterview:[],
            showOfferModal:false,
            showModal:false,
            disable:false,
            isEditing:false,
            interviewName:'',
            studentReport: {},
            showAcademicModal:false,
            showUnconditionalModal:false,
            isVarified:false,
            selectedCourse:{},
            count:0,
            first:true,
            docUrl:'',
            docUrlIndex:0,
            showInterview:false,
            interviewEmail:'',
            interviewEmailContent:'',
            interviewSendObj:'',
            interviewVideoLink:'',
            userRoleAccess:[{"operations": [1, 1, 1, 1], "name": "MAPPING"}, {"operations": [1, 1, 1, 1], "name": "QUESION"}, {"operations": [1, 1, 1, 1], "name": "INTERVIEW"}, {"operations": [1, 1, 1, 1], "name": "COURSE"}, {"operations": [1, 1, 1, 1], "name": "REPORT"}, {"operations": [1, 1, 1, 1], "name": "STUDENTS"}]

        }
    } 
    static getDerivedStateFromProps(props, state) {
        const {interviews, studentReport, coursesList:{course},user} =props
        let {selectedCourse } =state
        let dataCourse
        const access = user.organization.access
        let userRoleAccess
		let finalData = access?JSON.parse(access):[{"operations": [1, 1, 1, 1], "name": "MAPPING"}, {"operations": [1, 1, 1, 1], "name": "QUESION"}, {"operations": [1, 1, 1, 1], "name": "INTERVIEW"}, {"operations": [1, 1, 1, 1], "name": "COURSE"}, {"operations": [1, 1, 1, 1], "name": "REPORT"}, {"operations": [1, 1, 1, 1], "name": "STUDENTS"}]
 
        if(interviews && studentReport && studentReport.id != state.studentReport.id ) {
            if(course){
                for(let i =0; i <course.length;i++){
                    if(course[i].course_name == "ALL" && course[i].course_type == studentReport.course_type)
                    {      
                        dataCourse = course[i]
                    } 
                    if(course[i].course_name == studentReport.applied_course && course[i].course_type == studentReport.course_type){
                
                        dataCourse=course[i]
                        break
                    }
                }

                selectedCourse=dataCourse  
        }
            let currentInterview = _.find(interviews, { 'id': studentReport.interview_id });
            return {
                // stateInterview:  [...interviews],
                selectedInterview: currentInterview ? currentInterview.id : null,
                interviewName: currentInterview ? currentInterview.set_name : null,
                studentReport,
                selectedCourse: selectedCourse? selectedCourse:'',
                stateCourseList: course? [...course]:[],
                // userRoleAccess,
                userRoleAccess:finalData ,
                interviewEmail:studentReport? studentReport.email:''
            }
    
        }
    }
    componentDidMount(){
        const { match: { params}, user: { user } } = this.props;
        if(params && params.id) {
        // this.props.actions.getStudent(params.id);
        const handleSuccess = (body) => {
            const handleUpdateSuccess = () => {
                console.log("success");
            }
            const handleUpdateFailure = () => {
                console.log("failure");
            }
            const handleNoteSuccess = () => {
                console.log("success");
            }
            const handleNoteFailure = () => {
                console.log("failure");
            }
            const data = {
                "properties": [
                  {
                    "property": "zoomscore",
                    "value": body.score
                  },
                  {
                    "property": "eligible",
                    "value": body.eligible
                  }
                ]
            }
            const engagement = {
                "engagement": {
                    "active": true,
                    "type": "NOTE",
                },
                "associations": {
                    "contactIds": [body.v_id]
                },
                "metadata": {
                    "body": `Zilter: See Detail report here https://advisor.zoomabroad.com/sso?app=hubspot&token=${user.apikey}&id=${params.id}`
                }
            }
            this.props.integrationActions.updateHubSpotContactsProperties(handleUpdateSuccess, handleUpdateFailure, data, body.v_id);
            this.props.integrationActions.createHubSpotContactNote(handleUpdateSuccess, handleUpdateFailure, engagement);
        }
        const handleFailure = () => {
            console.log("failure");
        }
        this.props.actions.getReport(params.id, handleSuccess, handleFailure);
        this.props.interviewActions.getInterviews();
        this.props.courseAction.getOrganizationCourses(1);
        }
    }
    offerStatus=(val)=>{
        const { match: { params}, studentReport } = this.props;
        const {disable,first} =this.state;
        const data= {};
        if(disable || studentReport.status==null){
        if(val){
            data.status=val
            this.setState({
                disable:true
            })
        }
        const handleSuccess =() =>{
            // window.location.reload()
            if(val>2){
            window.location.reload()
                
            }

        }
        this.props.actions.updateReport(handleSuccess,params.id,data)
       
       
    }
    }
     onSave =()=> {
        const { match: { params}, studentReport } = this.props;
        const { interview_val, sop_val, selectedInterview, interviewName} = this.state;
        
        const data= {};
        if(interview_val) {
            data.interview_score = interview_val
        }
        if(sop_val) {
            data.sop_score = sop_val
        }
        if(selectedInterview){
            data.interview_id = selectedInterview
        }
        if(interviewName){
            data.interview_name = interviewName
        }
        const handleSuccess = ()=>{
            Toast.show({
                message: "Saved Successfully..!!",
                timeout: 4000,
                intent: 'success'
                
            })   
            this.props.history.push('/students')
        }
        this.props.actions.updateReport(handleSuccess,params.id,data)    
    }
    onEdit=()=>{
        this.setState({
            isEditing:true,
            disable:true
        })
    }
    handleTextChange = (name, event) => {
        this.setState({[name]: event.target.value});
    }

    onCloseModal = () => {
        this.setState({
            showModal: false,
            showInterview:false,
            showOfferModal:false,
            showUnconditionalModal:false
        });
    }
    
    openInterviewModel=(inter)=>{
        this.setState({
            showInterview:true,
            interviewSendObj:inter
        })

    }
    openOfferModel=()=>{
        this.setState({
            showOfferModal:true
        })

    }
    openUnconditionalModel=()=>{
        this.setState({
            showUnconditionalModal:true
        })

    }
    onOpenModel = (interviewObj) => {
        console.log("interviewObjinterviewObj", interviewObj)
        this.setState({
            showModal: true,
            interviewVideoLink:interviewObj.interview_url
        });
    }
    onCloseAcademicModal = () => {
        this.setState({
            showAcademicModal: false,
            docUrl:''
        });
    }
    onOpenAcademicModel = (url,obj) => {
        this.setState({
            showAcademicModal: true,
            docUrl: url,
            docUrlIndex: obj

        });
    }
    handleChange = (type, name, event) => {
        const radio = this.state[type];
        radio[name] =  event.target.value
        this.setState({[type]: radio});
    }
    onSendConditional =(data,intervieEmails) =>{
        const { match: { params}, studentReport } = this.props;

       const sendData={
            name: `${data.first_name} ${data.last_name}`,
            email: intervieEmails,
            course: data.applied_course,
            interview_id: data.interview_id,
            application_id: data.id,
            offer : "Conditional Offer"
        }
        
        const handleSuccess = ()=>{
            Toast.show({
                message: "Offer mail sent Successfully",
                timeout: 4000,
                intent: 'success'
                
            })
            this.onCloseModal();
            window.location.reload()

        }
       
        this.props.actions.sendOfferLetter(sendData,handleSuccess)
    }
    onSendUnonditional =(data,intervieEmails) =>{
        const { match: { params}, studentReport } = this.props;

        const sendData={
             name: `${data.first_name} ${data.last_name}`,
             email: intervieEmails,
             course: data.applied_course,
             interview_id: data.interview_id,
             application_id: data.id,
             offer : "Unconditional Offer"
         }
         
         const handleSuccess = ()=>{
             Toast.show({
                 message: "Offer mail sent Successfully",
                 timeout: 4000,
                 intent: 'success'
                 
             })
             this.onCloseModal();
            window.location.reload()

         }
        
         this.props.actions.sendOfferLetter(sendData,handleSuccess)
     }

    onSendInterview =(data, intervieEmails, interviewEmailContent) =>{
        const { interviewSendObj } = this.state
        const { match: { params}, user } = this.props;
        this.setState({
            count:1
        })
        console.log("datasend>>data", user.organization.organization_id)
       const sendData={
            name: `${data.first_name} ${data.last_name}`,
            email: intervieEmails,
            course: data.applied_course,
            interview_id: interviewSendObj.interview_id,
            interview_student_id: interviewSendObj.id,
            htmlFile: interviewEmailContent
        }
        
        const handleSuccess = ()=>{
            Toast.show({
                message: "Interview mail sent Successfully",
                timeout: 4000,
                intent: 'success'
                
            })
            this.onCloseModal();
        }
        const succes = ()=>{
            
        }
        if(user && user.organization){
            if(user.organization.organization_id == 'efdf7895-8dac-471b-b9d9-325920bf0847'){
                this.props.actions.sendInterviewLink(sendData,handleSuccess)
            }
        }
        
        this.props.actions.updateReport(succes,params.id,{interview_status:1})
        this.props.actions.getReport(params.id, ()=> {}, ()=> {});
    }

    varification =()=>{
  
        const { match: { params}, studentReport:{academic}, studentReport:{experience},studentReport } = this.props;
   
        const docs = this.state.docUrlIndex
        let data= {};
       
        if(docs.degree_name && academic) {
            data={
                user_id: '',
                "academic": [
                    {
                        "degree_name": docs.degree_name,
                        "verified": 1
                    }
                ]
            }
        }
        if(docs.lang_doc_url) {
            data={
                user_id: '',
                "language_efficiency": [
                    {
                        "verified": 1
                    }
                ]
            }
        }
        if(docs.company_name && experience) {
            data={
                user_id: '',
                "experience": [
                    {
                        "company_name": docs.company_name,
                        "exp_verified": 1
                    }
                ]
            }
        }

        if(docs.SOP_doc_url && studentReport.sop) {
            data={
                user_id: '',
                "sop": [
                    {
                        "SOP_verified": 1
                    }
                ]
            }
        }
        const handleSuccess =() =>{
            this.onOpenAcademicModel()
            Toast.show({
                message: "Marked as varified",
                timeout: 4000,
                intent: 'success'
                
            })
            this.onCloseAcademicModal()

        this.props.actions.getReport(params.id);

        }
        this.props.actions.updateStudentReport(handleSuccess,params.id,data)
    }
    handleClick = () => {
        this.setState({ isOpen: true });

    };

   

    handleClose = () => this.setState({ isOpen: false });

    handleTest =(interview) =>{
        const {selectedInterview, interviewName} =this.state;
        const { match: { params}, studentReport, user } = this.props;

        const data =
        {
            "student_id": params.id,
            "user_id": user.user_id,
            "organization_id": user.organization_id,
            "interview_id":interview.id,
            "interview_status":"",
            "interview_name":interview.set_name,
            "interview_url":null
    }
            // data.interview_id = interview.id
            // data.interview_name = interview.set_name
        const handleSuccess = ()=>{
            Toast.show({
                message: "Interview Selected",
                timeout: 4000,
                intent: 'success'
            })

        this.props.actions.getReport(params.id);
        }
        const handleFailure = ()=>{
            alert("fail")
        }
        // this.props.actions.updateReport(handleSuccess,params.id,data)
        this.props.actions.postInterview(handleSuccess,handleFailure,data)


    }


    handleItemSelect = (interview) => {
        this.setState({ 
            isOpen: false,
            selectedInterview: interview.id,
            interviewName:interview.set_name

        })
        this.handleTest(interview);
        
    };
    handleChangeCk = (value) => {
        this.setState({
            interviewEmailContent:value
		});
	}

    renderFilm = (interview, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${interview.set_name}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={interview.id}
                onClick={handleClick}
                text={text}
                selectedInterview={this.state.selectedInterview}
            />
        );
    };
    
    filterItems = (query, interview) => {
        return interview ?interview.set_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 : [];
    };

    onDeleteInterview=(id)=>{
        const { match: { params} } = this.props;

        let data ={}
        data.interview_id = null
        data.interview_name = null
        const handleSuccess = ()=>{

       window.location.reload()
            Toast.show({
                message: "Interview Removed",
                timeout: 4000,
                intent: 'success'
            })

    }
    const handleFail = ()=>{ 
        Toast.show({
            message: "Operation Failed",
            timeout: 4000,
            intent: 'danger'
        })
    }
    this.props.actions.deleteInterview(id,handleSuccess, handleFail) 
    // this.props.actions.deleteInterview(handleSuccess,params.id,data)
}
    
    render() {
        const { interviewVideoLink } = this.state
        const {student,studentReport, interviews: { interview }, match:{params}} = this.props
        return (
            <div className="container">
            <Card className="container container-class" elevation={Elevation.THREE}>
                <Omnibar
                    isOpen={this.state.isOpen}
                    onItemSelect={this.handleItemSelect}
                    items={interview ? interview : []} 
                    onClose={this.handleClose}
                    itemRenderer={this.renderFilm}
                    itemPredicate={this.filterItems}
                    // onActiveItemChange
                    className="omni-search"
                    
                />
                <ResultMarkup
                    userRoleAccess={this.state.userRoleAccess[4].operations}
                    items={student}
                    reportData={studentReport}
                    onSave={this.onSave}
                    handleChange={this.handleChange}
                    openOfferModel={this.openOfferModel}
                    openUnconditionalModel={this.openUnconditionalModel}
                    onSendConditional={this.onSendConditional}
                    onSendUnonditional={this.onSendUnonditional}
                    {...this.state}
                    handleClick={this.handleClick}
                    inputChange={this.state.interviewScore}
                    handleTextChange={this.handleTextChange}
                    interview_val={this.state.interview_val}
                    sop_val={this.state.sop_val}
                    showModal={this.state.showModal}
                    onCloseModal={this.onCloseModal}
                    onOpenModel={this.onOpenModel}
                    showVideo={this.state.showVideo}
                    onPlay={this.onPlay}
                    offerStatus={this.offerStatus}
                    disableButton={this.state.disable}
                    isEditing={this.state.isEditing}
                    onEdit={this.onEdit}
                    interviewName={this.state.interviewName}
                    showAcademicModal={this.state.showAcademicModal}
                    onCloseAcademicModal={this.onCloseAcademicModal}
                    onOpenAcademicModel={this.onOpenAcademicModel}
                    varification={this.varification}
                    history={this.props.history}
                    applicationID={this.props.studentReport.id}
                    courseData={this.state.stateCourseList}
                    isLoading={this.props.isLoading}
                    onSendInterview={this.onSendInterview}
                    selectedCourse={this.state.selectedCourse}
                    count={this.state.count}
                    docUrl={this.state.docUrl}
                    openInterviewModel={this.openInterviewModel}
                    showInterview={this.state.showInterview}
                    interviewEmail={this.state.interviewEmail}
                    interviewEmailContent={this.state.interviewEmailContent}
                    handleChangeCk={this.handleChangeCk}
                    onDeleteInterview={this.onDeleteInterview}
                    interviewVideoLink={interviewVideoLink}
                />
            </Card>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    console.log(state,"useruseruseruseruseruseruseruseruseruseruseruser>>>>>>>");
    return {
        isLoading: state.students.isLoading,
        error: state.Auth.error,
        student:state.students.student,
        studentReport:state.students.report,
        interviews: state.interview.interviews,
        coursesList: state.courses.courseList,
        user: state.Auth.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        interviewActions:bindActionCreators(interviewActions, dispatch),
        courseAction:bindActionCreators(courseAction, dispatch),
        integrationActions: bindActionCreators(integrationActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Result));


 


