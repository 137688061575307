import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Accordion, Card } from 'react-bootstrap';
import './Education.scss';

const Education = (props) => {
  const { student, showDocumentModal } = props;
  let academic = null;
  if (student.academic && student.academic.length) {

    academic = student.academic.map(acad => {
      return (<div className="academic">
        <div className="degree-type">{acad.degree_type}</div>
        <div className="degree-name">{acad.degree_name}</div>
        <div className="degree-score">{acad.score} %</div>
        <Row>
          {acad.doc_url_degree && acad.doc_url_degree ?
           <>
            {
            acad.doc_url_degree && acad.doc_url_degree.split('/').pop() != 'undefined' ? <Col md="4" className="inside-container" onClick={() => showDocumentModal(acad.doc_url_degree)}>
              <span>Degree</span>
              <FontAwesomeIcon className={"icon-inside"} icon={['fas', 'file-contract']} />

            </Col> : null}
            </> : null
            }
          {acad.doc_url_marksheet && acad.doc_url_marksheet ? 
          <>
            {acad.doc_url_marksheet && acad.doc_url_marksheet.split('/').pop() != 'undefined' ? <Col className="inside-container" onClick={() => showDocumentModal(acad.doc_url_marksheet)}>
              <span>Marksheet</span>

              <FontAwesomeIcon className={"icon-inside"} icon={['fas', 'id-card']} />

            </Col> : null}
          </> : null}
        </Row>
      </div>)
    })
  }
  return (
    // <Row className="education-section">
    //     <Col>
    //         <h3>Education</h3>
    //         {academic}
    //         <div></div>
    //     </Col>
    // </Row>
    <div className="education-section">
      <Accordion >
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <h3>Education</h3>
            <FontAwesomeIcon className={"icon"} icon={['fas', 'plus']} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {academic}



            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}
export default Education;
