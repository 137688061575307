import React, {Component} from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { DateInput } from "@blueprintjs/datetime";
import { RadioGroup, Radio } from "@blueprintjs/core";
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import action from '../../../modules/actions'
import {
    Label
} from "@blueprintjs/core";
import { Toast } from "../../../../../shared/toast/Toast";
import './VisaApplied.scss';
class  VisaApplied extends Component {
    constructor(props){
        super(props)
        this.state={  
            visaAppliedDate:new Date(),
            id:''
        }
    }
    componentDidMount(){
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppVisaApplied(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.visaApplied && props.visaApplied.length){
            if(props.visaApplied[0].id != state.id){
                return {
                    visaAppliedDate: props.visaApplied[0].visa_applied_date ? new Date(props.visaApplied[0].visa_applied_date): new Date(),
                    id:props.visaApplied[0].id
                  };
            }
        }
    }
    clearForm = () =>{
        this.setState({
            visaAppliedDate:new Date(),
            id:''
        })
        this.props.setClearForm()
    }
    onSubmit = ()=>{
        const {  visaAppliedDate  } = this.state
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;

        const data = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'visa_applied_date': visaAppliedDate,
            'application_id': applicationStatusId
        }
        const handlesuccessfull = () => {
            Toast.show({
                message: 'Save successfully',
                timeout: 4000,
                intent: 'success'
            });
            onCloseModal()
            this.setState({
                visaAppliedDate:new Date(),
                id:''
            })
            
        };
        const handleFailure = () => {
            Toast.show({
                message: 'Save unsuccessfully',
                timeout: 4000,
                intent: 'danger'
            });
            this.setState({
                visaAppliedDate:new Date(),
                id:''
            })
            onCloseModal()
        }
        this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'applied')
    }

    onChangeInput= (name,value)=>{
        this.setState({
            [name]:value
        })
    }
    render(){
    const { visaAppliedDate } = this.state;
    const { clearModel } =this.props
    if(!clearModel){
        this.clearForm()
    }
    return (
        <div className="visa-applied">
            <Col>
                <Label className="applied-date" htmlFor="input-b">Applied date *
                <DateInput
                        formatDate={date => moment(date).format("DD/MM/YYYY")}
                        onChange={(date) => this.onChangeInput("visaAppliedDate", date)}
                        parseDate={str => new Date(str)}
                        placeholder={"DD/MM/YYYY"}
                        defaultValue={new Date()}
                        maxDate={new Date('1/1/2030')}
                        value={visaAppliedDate}
                    />
                </Label>
            </Col>
            <Col className="button-save">
                <Button className="save-offer"  onClick={this.onSubmit}>Save</Button>
            </Col>
        </div>
    )
}
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        visaApplied: state.students.visaApplied
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
    };
  };
  export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(VisaApplied));
