import React, { Component } from "react";
import { Tabs, Tab } from "@blueprintjs/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import './StudentViewMarkup.scss';
import { ZLoader } from "../../../shared/loader/ZLoader";
import Modal from '../../../shared/model/model'
import Profile from "./Profile/Profile";
import Education from "./Profile/Education";
import Application from "./Profile/Applications"
import Experience from "./Profile/Experience";
import Language from "./Profile/Language";
import Activities from './Activity/List';
import EmailComposer from './Email/EmailComposer';
import Note from './Note/Note';
import Task from './Task/TaskCreate'
import Status from './Status/Status'
import WhatsApp from './Whatsapp/Whatsapp'
import FileViewer from 'react-file-viewer';
import ChatMessage from './ChatMessage/ChatMessageMarkup'
import TaskAllocate from './AllocateTask/AllocateTask'
import SchedulingMeeting from './AllocateTask/SchedulingMeeting'
import AttendingMeeting from './AllocateTask/AttendingMeeting'
import ConfirmingInterest from './AllocateTask/ConfirmingInterests'
import MakingFinalDecision from './AllocateTask/MakingFinalDecision'
import RequiredDocuments from './AllocateTask/RequiredDocuments'
import ProgressCheckList from '../modules/chacklist.json'
import Visa from './AllocateTask/Visa'
import FeedbackForm from './feedback-form/FeedbackFom'

const StudentViewMarkup = (props) => {
    const { student, onMessage, onShowMessage, onCloseModal, onShowNote, onNote, isLoading, userProfile, onChange, fromEmail, toEmail, logsDetails, onShowTask, onTask,
        onPageChange, itemRender, pageIndex, onChangetext, status, myState, studentCourses, onShowStatus, onStatus, applicationStatus, members,
        onChangeInput, assignedValue, applicationValue, openInNewTab, subject, onSubjectChange, courseName, editorState, onEditorStateChange, editState, editId, onEditState,
        onDeleteStudentLogs, leadStatusValue, noteStatusValue, leadState, onEditNoteChange, editorNoteState, applyZoomabroad, onStudentList, showApplicationCreateModal, onApplyCourse, onClearAppModal, onApplyCreateCourse,
        zoomabroadCourses, zoomabroadUniversity, channelList, applicationCourseType,applicationType, applicationCourseUniversity, sourceList,
        appicationCourseCreate, showDocModal, showDocumentModal, modalDocUrl, applicationChannelName, applicationIntakeYear, applicationIntakeMonth
        , onShowWhatsapp, onWhatsapp, phoneNumber, editorWhatsState, oneditorWhatsChange, onWhatsappEdit, showApplicationStatusModal, onApplicationStatusModal,
        applicationStatusIndex, applicationModalName, applicationStatusId, clearModel, setClearForm, openApplicationEditModal, showApplicationNumberModal,
        onApplicationEditModal,onIntakeEditModal,onCourseEditModal,application_intake_month,application_intake_year, onChangeApplication, applicationNumber, onShowChatMessage, onChatMessage, taskAllocateModel, onCloseTaskAllocateModal, showTaskAllocateModal, showAllocateModal, taskIndex,
        onTaskIndex, onCloseAllocationModal,showApplicationcourseModal,onApplicationTypeEditModal,openApplicationTypeEditModal,applied_courses,showApplicationTypeModal,
        application_type, openApplicationCourseEditModal,onEditApplicationcoursename, taskName, checkList, supportStaffValue,openApplicationIntakeEditModal,
        showApplicationIntakeModal, mySupportState ,showFeedbackform, closefeedbackModal, openFeedbackModal,getFeedbackForm,onChangeFeedback,onSubmitFeedback,
         taskVisaAppointment,studentAppicationIntake, onPhoneCall, studentZA} = props;
   
    let zaCoursesData = []
    let applied = null;
    if (zoomabroadCourses && zoomabroadCourses.university_courses) {
        for (let i = 0; i < zoomabroadCourses.university_courses.length; i++) {
            const data = {};
            data.value = zoomabroadCourses.university_courses[i].id;
            data.label = zoomabroadCourses.university_courses[i].course_name;
            let index = zaCoursesData.findIndex(x => x.label == data.label);
            index === -1 ? zaCoursesData.splice(0, 0, data) : console.log("object already exists")
        }
    }
    let zaUniversityData = []
    if (zoomabroadUniversity && zoomabroadUniversity.university_name) {
        for (let i = 0; i < zoomabroadUniversity.university_name.length; i++) {
            const data = {};
            if (zoomabroadUniversity.university_name[i].university_name != "" && zoomabroadUniversity.university_name[i].university_name != "demo" && zoomabroadUniversity.university_name[i].university_name != "xyz" && zoomabroadUniversity.university_name[i].university_name != "test" && zoomabroadUniversity.university_name[i].university_name != "test22" && zoomabroadUniversity.university_name[i].university_name != "Qualifi" && zoomabroadUniversity.university_name[i].university_name != "qualifi") {
               data.value = zoomabroadUniversity.university_name[i].id;
                data.label = zoomabroadUniversity.university_name[i].university_name;
                let index = zaUniversityData.findIndex(x => x.label === data.label);
                index === -1 ? zaUniversityData.splice(0, 0, data) : console.log("object already exists")
            }
        }
    }
    let channelData = []
    if (channelList && channelList) {
        for (let i = 0; i < channelList.length; i++) {
            const data = {};
            data.value = channelList[i].id;
            data.label = channelList[i].channel_name;
            let index = channelData.findIndex(x => x.value == data.value);
            index === -1 ? channelData.splice(0, 0, data) : console.log("object already exists")
        }
    }

    let countActivity
    let pagesActivity
    if (logsDetails.logs && logsDetails.logs.length) {
        countActivity = logsDetails.pagination.count
        pagesActivity = logsDetails.pagination.pages
    }
    const handleEmails = (emailsData) => {
        const logs = []
        let emails = {}
        if (emailsData.logs && emailsData.logs.length) {
            for (let i = 0; i < emailsData.logs.length; i++) {
                if (emailsData.logs[i].type == 'email') {
                    logs.push(emailsData.logs[i])
                }
            }
            emails.logs = logs
            return emails
        }

    }
    const handleWhatsapp = (whatsAppData) => {
        const logs = []
        let whatsApp = {}
        if (whatsAppData.logs && whatsAppData.logs.length) {
            for (let i = 0; i < whatsAppData.logs.length; i++) {
                if (whatsAppData.logs[i].type == 'whats_app') {
                    logs.push(whatsAppData.logs[i])
                }
            }
            whatsApp.logs = logs
            return whatsApp
        }
    }


    const handleNotes = (notesData) => {
        const logs = []
        let notes = {}
        if (notesData.logs && notesData.logs.length) {
            for (let i = 0; i < notesData.logs.length; i++) {
                if (notesData.logs[i].type == 'note') {
                    logs.push(notesData.logs[i])
                }
            }
            notes.logs = logs
            return notes
        }

    }
    const handleTasks = (TasksData) => {
        const logs = []
        let Tasks = {}
        // let count = 0
        if (TasksData.logs && TasksData.logs.length) {
            for (let i = 0; i < TasksData.logs.length; i++) {
                if (TasksData.logs[i].type == 'task') {
                    logs.push(TasksData.logs[i])
                }
            }
            Tasks.logs = logs
            return Tasks
        }

    }
    const onError = () => { console.log("error") }
    const handleList = (data, status) => {
        if (data == 2) {
            return <li className="small-text"><img className="iconImageCheck" src={"https://zoomabroad-assets.s3.amazonaws.com/assets/img/dashboard-check-icon.png"} />{status}</li>
        }
        else if (data == 1) {
            return <li className="mediumText"><img className="iconImageRefresh" src={"https://zoomabroad-assets.s3.amazonaws.com/assets/img/dashboard-refresh-icon.png"} /> {status}</li>

        }
        else {
            return <li className="mediumText"><img className="iconImage" src={"https://zoomabroad-assets.s3.amazonaws.com/assets/img/dashboard-refresh-icon.png"} />{status}</li>

        }
    }
    const onAllocateModel = () => {
        switch (taskIndex) {
            case 0:
                return <SchedulingMeeting
                    onCloseModal={onCloseModal}
                />
            case 1:
                return <SchedulingMeeting
                    onCloseModal={onCloseModal}
                />
            case 2:
                return <AttendingMeeting
                    onCloseModal={onCloseModal}
                />
            case 3:
                return <RequiredDocuments
                    onCloseModal={onCloseModal}
                />
            case 4:
                return <ConfirmingInterest
                    onCloseModal={onCloseModal}
                />
            case 5:
                return <MakingFinalDecision
                    onCloseModal={onCloseModal}
                    studentCourses={studentCourses && studentCourses.length ? studentCourses : []}
                />
            case 6:
                return <Visa
                    // taskVisaAppointment={taskVisaAppointment}
                    onCloseModal={onCloseModal}
                />
            default:
                console.log("i is greater than 2.");
        }
    }
    return (
        <Container className="student-view">
            <Modal
                modalTitle={"Task Allocation"}
                isModalOpen={showTaskAllocateModal}
                onCloseModal={onCloseTaskAllocateModal}
            >
                <TaskAllocate
                    onCloseTaskAllocateModal={onCloseTaskAllocateModal}
                    onTaskIndex={onTaskIndex}
                />
            </Modal>
            <Modal
                modalTitle={"Feedback"}
                isModalOpen={showFeedbackform}
                onCloseModal={closefeedbackModal}
            >
               <FeedbackForm
               closefeedbackModal={closefeedbackModal}
               getFeedbackForm={getFeedbackForm}
               onChangeFeedback={onChangeFeedback}
               onSubmitFeedback={onSubmitFeedback}
               />
            </Modal>
            <Modal
                // showLargeModal={taskIndex == 4 || taskIndex == 5 ? true : false}
                className="task-allocation"
                modalTitle={taskName}
                isModalOpen={showAllocateModal}
                onCloseModal={onCloseModal}
            >
                {onAllocateModel()}
            </Modal>
            <Modal
                showLargeModal
                modalTitle={"Document"}
                isModalOpen={showDocModal}
                onCloseModal={onCloseModal}
                showDocumentModalClass


            >
                {modalDocUrl ? <FileViewer
                    fileType={modalDocUrl.split('.').pop()}
                    filePath={modalDocUrl}
                    // errorComponent={CustomErrorComponent}
                    onError={onError}
                />
                    : ''}
                {/* {modalDocUrl} */}
            </Modal>
            {isLoading ? <ZLoader /> : null}
            <Row>
                <Col md="6" className="header">
                    <h4>Profile</h4>
                </Col>
                <Col md="6" className="header">
                    <Button className="student-back external" href={`https://advisor.zoomabroad.com/student/view/${student.id}`} target="_blank">
                        <FontAwesomeIcon className="external-icon" icon={["fas", "external-link-alt"]} />
                    Open
            </Button>
                    <Button style={{ marginRight: 4 }} className="student-back external" onClick={() => onStudentList()}>Back</Button>

                </Col>

            </Row>
            <Row>
                <Col md={4} className="profile">
                    <Profile
                        student={student}
                        onMessage={onMessage}
                        onNote={onNote}
                        onTask={onTask}
                        members={members}
                        onChangeInput={onChangeInput}
                        assignedValue={assignedValue}
                        myState={myState}
                        openInNewTab={openInNewTab}
                        leadStatusValue={leadStatusValue}
                        leadState={leadState}
                        showApplicationCreateModal={showApplicationCreateModal}
                        sourceList={sourceList}
                        onWhatsapp={onWhatsapp}
                        onChatMessage={onChatMessage}
                        supportStaffValue={supportStaffValue}
                        mySupportState={mySupportState}
                        openFeedbackModal={openFeedbackModal}
                        applicationType={applicationType}
                        userProfile={userProfile && userProfile ? userProfile.user : ''}
                        onPhoneCall={onPhoneCall}
                        studentZA={studentZA}
                    />
                    {student && student.za_id ?
                        <Row className='task-section'>
                            <Col>
                                <ul>
                                    {ProgressCheckList && ProgressCheckList.length && checkList && checkList.length
                                        ? ProgressCheckList.map((data, index) =>
                                            handleList(checkList[0][data.id], data.status)
                                        )
                                        : ''}
                                </ul>
                                <div>
                                    <Button className="apply-button" onClick={() => taskAllocateModel(student.id)}>Task Allocation</Button>
                                </div>
                            </Col>
                        </Row> : ''
                    }
                    <Row className='course-section'>
                        <Col>
                            <div><h6>Course : {student.applied_course}</h6></div>
                            <div><h6>Course Type :{student.course_type}</h6></div>
                            <div><h6>Intake : {student.intake_month} {student.intake_year}</h6></div>
                        </Col>

                    </Row>

                    {student.other_applied_course ? <Row className='course-section'>
                        <Col>
                            <div>
                                <h6>Comment : </h6>

                                <p>{student.other_applied_course}</p>
                            </div>
                        </Col>

                    </Row> : ''}
                    {
                    studentCourses && studentCourses.length ?
                     <Row className='course-section'>
                        <Col>
                            <div>
                                <h6>Selected Offer : </h6>
                                {applied = studentCourses.map(acad => {
                                    if (acad.course_level == 'selected offer') {
                                        return (
                                        <p>{acad.applied_courses} <strong>{acad.university_name}</strong></p>
                                        )
                                    }
                                }
                                )}
                            </div>
                        </Col>

                    </Row> : ''
                    }
                    {
                    student && student.visaapt_by_std != null ?
                     <Row className='course-section'>
                        <Col>
                            <div>
                                <h6>Visa process by Student : {student.visaapt_by_std == 1 ? 'No' : student.visaapt_by_std != null ? 'Yes' : 'NA'} </h6>
                            </div>
                        </Col>

                    </Row> : ''
                    }
                    <Application
                        courses={studentCourses && studentCourses.length ? studentCourses : []}
                        myState={myState}
                        onChangetext={onChangetext}
                        onStatus={onStatus}
                        applicationStatus={applicationStatus && applicationStatus.length ? applicationStatus : []}
                        applicationValue={applicationValue}
                        applyZoomabroad={applyZoomabroad}
                        student={student}
                        showApplicationCreateModal={showApplicationCreateModal}
                        onCloseModal={onCloseModal}
                        onApplyCourse={onApplyCourse}
                        onChangeInput={onChangeInput}
                        onEditApplicationcoursename={onEditApplicationcoursename}
                        onApplyCreateCourse={onApplyCreateCourse}
                        onClearAppModal={onClearAppModal}
                        zaCoursesData={zaCoursesData}
                        zaUniversityData={zaUniversityData}
                        channelData={channelData}
                        channelList={channelList}
                        applicationCourseType={applicationCourseType}
                        applicationType={applicationType}
                        applicationCourseUniversity={applicationCourseUniversity}
                        appicationCourseCreate={appicationCourseCreate}
                        applicationChannelName={applicationChannelName}
                        applicationIntakeYear={applicationIntakeYear}
                        applicationIntakeMonth={applicationIntakeMonth}
                        showApplicationStatusModal={showApplicationStatusModal}
                        onApplicationStatusModal={onApplicationStatusModal}
                        applicationStatusIndex={applicationStatusIndex}
                        applicationModalName={applicationModalName}
                        applicationStatusId={applicationStatusId}
                        clearModel={clearModel}
                        setClearForm={setClearForm}
                        openApplicationEditModal={openApplicationEditModal}
                        showApplicationNumberModal={showApplicationNumberModal}
                        onApplicationEditModal={onApplicationEditModal}
                        onChangeApplication={onChangeApplication}
                        applicationNumber={applicationNumber}
                        onIntakeEditModal={onIntakeEditModal}
                        application_intake_month={application_intake_month}
                        applied_courses={applied_courses}
                        application_type={application_type}
                        showApplicationTypeModal={showApplicationTypeModal}
                        application_intake_year={application_intake_year}
                        showApplicationIntakeModal={showApplicationIntakeModal}
                        openApplicationIntakeEditModal={openApplicationIntakeEditModal}
                        openApplicationCourseEditModal={openApplicationCourseEditModal}
                        openApplicationTypeEditModal={openApplicationTypeEditModal}
                        showApplicationcourseModal={showApplicationcourseModal}
                        onCourseEditModal={onCourseEditModal}
                        studentAppicationIntake={studentAppicationIntake}
                        onApplicationTypeEditModal={onApplicationTypeEditModal}
                        userProfile={userProfile && userProfile ? userProfile.user : ''}
                    />
                    <Education student={student}
                        showDocModal={showDocModal}
                        showDocumentModal={showDocumentModal}
                    />
                    <Experience student={student}
                        showDocModal={showDocModal}
                        showDocumentModal={showDocumentModal}
                    />
                    <Language student={student}
                        showDocModal={showDocModal}
                        showDocumentModal={showDocumentModal}
                    />
                </Col>
                <Col md={8}>
                    <Row>
                        <Col className="tab-section">
                            <Tabs
                                animate={true}
                                id="TabsExample"
                                key={"horizontal"}
                                renderActiveTabPanelOnly={true}
                            >
                                <Tab id="rx" title="Activity" panel={
                                    <Activities
                                        logsDetails={logsDetails}
                                        isLoading={isLoading}
                                        count={countActivity ? countActivity : 0}
                                        pages={pagesActivity ? pagesActivity : 1}
                                        onPageChange={onPageChange}
                                        itemRender={itemRender}
                                        pageIndex={pageIndex}
                                        onChangetext={onChangetext}
                                        status={status}
                                        myState={myState}
                                        onNote={onNote}
                                        onTask={onTask}
                                        onDeleteStudentLogs={onDeleteStudentLogs}
                                        onWhatsappEdit={onWhatsappEdit}


                                    />} />
                                <Tab id="ng" title="Notes" panel={<Activities
                                    logsDetails={handleNotes(logsDetails) ? handleNotes(logsDetails) : {}}
                                    isLoading={isLoading}
                                    index={logsDetails.logs && logsDetails.logs.length ? logsDetails.logs.length : 0}
                                />} />
                                <Tab id="wm" title="Whatsapp" panel={<Activities
                                    logsDetails={handleWhatsapp(logsDetails) ? handleWhatsapp(logsDetails) : {}}
                                    isLoading={isLoading}
                                />} />
                                <Tab id="em" title="Emails" panel={<Activities
                                    logsDetails={handleEmails(logsDetails) ? handleEmails(logsDetails) : {}}
                                    isLoading={isLoading}
                                />} />
                                <Tab id="mb" title="Tasks" panel={<Activities
                                    logsDetails={handleTasks(logsDetails) ? handleTasks(logsDetails) : {}}
                                    isLoading={isLoading}
                                    onChangetext={onChangetext}
                                    status={status}
                                    myState={myState}

                                />} />
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal
                modalTitle={"Compose email"}
                isModalOpen={onShowMessage}
                onCloseModal={onCloseModal}
                width={'700px'}
            >
                <EmailComposer
                    userProfile={userProfile}
                    student={student}
                    closeModal={onCloseModal}
                    userAuthDetails={{}}
                    onChange={onChange}
                    fromEmail={fromEmail}
                    toEmail={toEmail}
                    courses={studentCourses && studentCourses.length ? studentCourses : []}
                    isLoading={isLoading}

                />
            </Modal>
            <Modal
                modalTitle={"New Note"}
                isModalOpen={onShowNote}
                onCloseModal={onCloseModal}
                width={'700px'}
            >
                <Note
                    student={student}
                    userProfile={userProfile}
                    closeModal={onCloseModal}
                    courses={studentCourses && studentCourses.length ? studentCourses : []}
                    subject={subject}
                    onSubjectChange={onSubjectChange}
                    courseName={courseName}
                    onChangeInput={onChangeInput}
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    editState={editState}
                    editId={editId}
                    onEditState={onEditState}
                    onDeleteStudentLogs={onDeleteStudentLogs}
                    noteStatusValue={noteStatusValue}
                    editorNoteState={editorNoteState}
                    onEditNoteChange={onEditNoteChange}
                    isLoading={isLoading}
                />
            </Modal>
            <Modal
                modalTitle={"New Task"}
                isModalOpen={onShowTask}
                onCloseModal={onCloseModal}
                width={'700px'}
            >
                <Task
                    student={student}
                    userProfile={userProfile}
                    closeModal={onCloseModal}
                    courses={studentCourses && studentCourses.length ? studentCourses : []}
                    onSubjectChange={onSubjectChange}
                    courseName={courseName}
                    onChangeInput={onChangeInput}
                    editorState={editorState}
                    onEditorStateChange={onEditorStateChange}
                    editState={editState}
                    editId={editId}
                    onEditState={onEditState}
                    subject={subject}
                    onDeleteStudentLogs={onDeleteStudentLogs}
                    isLoading={isLoading}

                />
            </Modal>
            <Modal
                modalTitle={"Whatsapp message"}
                isModalOpen={onShowWhatsapp}
                onCloseModal={onCloseModal}
                width={'700px'}
            >
                <WhatsApp
                    userProfile={userProfile}
                    student={student}
                    closeModal={onCloseModal}
                    userAuthDetails={{}}
                    onChange={onChange}
                    fromEmail={fromEmail}
                    toEmail={toEmail}
                    courses={studentCourses && studentCourses.length ? studentCourses : []}
                    isLoading={isLoading}
                    phoneNumber={phoneNumber}
                    editorWhatsState={editorWhatsState}
                    oneditorWhatsChange={oneditorWhatsChange}
                    onEditState={onEditState}

                />
            </Modal>
            <Modal
                modalTitle={"Create Status"}
                isModalOpen={onShowStatus}
                onCloseModal={onCloseModal}
                width={'700px'}
            >
                <Status
                    student={student}
                    userAuthDetails={userProfile}
                    closeModal={onCloseModal}
                />
            </Modal>
            {
                onShowChatMessage ?

                    <ChatMessage
                        student={student}
                        userAuthDetails={userProfile}
                        closeModal={onCloseModal}
                    />
                    : ''
            }
        </Container>
    )
}
export default StudentViewMarkup;
