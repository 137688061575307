import { combineReducers } from 'redux';
import Auth from './screens/Auth/modules/reducer';
import Dashboard from './screens/Dashboard/modules/reducer';
import CreateUser from './screens/CreateUser/modules/reducer';
import interview from './screens/Interview/modules/reducer'
import students from './screens/Students/modules/reducer'
import courses from './screens/Course/modules/reducer'
import Integration from './screens/Integration/modules/reducer'
import wizard from './screens/Wizards/modules/reducer'
import userRole from './screens/UserRole/modules/reducer'
import members from './screens/Members/modules/reducer'
import source from './screens/Source/modules/reducer'
import channel from './screens/Channel/modules/reducer'
import reports from './screens/Report/modules/reducer'
import marketing from './screens/Marketing/modules/reducer'
import activity from './screens/activity/modules/reducer'
import customerReport from './screens/ReportExport/modules/reducer'
import cohort from './screens/Cohort/modules/reducer'

export default combineReducers({
    Auth,
    Dashboard,
    CreateUser,
    interview,
    students,
    courses,
    Integration,
    wizard,
    userRole,
    members,
    source,
    channel,
    reports,
    marketing,
    activity,
    customerReport,
    cohort
});