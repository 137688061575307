import classNames from "classnames";
import * as React from "react";
import { Button, Classes, ContextMenu, ContextMenuTarget, Menu, MenuDivider, MenuItem, Tag } from "@blueprintjs/core";
// import { Example, IExampleProps } from "@blueprintjs/docs-theme";
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZLoader } from '../../../shared/loader/ZLoader'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import moment from 'moment';
import "./StudentMarkup.scss"
import Modal from "../../../shared/model/model"
import { URL } from '../../../env'
import { Player } from 'video-react';
import screenshot from "../../../img/experience.png"
import mailSend from '../../../assets/mailNew.png'

const StudentMarkup = (props) => {



    const showContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        // invoke static API, getting coordinates from mouse event
        ContextMenu.show(
            <Menu>
                <MenuItem onClick={() => window.open(`${URL}/student/view/${items.id}`, "_blank")} icon="search-around" text="Open in new tab" />
            </Menu>,
            { left: e.clientX, top: e.clientY },
            () => props.SetContextMenuClose(),
        );
        // indicate that context menu is open so we can add a CSS class to this element
        props.SetContextMenuOpen();
    }

    const userAccess = (userRoleAccess, act) => {
        if (userRoleAccess[0] == 1 || userRoleAccess[1] == 1 || userRoleAccess[2] == 1 || userRoleAccess[3] == 1) {
            return true
        }
        else {
            return false
        }
    }
    const subscriberStatusColor = (created) => {
        let hours = moment().diff(moment(created), 'hours');
        if (hours <= 24) {
            return 'green'
        }
        if (hours <= 48) {
            return 'amber'
        }
        if (hours > 48) {
            return 'red'
        }

    }
    const statusColor = (count) =>{
        console.log("countcount??????/////", count)
        if (count >= 2) {
            return 'green'
        }
        if (count == 1) {
            return 'amber'
        }
        if (count == 0 || count == '' || count == null ) {
            return 'red'
        }
    }
    const handleEligible = (eligible) => {
        if (eligible == "NA") {
            return ""
        }
        if (eligible == "Eligible") {
            return "bp3-intent-success"
        }
        if (eligible == "Not Eligible") {
            return "bp3-intent-danger"
        }
    }
    const capitalizeFirstLetter = (string) => {
        if (string) {
            var str
            str = string[0].toUpperCase() + string.slice(1);
            return str.replace(/_/g, " ");
        }
    }

    const ownerName = (assinged_id) => {
        const { allMembers } = props;
        if (allMembers && allMembers) {
            if (allMembers.Users && allMembers.Users) {
                for (let i = 0; i < allMembers.Users.length; i++) {
                    if (allMembers.Users[i].user_id == assinged_id) {
                        return allMembers.Users[i].first_name
                    }
                }
            }
        }
    }
    const sourceName = (source_id) => {
        const { sourceList } = props
        if (sourceList && sourceList) {
            for (let i = 0; i < sourceList.length; i++) {
                if (sourceList[i].id == source_id) {
                    return sourceList[i].source_short_name
                }
            }
        }
    }
    const referenceName = (refer) => {
        if (refer && refer != 'organic') {
            return refer
        }
        else return ''
    }
    const handlesInterviewName = (students, items, handleOpenInterview, selectedInterview) => {
        return students ? students.map(inter => {

            if (items.id == inter.student_id) {
                console.log(selectedInterview, "+++++++++++++++++++++++++++++++++++++++++");

                if (inter.application_url) {
                    return <><span className="interview-name">{inter.interview_name}</span>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">View Interview</Popover>}>

                            <FontAwesomeIcon onClick={() => onshowVideoModal(inter)} className="cross-icon video" icon={['fas', 'video']} />
                        </OverlayTrigger>
                        <Modal
                            modalTitle={"Interview"}
                            isModalOpen={showVideoModal}
                            onCloseModal={onCloseModal}
                        >
                            {showVideoModal ? <Player
                                playsInline
                                poster={screenshot}
                                src={`https://zoomabroadv2.s3.amazonaws.com/${selectedInterview ? selectedInterview.application_url : ''}`} //{`https://zoomabroadv2.s3.amazonaws.com/interview/${videoLink}`}
                                className="players"

                            /> : null}

                        </Modal>
                    </>

                }
                if (inter.interview_name && !inter.application_url) {
                    return <><span className="interview-name">{inter.interview_name}
                        {/* <FontAwesomeIcon onClick={onDeleteInterview} className="cross-icon"  icon={['fas', 'minus-circle']}/> */}
                    </span>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Delete Interview</Popover>}>
                            <FontAwesomeIcon onClick={() => onDeleteInterview(inter.student_id)} className="cross-icon" icon={['fas', 'trash-alt']} />

                        </OverlayTrigger>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Send Interview</Popover>}

                        >
                            <img src={mailSend} onClick={() => handleOpenInterview(items.email, inter)} className="emailSend" height={14} />
                            {/* <FontAwesomeIcon  onClick={() => handleOpenInterview(items.email, inter)} className="cross-icon mail" /> */}
                        </OverlayTrigger>
                        <Modal
                            modalTitle={"Send Interview"}
                            isModalOpen={showInterview}
                            onCloseModal={onCloseModal}
                        >
                            <input placeholder="Email" className="form-control modal-email" type="email" onChange={(event) => handleTextChange('interviewEmail', event)} value={interviewEmail}></input>
                            {/* <Ckeditor onChange={(event)=>handleChangeCk(event)} value={interviewEmailContent}  name={"interviewEmailContent"}  /> */}
                            <Button className="send-button" onClick={() => onSendInterview(items)}>Send</Button>

                        </Modal>
                    </>

                }
                else {
                    return <><span className="interview-name">N/A</span>
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Assign Interview</Popover>}>
                            <FontAwesomeIcon className="cross-icon plus" onClick={() => handleClick(items.id)} icon={['fas', 'user-plus']} />
                        </OverlayTrigger>
                    </>
                }
            }
        }) : []

    };
    const { selectedInterview, userRoleAccess, onDeleteInterview, showVideoModal, onshowVideoModal, showModal, onSendInterview, handleChangeCk, interviewEmailContent, interviewEmail, handleTextChange, onCloseModal, handleOpenInterview,
        showInterview, interviewData, handleClick, items, reportPush, editPush, updateDirection, index, openInNewTab, openStudentEdit, allMembers, count, isLoading } = props;
    const classes = classNames("context-menu-node", {
        "context-menu-open": props.isContextMenuOpen,
    });
    console.log("userRoleAccessuserRoleAccessuserRoleAccess", userRoleAccess)
    return (

        <div className={`outer-student-container ${showInterview || showVideoModal ? " hide-hover" : " show-hover"}`}  > {/* {onClick={() =>reportPush(items.id)}}*/}
            {isLoading && isLoading ? <ZLoader /> : null}

            <Accordion className={classes} onContextMenu={showContextMenu}>
                <Card className="cards rounded ">
                    <Accordion.Toggle onc id={`studentid_${index}`} onClick={(event) => updateDirection(event)} as={Card.Header} className={`demo-class`} eventKey="0"  >
                        <Row className={classes} onContextMenu={showContextMenu}>
                            <OverlayTrigger
                                placement={'top'}
                                delay={{ hide: 200 }}
                                overlay={<Popover className="tool-tip" id="tooltip-disabled"><p> Created at: {moment(items.created_at).format('lll')}</p> Last action at :{moment(items.last_activity ? items.last_activity : items.created_at).format('lll')}</Popover>}>
                                <Col md="2" xs="12" className="name-container" >
                                    <h6>{items.first_name} {items.middle_name} {items.last_name}</h6>
                                </Col>
                            </OverlayTrigger>
                            <Col className="remove-padding name-container">
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Profile</Popover>}>
                                    {userAccess(userRoleAccess) ? <FontAwesomeIcon onClick={() => editPush(items.id)} className="cross-icon edit" icon={['fas', 'eye']} /> : null}
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                                    <FontAwesomeIcon onClick={() => openStudentEdit(items.id)} className="cross-icon edit" icon={['fas', 'edit']} />
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Report</Popover>}>
                                    {userAccess(userRoleAccess) ? <FontAwesomeIcon onClick={() => reportPush(items.id)} className="cross-icon edit" icon={['fas', 'id-badge']} /> : null}
                                </OverlayTrigger>

                            </Col>
                            <Col md="1" xs="12" className="remove-padding">
                                <p>{` ${items.score > 0 ? `${items.score ? Math.round(items.score) : items.score} / 1000` : "N/A"}`}</p>
                            </Col>
                            {/* <Col md="2" xs="5" className="text-left">
                                <h6>{items.applied_course}</h6>
                            </Col> */}
                            <Col md="1" xs="1" className="text-left" style={{ padding: 0 }}>
                                <h6 class={`bp3-tag eligibility ${handleEligible(items.eligible)}`}>{items.eligible ? items.eligible : "NA"}</h6>
                            </Col>
                            <Col md="1" xs="5" className="text-left">
                                {items.lead_status == 'subscriber' || items.lead_status == 'lost' || items.lead_status == 'referrer'? <Tag className={`subscriber-${subscriberStatusColor(items.created_at)}`}>{capitalizeFirstLetter(items.lead_status)}</Tag> : items.lead_status == 'ringing' || items.lead_status == 'hot_lead' || items.lead_status == 'lead' ?  <Tag className={`lead-${statusColor(items.task_count)}`}>{capitalizeFirstLetter(items.lead_status)}</Tag> : <h6>{capitalizeFirstLetter(items.lead_status)}</h6>}

                            </Col>
                            {/* <Col md="2" xs="6" style={{ backgroundColor: "#f1f5f8" }} >
                                {handlesInterviewName(interviewData, items, handleOpenInterview, selectedInterview)}

                            </Col> */}
                            <OverlayTrigger
                                placement={'top'}
                                delay={{ hide: 200 }}
                                overlay={<Popover className="tool-tip" id="tooltip-disabled">{sourceName(items.source_id) ? `${sourceName(items.source_id)} ${referenceName(items.reference_by)}` : items.reference_by}</Popover>}>
                                <Col md="1" style={{ padding: 0 }}>
                                    <h6>{sourceName(items.source_id) ? `${sourceName(items.source_id)} ${referenceName(items.reference_by)}` : items.reference_by}</h6>
                                </Col>
                            </OverlayTrigger>
                            <Col md="2">
                                <h6>{capitalizeFirstLetter(items.intake_month)} {items.intake_year}</h6>
                            </Col>
                            <Col md="1">
                                <h6>{ownerName(items.assigned_id)}</h6>
                            </Col>
                            {/* <Col>
                            </Col> */}
                            <Col md="1" xs="6" style={{ backgroundColor: "#f1f5f8", marginTop: "0px", padding: 0 }} >
                                <p className="text-center">{moment(items.last_activity ? items.last_activity : items.created_at).format('ll')}</p>

                            </Col>
                            <Col md="1" xs="6" className="button-conatiner" style={{ padding: 0 }}>
                                {moment(items.created_at).format('ll')}
                                {/* {userAccess(userRoleAccess) ? <Button className="report-button" onClick={() => reportPush(items.id)}>Report</Button> : ''} */}
                            </Col>

                        </Row>
                    </Accordion.Toggle>

                </Card>
                {/* <div className="date-hide">
                    <p>created at :&nbsp;{moment(items.created_at).format('LLLL')}</p>
                    <p>last action at:&nbsp;{moment(items.last_activity).format('LLLL')}</p>
                </div> */}
            </Accordion>

        </div>
    )
}

export default StudentMarkup
