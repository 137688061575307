import React, { Component } from 'react';
import { FormGroup, RadioGroup, Radio, InputGroup, Card, Elevation } from "@blueprintjs/core";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Button, Form, Col, Row ,OverlayTrigger,Popover} from 'react-bootstrap';
import Modal from '../../../shared/model/model'
import EducationUpdate from '../components/Edit/EducationUpdate'
import ExperienceUpdate from './Edit/ExperienceUpdate'
import ProficiencyUpdate from './Edit/ProficiencyUpdate'
import { CLOUDFROND_ASSET_URL } from '../../../env'
import "./StudentEditMarkup.scss"
import { ZLoader } from '../../../shared/loader/ZLoader';
import "../../../../node_modules/video-react/dist/video-react.css";
import FileViewer from 'react-file-viewer';

import moment from 'moment';

const StudentEdit = (props) => {

  const { student, onChangeInput, firstName, lastName, openEducationModal, educationModal, closeModal, onSave, degreeType,
    eduInstitute, eduName, eduCourse, eduStart, eduEnd, eduStartMonth, eduEndMonth, eduOption, eduMark, eduDescription, onEducationUpdate, errors,
    experienceModal, openExperienceModal, titleExp, companyExp, locationExp, typeExp, onExperienceUpdate, expStartMonth, expEndMonth, descriptionExp,
    endExp, startExp, proficiencyModal, openProficiencyModal, listening, reading, writing, speaking, overall, onProficiencyUpdate, proficiencyType, email, phoneNumber,
    paymentMethod, financialCapacity, gap, country, sopValue, addNewDocument, onSaveDocument, passportValue, lorValue, visaValue, otherdocValue,
    educationState, experienceState, languageEfficiencyState, deleteEducation, deleteProficiency, deleteExperience, addEduFile, addFile, documentFile, addexperienceFile
    , sopData, lorData, passportData, otherDocData, otherDocDescription, visaData, startDate, endDate, visaDocDescription, passportDocDescription, alternativePhoneNumber, alternativeEmail,
    alternativeEmailModal, openAltenativeEmailModal,altEmailEdit,eduacationEdit,isLoading,isSaasOfferLoading,isAcademicLoading,proficiencyEdit,experienceEdit, editAlternativeEmail, addAlternativeEmail, 
    deleteAltenativeEmail,deleteAltenativePhone, alternativePhoneModal, openAltenativePhoneModal, editPhoneNumber, addAlternativePhone, onStudentList, intakeYear, intakeMonth ,editmailerror,altPhoneEdit,
    showDocModal,openDocModal, viewDocFile, isLoadingIntegration, cvDoc, cvDocDescription, cvValue, editPhoneerror} = props;


  // alternativePhoneNumber={alternativePhoneNumber}
  // alternativePhoneModal={alternativePhoneModal}
  // openAltenativePhoneModal={this.openAltenativePhoneModal}
  // editPhoneNumber={editPhoneNumber}
  // addAlternativePhone={this.addAlternativePhone}

  const handleCourse = (education) => {
    if (education.name == 'secondary') {
      return 'Secondry Education';
    }
    if (education.name == 'higherSecondary') {
      return 'Higher Secondry Education';
    }
    return education.course;
  };
  const handleNameHeader = (education) => {
    if (education.name == 'secondary') {
      return 'Secondry Education';
    }
    if (education.name == 'higherSecondary') {
      return 'Higher Secondry Education';
    }
    return education.name;
  };
  const handleName = (education) => {
    if (education.name == 'secondary') {
      return '';
    }
    if (education.name == 'higherSecondary') {
      return '';
    }
    return education.name;
  };
  const userEduDocument = (document) => {
    if (document.doc_url_degree || document.doc_url_marksheet) {
      return (
        <a href={`${document.doc_url_degree || document.doc_url_marksheet}`}>
          <span>
            <OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </OverlayTrigger>
          </span>
        </a>

      );
    }
    if (document.lang_doc_url) {
      return (
        <a href={`${document.lang_doc_url}`}>
          <span>
              <OverlayTrigger
                placement={'top'}
                delay={{ hide: 200 }}
                overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
        </a>

      );
    }
    if (document.exp_doc_url) {
      return (
        <a href={`${document.exp_doc_url}`}>
          <span>
              <OverlayTrigger
                  placement={'top'}
                  delay={{ hide: 200 }}
                  overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                </OverlayTrigger>
            </span>
        </a>

      );
    }
  };
  const sopDoc = (sop) => {
    if (sop) {
      return (
        <a href={`${sop}`}>
          <span>
              <OverlayTrigger
                  placement={'top'}
                  delay={{ hide: 200 }}
                  overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
        </a>

      );
    }
  }
  const otherDocDisplay = (sop) => {
    if (sop) {
      return (
        <a href={`${sop}`}>
          <span>
              <OverlayTrigger
                  placement={'top'}
                  delay={{ hide: 200 }}
                  overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                <i class="fa fa-cloud-download" aria-hidden="true"></i>
              </OverlayTrigger>
            </span>
        </a>

      );
    }
  }
  // sopValue, addNewDocument, onSaveDocument, passportValue, lorValue, visaValue, otherdocValue
  const onError = ()=>{
  }
  return (
    
    <div className="container result-container">
       {
        isLoading || isLoadingIntegration ? <ZLoader/> :
        <>
      <div className="header">

        <Button style={{ marginRight: 4 }} className="student-back external" onClick={() => onStudentList()}>Back</Button>

      </div>
      <Modal 
            showLargeModal
            modalTitle={"Document"}
            isModalOpen={showDocModal}
            onCloseModal={closeModal}
            showDocumentModalClass
        >
      {viewDocFile  ?  <FileViewer
        fileType={viewDocFile.split('.').pop().toLowerCase()}
        filePath={viewDocFile}
        // errorComponent={CustomErrorComponent}
        onError={onError()}
        />
        :''}
            {/* {viewDocFile} */}
            </Modal>
      <Modal
        modalTitle={eduacationEdit ? 'Update Education' : 'Add Education'}
        isModalOpen={educationModal}
        onCloseModal={closeModal}
      >
        <EducationUpdate
          errors={errors}
          eduStartMonth={eduStartMonth}
          eduEndMonth={eduEndMonth}
          addFile={addFile}
          eduInstitute={eduInstitute}
          eduCourse={eduCourse}
          eduName={eduName}
          eduMark={eduMark}
          onChangeInput={onChangeInput}
          onEducationUpdate={onEducationUpdate}
          eduOption={eduOption}
          //   deleteEducation={deleteEducation}
          documentFile={documentFile}
          degreeType={degreeType}
          eduStart={eduStart}
          eduEnd={eduEnd}
          //   onChangeDate={onChangeDate}
          eduDescription={eduDescription}
          //   onChangeEndDate={onChangeEndDate}
          // educationDetails={educationDetails}
          addEduFile={addEduFile}
          eduacationEdit={eduacationEdit}
          isLoading={isLoading}
        />
      </Modal>
      <Modal
        modalTitle={altEmailEdit ?  "Update Alternative Email" : "Add Alternative Email"}
        isModalOpen={alternativeEmailModal}
        onCloseModal={closeModal}
      >
        <>
          <Form.Label>Email</Form.Label>
          <Form.Control
            placeholder="Email"
            type="text"
            className="form-control modal-email"
            value={editAlternativeEmail}
            onChange={(event) => onChangeInput('editAlternativeEmail', event.target.value)}
          />
          {editmailerror ? <span style={{color:'red'}}>Please enter valid email address</span> : ''}
          <Button className="button1 pull-right" disabled={editAlternativeEmail ? false : true} style={{ marginTop: "15px" }} onClick={() => addAlternativeEmail()}>{altEmailEdit ? "Update": "Add"}</Button>
        </>
      </Modal>

      <Modal
        modalTitle={altPhoneEdit ? "Update Alternative Phone Number" : "Add Alternative Phone Number"}
        isModalOpen={alternativePhoneModal}
        onCloseModal={closeModal}
      >
        <>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            placeholder="phone number"
            type="text"
            className="form-control modal-email"
            value={editPhoneNumber}
            onChange={(event) => onChangeInput('editPhoneNumber', event.target.value)}
          />
                    {editPhoneerror ? <span style={{color:'red'}}>Please enter valid phone number</span> : ''}

          <Button className="button1 pull-right" disabled={editPhoneNumber ? false : true} style={{ marginTop: "15px" }} onClick={() => addAlternativePhone()}>{altPhoneEdit ? "Update": "Add"}</Button>
        </>
      </Modal>

      <Modal
        modalTitle={experienceEdit ? "Edit Experience" : "Add Experience"}
        isModalOpen={experienceModal}
        onCloseModal={closeModal}
      >
        <ExperienceUpdate
          expStartMonth={expStartMonth}
          expEndMonth={expEndMonth}
          descriptionExp={descriptionExp}
          addexperienceFile={addexperienceFile}
          // onChangeEndDate={onChangeEndDate}
          titleExp={titleExp}
          companyExp={companyExp}
          endExp={endExp}
          startExp={startExp}
          locationExp={locationExp}
          typeExp={typeExp}
          onChangeInput={onChangeInput}
          onExperienceUpdate={onExperienceUpdate}
          // deleteExperience={deleteExperience}
          // onChangeDate={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          addFile={addFile}
          errors={errors}
          experienceEdit={experienceEdit}

        />
      </Modal>
      <Modal
        modalTitle={proficiencyEdit ? "Edit Proficiency" : "Add Proficiency"}
        isModalOpen={proficiencyModal}
        onCloseModal={closeModal}
      >
        <ProficiencyUpdate
          onChangeInput={onChangeInput}
          onProficiencyUpdate={onProficiencyUpdate}
          // deleteEducation={deleteEducation}
          overall={overall}
          listening={listening}
          reading={reading}
          speaking={speaking}
          writing={writing}
          proficiencyType={proficiencyType}
          proficiencyEdit={proficiencyEdit}
          // deleteProficiency={deleteProficiency}
          addFile={addFile}
        errors={errors}

        />
      </Modal>
      <FormGroup>
        <div className='student-name'>
          <Row>
            <Col className='first-name'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="first name"
                className="form-control modal-email"
                value={firstName}
                readOnly={true}
                disabled={true}
                onChange={(event) => onChangeInput('firstName', event.target.value)}
              />
            </Col>
            <Col className='last-name'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder="last name"
                type="text"
                className="form-control modal-email"
                value={lastName}
                readOnly={true}
                disabled={true}
                onChange={(event) => onChangeInput('lastName', event.target.value)}
              />

            </Col>
          </Row>
          <Row >
            <Col className='first-name'>
              <Form.Label>Primary Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="email"
                className="form-control modal-email"
                value={email}
                readOnly={true}
                disabled={true}
                onChange={(event) => onChangeInput('email', event.target.value)}
              />
            </Col>
            <Col className='first-name'>
              <Form.Label>Primary Phone Number</Form.Label>
              <Form.Control
                placeholder="phone number"
                type="text"
                className="form-control modal-email"
                value={phoneNumber}
                readOnly={true}
                disabled={true}
                onChange={(event) => onChangeInput('phoneNumber', event.target.value)}
              />

            </Col>
          </Row>
          <Row >

            <Col md='5' className='first-name'>
              <Form.Label>Alternative Email</Form.Label>
              {alternativeEmail && alternativeEmail ? alternativeEmail.map((emailName, index) => (
                <Row className="alternative-input">
                  <Col md="9" sm="7" className="alternative-email">{emailName}</Col>
                  <Col md="1" sm="2">
                      <OverlayTrigger
                          placement={'top'}
                          delay={{ hide: 200 }}
                          overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                        <i class="fa fa-pencil edit-pencil" aria-hidden="true" onClick={() => openAltenativeEmailModal(emailName, index)}></i>
                    </OverlayTrigger> 
                 </Col>
                 <Col md="1" sm="2">
                      <OverlayTrigger
                          placement={'top'}
                          delay={{ hide: 200 }}
                          overlay={<Popover className="tool-tip" id="tooltip-disabled">delete</Popover>}>
                        <i class="fa fa-trash edit-pencil" aria-hidden="true" onClick={() => deleteAltenativeEmail(emailName, index)}></i>
                    </OverlayTrigger> 
                 </Col>
                 </Row>
                 )) : ''}
                {errors.alternativeEmail ? <span className="errors">Please enter valid phone number</span> : ''}

            </Col>
            <Col>
              <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Add</Popover>}>
                    
                <i className='plus-icon' class="fa fa-plus add-icon" aria-hidden="true" onClick={() => openAltenativeEmailModal('add')}></i>
              </OverlayTrigger>
            </Col>
            <Col md='5' className='first-name'>
              <Form.Label>Alternative Phone Number</Form.Label>
              {alternativePhoneNumber && alternativePhoneNumber ? alternativePhoneNumber.map((phoneNumber, index) => (
                <Row className="alternative-input">
                  <Col md="8" className="alternative-email">{phoneNumber}</Col>
                  <Col md="2">
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ hide: 200 }}
                        overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                          <i class="fa fa-pencil edit-pencil" aria-hidden="true" onClick={() => openAltenativePhoneModal(phoneNumber, index)}></i>
                    </OverlayTrigger>
                  </Col>
                  <Col md="2">
                    <OverlayTrigger
                          placement={'top'}
                          delay={{ hide: 200 }}
                          overlay={<Popover className="tool-tip" id="tooltip-disabled">delete</Popover>}>
                      <i class="fa fa-trash edit-pencil" aria-hidden="true" onClick={() => deleteAltenativePhone(phoneNumber, index)}></i>
                    </OverlayTrigger>
                    </Col>
                    {errors.alternativePhoneNumber ? <span className="errors">Please enter valid phone number</span> : ''}

                </Row>)) : ''}
            </Col>
            <Col>
              <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Add</Popover>}>
                    
                <i className='plus-icon pull-right' class="fa fa-plus add-icon" aria-hidden="true" onClick={() => openAltenativePhoneModal('add')}></i>
              </OverlayTrigger>
            </Col>


          </Row>
          <Row >
            <Col className='first-name'>
              <Form.Label>Payment Method</Form.Label>
              <Form.Control
                as="select"
                placeholder="first name"
                className="form-control modal-email"
                value={paymentMethod}
                onChange={(event) => onChangeInput('paymentMethod', event.target.value)}>
                <option value="">Select type</option>
                <option value="selfFamily">Self / Family</option>
                <option value="scholarshipAid">Scholarship / Aid</option>
                <option value="educationLoan">Education Loan</option>
              </Form.Control>
              {errors.paymentMethod ? <span className="errors">Please Select Appropriate Payment Method</span> : ''}
            </Col>
            <Col className='first-name'>
              <Form.Label>Financial Capacity</Form.Label>
              <Form.Control
                // disabled={!paymentMethod}
                placeholder="financial capacity"
                type="text"
                className="form-control modal-email"
                value={financialCapacity}
                onChange={(event) => onChangeInput('financialCapacity', event.target.value)}
              />
               {errors.financialCapacity ? <span className="errors">Please Enter Financial Capacity</span> : ''}
            </Col>
          </Row>
          <Row >
            <Col className='first-name'>
              <Form.Label>Educational Gap</Form.Label>
              <Form.Control
                as="select"
                placeholder="educational gap"
                className="form-control modal-email"
                value={gap}
                onChange={(event) => onChangeInput('gap', event.target.value)}

              >
                <option value="">Select gap</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </Form.Control>
              {errors.gap ? <span className="errors">Please Enter Educational Gap if any?</span> : ''}
            </Col>
            <Col className='first-name'>
              <Form.Label>Student Origin Country</Form.Label>
              <Form.Control
                placeholder="country"
                as="select"
                className="form-control modal-email"
                value={country}
                onChange={(event) => onChangeInput('country', event.target.value)}
              >
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">Netherlands (Holland, Europe)</option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">Republic of Montenegro</option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">United Arab Emirates</option>
                <option value="United States of America">United States of America</option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>

              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group >
                <Form.Label>Intake Year : </Form.Label>
                <Form.Control
                  className="select-options"
                  name={"intakeYear"}
                  as="select"
                  placeholder="Status"
                  value={intakeYear ? intakeYear : null}
                  onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                >
                  <option value="">Select</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                </Form.Control>
              </Form.Group>
            </Col><Col>
              <Form.Group >
                <Form.Label>Intake Month : </Form.Label>
                <Form.Control
                  className="select-options"
                  name={"intakeMonth"}
                  as="select"
                  placeholder="Status"
                  value={intakeMonth ? intakeMonth : null}
                  onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                >

                  <option value="">Select</option>
                  <option value="january">January</option>
                  <option value="february">February</option>
                  <option value="march">March</option>
                  <option value="april">April</option>
                  <option value="may">May</option>
                  <option value="june">June</option>
                  <option value="july">July</option>
                  <option value="august">August</option>
                  <option value="september">September</option>
                  <option value="october">October</option>
                  <option value="november">November</option>
                  <option value="december">December</option>

                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className='save-button'>
            <Button /* disabled={!(paymentMethod && financialCapacity && gap && intakeYear && intakeMonth)} */ className="save" onClick={() => onSave()} >Save</Button>
          </Row>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <Col>
                <div className='education-header'>Education</div>
              </Col>
              <Col>
                <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Add</Popover>}>
                    
                  <i className='plus-icon' class="fa fa-plus" aria-hidden="true" onClick={() => openEducationModal('add')}></i>
              </OverlayTrigger>
              </Col>
            </Row>
            <div className="education-details-container">
             
              {educationState && educationState.length ? (
                educationState.map((education, index) => (
                  <Row className="education-row">
                    <Col className="image-container" md="1" xs="2">
                      <img
                        src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                        alt=""
                      />
                    </Col>
                    <Col md="8" xs="7">
                      {education.degree_name ? <Row>
                        <Col md="4">degree name:</Col><Col>{education.degree_name}</Col>
                        </Row>:''}
                      {education.degree_type ? <Row>
                        <Col md="4">degree type:</Col><Col>{education.degree_type}</Col>
                        </Row>:''}
                      {education.started_year || education.completed_year ? <Row>
                        <Col md="4">
                        Start Date - End Date
                        </Col>
                        <Col>{moment(education.started_year).format(
                        'MM-YYYY',
                      )} to {moment(education.completed_year).format(
                        'MM-YYYY',
                      )} </Col>
                        </Row>:''}
                      {education.college_name ?
                      <Row>
                        <Col md="4">schoool/college name:</Col><Col>{education.college_name}</Col>
                      </Row>:''}
                      {education.score ? <Row>
                        <Col md="4">marks:</Col><Col>{education.score}</Col>
                        </Row>:''}
                      {education.field_of_study ? <Row>
                        <Col md="4">field of study:</Col><Col>{education.field_of_study}</Col>
                        </Row>:''}

                    </Col>
                    <Col md="1" xs="1">
                      {userEduDocument(education)}
                      {/* <span onClick={()=>openDocModal(education)}>hdhd</span> */}
                <i style={{marginRight:5}} onClick={()=>openDocModal(education,"edu")} class="fa fa-eye"  aria-hidden="true"></i>
              

                    </Col>
                    <Col md="1" xs="1">
                        <OverlayTrigger
                              placement={'top'}
                              delay={{ hide: 200 }}
                              overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                          <i class="fa fa-pencil" aria-hidden="true" onClick={() => openEducationModal(education, index)}></i>
                    </OverlayTrigger>
                    </Col>
                    <Col md="1" xs="1">
                      {' '}

                      {/* <span className="icon-trash icon-new-edit" onClick={() => deleteEducation(index)} ></span> */}
                      <OverlayTrigger
                          placement={'top'}
                          delay={{ hide: 200 }}
                          overlay={<Popover className="tool-tip" id="tooltip-disabled">delete</Popover>}>
                        <i class="fa fa-trash" aria-hidden="true" onClick={() => deleteEducation(education, index)}></i>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                ))
              ) :
                <Row className='education-add'>
                  <Button className="button1" onClick={() => openEducationModal('add')} >Add Education Detail
                    {''}
                    <OverlayTrigger
                          placement={'top'}
                          delay={{ hide: 200 }}
                          overlay={<Popover className="tool-tip" id="tooltip-disabled">Add</Popover>}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    </OverlayTrigger>
                  </Button>
                </Row>
              }
            </div>

          </div>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <Col>
                <span className='education-header'>Experiance</span>
              </Col>
              <Col>
                <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Add</Popover>}>
                    
                  <i className='plus-icon' class="fa fa-plus" aria-hidden="true" onClick={() => openExperienceModal('add')}></i>
                </OverlayTrigger>
              </Col>
            </Row>
            <div className="education-details-container">
              {experienceState && experienceState.length ? (
                experienceState.map((exp, index) => (
                  <Row className="education-row">
                    <Col className="image-container" md="1" xs="2">
                      <img
                        src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                        alt=""
                      />
                    </Col>
                    <Col md="8" xs="7">
                    {exp.company_name ?
                      <Row>                      
                        <Col md="4">company name:</Col>
                        <Col>{exp.company_name}</Col>                      
                      </Row>:''}
                      {exp.experience ?
                        <Row>                      
                        <Col md="4">experience:</Col>
                        <Col>{exp.experience}</Col>                      
                      </Row>:''
                      }
                      <p>
                        <Row>
                           <Col md="4">
                             From - to
                           </Col>
                           <Col>
                              {exp.started_year && exp.started_year != null
                              ? moment(exp.started_year).format('MM-YYYY')
                              : ''}
                              {' '}
                              {exp.completed_year && exp.started_year ? ' to ' : ''}
                              {' '}
                              {exp.completed_year && exp.completed_year != null
                                ? moment(exp.completed_year).format('MM-YYYY')
                                : ''}
                           </Col>
                        </Row>                        
                      </p>
                      {exp.experience_type && exp.title ?
                        <Row>                      
                        <Col md="4">experience type-title:</Col>
                        <Col>{exp.experience_type}-{exp.title}</Col>                      
                      </Row>:
                      exp.experience_type || exp.title ?
                        <Row>                      
                          {exp.experience_type ?<><Col md="4">Experience type : </Col><Col>{exp.experience_type}</Col> </>:''}{exp.title? <><Col md="4">Experience title : </Col><Col> {exp.title}</Col></>:''}                      
                        </Row>:''
                      }
                      
                      {exp.location ?
                        <Row>                      
                        <Col md="4">Location:</Col>
                        <Col>{exp.location}</Col>                      
                      </Row>:''
                      }
                      {exp.description ?
                        <Row>                      
                        <Col md="4">description:</Col>
                        <Col>{exp.description}</Col>                      
                      </Row>:''
                      }
                     </Col>

                    <Col md="1" xs="1">
                      {userEduDocument(exp)}
                <i style={{marginRight:5}} onClick={()=>openDocModal(exp,"exp")} class="fa fa-eye"  aria-hidden="true"></i>

                    </Col>

                    <Col md="1" xs="1">
                        <OverlayTrigger
                              placement={'top'}
                              delay={{ hide: 200 }}
                              overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                          <i class="fa fa-pencil" aria-hidden="true" onClick={() => openExperienceModal(exp, index)}></i>
                      </OverlayTrigger>
                    </Col>
                    <Col md="1" xs="1">
                      {' '}
                      <OverlayTrigger
                          placement={'top'}
                          delay={{ hide: 200 }}
                          overlay={<Popover className="tool-tip" id="tooltip-disabled">delete</Popover>}>
                      <i class="fa fa-trash" aria-hidden="true" onClick={() => deleteExperience(index)}></i>
                      </OverlayTrigger>
                    </Col>

                  </Row>
                ))
              ) : (
                  <Row className='education-add'>
                    <Button className="button1" onClick={() => openExperienceModal('add')} >Add Experiance Detail
                      {''}
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </Button>
                  </Row>
                )}
            </div>

          </div>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <Col>
                <span className='education-header'>Language Proficiency</span>
              </Col>
              {
                languageEfficiencyState && languageEfficiencyState.length ? '' :
                <Col>
                  <OverlayTrigger
                              placement={'top'}
                              delay={{ hide: 200 }}
                              overlay={<Popover className="tool-tip" id="tooltip-disabled">Add</Popover>}>
                      
                    <i className='plus-icon' class="fa fa-plus" aria-hidden="true" onClick={() => openProficiencyModal('add')}></i>
                </OverlayTrigger>
              </Col>
              }
              
            </Row>
            <div className="education-details-container">
              {languageEfficiencyState && languageEfficiencyState.length ? (
                
                languageEfficiencyState.map((proficiency, index) => (
                  <Row className="education-row">
                    <Col className="image-container" md="1" xs="2">
                      <img
                        src={`${CLOUDFROND_ASSET_URL}/img/language.png`}
                        alt=""
                      />
                    </Col>
                    <Col md="8" xs="7">
                      <h3>
                        {proficiency.ielts_score ? "Overall IELTS" : proficiency.toefl_score ? "Overall TOEFL" : proficiency.pte_score ? "PTE" : null} :
                          {proficiency.ielts_score ? proficiency.ielts_score : proficiency.toefl_score ? proficiency.toefl_score : proficiency.pte_score}
                      </h3>
                      <p>
                        Listening :
                                {' '}
                        <strong>{proficiency.listening}</strong>
                                                            &ensp; Reading :
                                {' '}
                        <strong>{proficiency.reading}</strong>
                                                            &ensp; Writing :
                                {' '}
                        <strong>{proficiency.writing}</strong>
                                                            &ensp; Speaking :
                                {' '}
                        <strong>{proficiency.speaking}</strong>
                      </p>
                    </Col>
                    <Col md="1" xs="1">
                      {userEduDocument(proficiency)}
                <i style={{marginRight:5}} onClick={()=>openDocModal(proficiency,"lang")} class="fa fa-eye"  aria-hidden="true"></i>

                    </Col>
                    <Col md="1" xs="1">
                      <OverlayTrigger
                            placement={'top'}
                            delay={{ hide: 200 }}
                            overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                        <i class="fa fa-pencil" aria-hidden="true" onClick={() => openProficiencyModal(proficiency, index)}></i>
                      </OverlayTrigger>
                    </Col>
                    <Col md="1" xs="1">
                    <OverlayTrigger
                          placement={'top'}
                          delay={{ hide: 200 }}
                          overlay={<Popover className="tool-tip" id="tooltip-disabled">delete</Popover>}>
                      <i class="fa fa-trash" aria-hidden="true" onClick={() => deleteProficiency()} ></i>
                    </OverlayTrigger>
                    </Col>
                  </Row>
                ))
              ) : (
                  <Row className='education-add'>
                    <Button className="button1" onClick={() => openProficiencyModal('add')} >Add Language Proficiency Detail
              <i class="fa fa-plus" aria-hidden="true"></i>
                    </Button>
                  </Row>
                )}
            </div>
          </div>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <span className='education-header'>Statement Of Purpose</span>
            </Row>
            <Row className='education-add'>
              {sopData && sopData.length ? (
                <Row className="info-row">
                  <Col className="info-container" md="1" xs="2">
                    <img
                      src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                      alt=""
                    />
                  </Col>
                  <Col className="info-doc" md="10" lg="10" xs="10">
                    <Col className="info-doc-row">
                      {sopData && sopData.length ? sopData.map((sop, index) => (
                        <Row md="2" lg="2" className="info-des">
                          <span className="info-read" >Description {index + 1} : &nbsp;{sop.description}&nbsp;</span>
                          <span className="info-down">Document {index + 1} : &nbsp; {sopDoc(sop.SOP_doc_url)}</span>
                        </Row>
                      )) : ''}
                    </Col>
                  </Col>
                </Row>
              ) : ''}
              <Row>
                <Col className="image-container" md="1" xs="2">
                  <img
                    src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                    alt=""
                  />
                </Col>
                <Col md="6" xs="10" className='description'>
                  <h5>Description</h5>
                  <Form.Control
                    className="description-textarea"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    name="sopValue"
                    value={sopValue}
                    as="textarea"
                    placeholder="Name and Details.."
                  />
                </Col>

              </Row>

              <Row>
                <Col md="2" xs="2" />
                <Col md="6" xs="6" style={{}}>
                  <Form.File
                    onChange={(e) => addNewDocument(e, 'sop')}
                    style={{ marginTop: 15 }}
                    multiple
                    id="exampleFormControlFile1"
                    accept=".png, .jpg, .jpeg, .pdf"
                    name="documentFile"
                  />
                </Col>
                <Col className="text-right" md="3" xs="5">
                  <a>
                    <Button
                      style={{ marginTop: 9, fontSize: 13 }}
                      className="button1"
                      disabled={!sopValue}
                      onClick={() => onSaveDocument(11)}
                    >
                      Save
                      </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <span className='education-header'>Passport</span>
            </Row>
            <Row className='education-add'>
              {passportData ?
                <Row className="info-row">
                  <Col className="info-container" md="1" xs="2">
                    <img
                      src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                      alt=""
                    />
                  </Col>
                  <Col className="info-doc" md="10" xs="10">
                    <Row className="info-doc-row">
                      <Col className="info-des">
                        <div className="info-read" >&nbsp;{passportDocDescription}&nbsp;</div>
                        <div className="info-down">Document {1} : &nbsp;{sopDoc(passportData)}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                : ''}
              <Row>
                <Col className="image-container" md="1" xs="2">
                  <img
                    src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                    alt=""
                  />
                </Col>
                <Col md="6" xs="10" className='description'>
                  <h5>Description</h5>
                  <Form.Control
                    className="description-textarea"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    name="passportValue"
                    value={passportValue}
                    as="textarea"
                    placeholder="Name and Details.."
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2" xs="2" />
                <Col md="6" xs="6" style={{}}>
                  <Form.File
                    onChange={(e) => addNewDocument(e, 'passport')}
                    style={{ marginTop: 15 }}
                    multiple
                    id="exampleFormControlFile1"
                    accept=".png, .jpg, .jpeg, .pdf"
                    name="documentFile"
                  />
                </Col>
                <Col className="text-right" md="3" xs="5">
                  <a>
                    <Button
                      style={{ marginTop: 9, fontSize: 13 }}
                      className="button1"
                      disabled={!passportValue}
                      onClick={() => onSaveDocument(12)}
                    >
                      Save
                      </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <span className='education-header'>Letter Of Recommendation</span>
            </Row>
            <Row className='education-add'>
              {lorData && lorData.length ?
                <Row className="info-row">
                  <Col className="info-container" md="1" xs="2">
                    <img
                      src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                      alt=""
                    />
                  </Col>
                  <Col className="info-doc" md="10" xs="10">
                    <Col className="info-doc-row">
                      {lorData && lorData.length ? lorData.map((lor, index) => (
                        <Row md="3" lg="3" className="info-des">
                          <span className="info-read" >Description {index + 1} :&nbsp;{lor.description}&nbsp;</span>
                          <span className="info-down">Document {index + 1} :&nbsp;{sopDoc(lor.doc_url)}</span>
                        </Row>
                      )) : ''}
                    </Col>
                  </Col>
                </Row>
                : ''}
              <Row>
                <Col className="image-container" md="1" xs="2">
                  <img
                    src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                    alt=""
                  />
                </Col>
                <Col md="6" xs="10" className='description'>
                  <h5>Description</h5>
                  <Form.Control
                    className="description-textarea"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    name="lorValue"
                    value={lorValue}
                    as="textarea"
                    placeholder="Name and Details.."
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2" xs="2" />
                <Col md="6" xs="6" style={{}}>
                  <Form.File
                    onChange={(e) => addNewDocument(e, 'lor')}
                    style={{ marginTop: 15 }}
                    multiple
                    id="exampleFormControlFile1"
                    accept=".png, .jpg, .jpeg, .pdf"
                    name="documentFile"
                  />
                </Col>
                <Col className="text-right" md="3" xs="5">
                  <a>
                    <Button
                      style={{ marginTop: 9, fontSize: 13 }}
                      className="button1"
                      disabled={!lorValue}
                      onClick={() => onSaveDocument(13)}
                    >
                      Save
                      </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <span className='education-header'>Visa Refusal</span>
            </Row>
            <Row className='education-add'>
              {
                visaData ?

                  <Row className="info-row">
                    <Col className="info-container" md="1" xs="2">
                      <img
                        src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                        alt=""
                      />
                    </Col>
                    <Col className="info-doc" md="10" xs="10">
                      <Row className="info-doc-row">
                        <Col className="info-des">
                          <span className="info-read" >&nbsp;{visaDocDescription}&nbsp;</span>
                          <span className="info-down">Document {1} :&nbsp;{sopDoc(visaData)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  : ''}
              <Row>
                <Col className="image-container" md="1" xs="2">
                  <img
                    src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                    alt=""
                  />
                </Col>
                <Col md="6" xs="10" className='description'>
                  <h5>Description</h5>
                  <Form.Control
                    className="description-textarea"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    name="visaValue"
                    value={visaValue}
                    as="textarea"
                    placeholder="Name and Details.."
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2" xs="2" />
                <Col md="6" xs="6" style={{}}>
                  <Form.File
                    onChange={(e) => addNewDocument(e, 'visarefusal')}
                    style={{ marginTop: 15 }}
                    multiple
                    id="exampleFormControlFile1"
                    accept=".png, .jpg, .jpeg, .pdf"
                    name="documentFile"
                  />
                </Col>
                <Col className="text-right" md="3" xs="5">
                  <a>
                    <Button
                      style={{ marginTop: 9, fontSize: 13 }}
                      className="button1"
                      disabled={!visaValue}
                      onClick={() => onSaveDocument(14)}
                    >
                      Save
                      </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <span className='education-header'>Other Documents</span>
            </Row>
            <Row className='education-add'>
              {otherDocData ?
                <Row className="info-row">
                  <Col className="info-container" md="1" xs="2">
                    <img
                      src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                      alt=""
                    />
                  </Col>
                  <Col className="info-doc" md="10" xs="10">
                    <Row className="info-doc-row">
                      <Col className="info-des">
                      {otherDocData && otherDocData.length ? otherDocData.map((otherData, index) => (
                        <Row md="2" lg="2" className="info-des">
                          <span className="info-read" >Description {index + 1} : &nbsp;{otherData.description}&nbsp;</span>
                          <span className="info-down">Document {index + 1} : &nbsp; {sopDoc(otherData.other_documents_url)}</span>
                        </Row>
                      )) : ''}
        
                      </Col>
                    </Row>
                  </Col>
                </Row>
                : ''}
              <Row>
                <Col className="image-container" md="1" xs="2">
                  <img
                    src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                    alt=""
                  />
                </Col>
                <Col md="6" xs="10" className='description'>
                  <h5>Description</h5>
                  <Form.Control
                    className="description-textarea"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    name="otherdocValue"
                    value={otherdocValue}
                    as="textarea"
                    placeholder="Name and Details.."
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2" xs="2" />
                <Col md="6" xs="6" style={{}}>
                  <Form.File
                    onChange={(e) => addNewDocument(e, 'otherdocuments')}
                    style={{ marginTop: 15 }}
                    multiple
                    id="exampleFormControlFile1"
                    accept=".png, .jpg, .jpeg, .pdf"
                    name="documentFile"
                  />
                </Col>
                <Col className="text-right" md="3" xs="5">
                  <a>
                    <Button
                      style={{ marginTop: 9, fontSize: 13 }}
                      className="button1"
                      disabled={!otherdocValue}
                      onClick={() => onSaveDocument(15)}
                    >
                      Save
                      </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
        <div className='education-row'>
          <div className='education'>
            <Row>
              <span className='education-header'>CV</span>
            </Row>
            <Row className='education-add'>
              {
                cvDoc ?

                  <Row className="info-row">
                    <Col className="info-container" md="1" xs="2">
                      <img
                        src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                        alt=""
                      />
                    </Col>
                    <Col className="info-doc" md="10" xs="10">
                      <Row className="info-doc-row">
                        <Col className="info-des">
                          <span className="info-read" >&nbsp;{cvDocDescription}&nbsp;</span>
                          <span className="info-down">Document {1} :&nbsp;{sopDoc(cvDoc)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  : ''}
              <Row>
                <Col className="image-container" md="1" xs="2">
                  <img
                    src={`${CLOUDFROND_ASSET_URL}/img/building.png`}
                    alt=""
                  />
                </Col>
                <Col md="6" xs="10" className='description'>
                  <h5>Description</h5>
                  <Form.Control
                    className="description-textarea"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    name="cvValue"
                    value={cvValue}
                    as="textarea"
                    placeholder="Name and Details.."
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2" xs="2" />
                <Col md="6" xs="6" style={{}}>
                  <Form.File
                    onChange={(e) => addNewDocument(e, 'cvDoc')}
                    style={{ marginTop: 15 }}
                    multiple
                    id="exampleFormControlFile1"
                    accept=".png, .jpg, .jpeg, .pdf"
                    name="documentFile"
                  />
                </Col>
                <Col className="text-right" md="3" xs="5">
                  <a>
                    <Button
                      style={{ marginTop: 9, fontSize: 13 }}
                      className="button1"
                      disabled={!cvValue}
                      onClick={() => onSaveDocument(14)}
                    >
                      Save
                      </Button>
                  </a>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
      </FormGroup>
    </>
    }
</div>
  )
}

export default StudentEdit