import constants from './constants';

const INITITAL_STATE = {
    isLoading: false,
    error: '',
    isUploadFetching: false,
    saasData: null,
    courseList: [],
    pagination: {},
    univerityCreate: {}
}

export default (state = INITITAL_STATE, action) => {
    switch (action.type) {
        case constants.SAVE_LOADING:
            return { ...state, isLoading: true };
        case constants.SAVE_SUCCESS:
            return { ...state, isLoading: false, error: '' };
        case constants.SAVE_ERROR:
            return { ...state, isLoading: false, error: action.payload };

        case constants.GET_ALL_COURSES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case constants.GET_ALL_COURSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                courseList: action.payload,
                pagination: action.payload.pagination
            };
        case constants.GET_ALL_COURSES_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case constants.SAAS_UPLOAD_REQUEST:
            return {
                ...state,
                isUploadFetching: true,
                isLoading: true
            };
        case constants.SAAS_UPLOAD_REQUEST_SUCCESS:
            return {
                ...state,
                isUploadFetching: false,
                saasData: action.payload
            };
        case constants.SAAS_UPLOAD_REQUEST_FAIL:
            return {
                ...state,
                isUploadFetching: false,
                error: action.error
            };
        case constants.DELETE_COURSE_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case constants.DELETE_COURSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case constants.DELETE_COURSE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case constants.POST_CREATE_UNIVERSITY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case constants.POST_CREATE_UNIVERSITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                univerityCreate: action.payload,
            };
        case constants.POST_CREATE_UNIVERSITY_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};