import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import actions from '../../modules/actions'
import { Toast } from '../../../../shared'
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import './ConfirmingInterests.scss';

class ConfirmingInterests extends Component {
    constructor(props) {
        super(props)
        this.state = {
            universityName: '',
            courseName: '',
            year: '',
            month: '',
            degree: '',
            id: '',
            errors: {},
            multiple: [1],
            studentIndex: 0,
            selected: []
        }
    }
    componentDidMount() {
        const { match: { params }, userProfile, student } = this.props;
        this.props.actions.getStudent(params.id);
        this.props.actions.getStudentAppliedCourses(params.id)
    }

    static getDerivedStateFromProps(props, state) {

    }

    isvalid = () => {
        const { universityName, courseName } = this.state;
        let errors = {};

        if (universityName == '') {
            errors.universityName = true;
            this.setState({ errors });
            return false;
        }
        if (courseName == '') {
            errors.courseName = true;
            this.setState({ errors });
            return false;
        }


        return true;
    };
    onAdd = () => {
        const { multiple } = this.state
        multiple.push(1)
        this.setState({
            multiple
        })
    }
    onSubmit = () => {
        const { match: { params }, userProfile, onCloseModal, student, studentCourses } = this.props;
        const { universityName, courseName, multiple, studentIndex, selected } = this.state;

        let data = []
        let zadata = []
        for (let i = 0; i <= selected.length - 1; i++) {

            let opportunityData = {}
            opportunityData = {
                "option_course_name": selected[i].applied_courses,
                "option_course_description": selected[i].university_name,
                "selected_course": null,
                "type": 'final_decision',
                "selected_offer": 0,
                "student_id": params.id,
                "user_id": userProfile.organization.user_id
            }
            data.push(opportunityData)

            let opportunityZAData = {}
            console.log(student, student.za_id)
            opportunityZAData = {
                "option_course_name": selected[i].applied_courses,
                "option_course_description": selected[i].university_name,
                "selected_course": null,
                "type": 'final_decision',
                "selected_offer": 0,
                'user_id': student ? student.za_id : null,
            }
            zadata.push(opportunityZAData)

        }
        const taskList = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'meeting_schedule': false,
            'attending_meeting': false,
            'required_documents': false,
            'visa_appointment': false,
            'opportunity': false,
            'select_offer': true,
            'qualifying': false,
        }
        const ZAtaskList = {
            'user_id': student ? student.za_id : null,
            'meeting_schedule': false,
            'attending_meeting': false,
            'required_documents': false,
            'visa_appointment': false,
            'opportunity': false,
            'select_offer': true,
            'qualifying': false,

        }
        const handlesuccessfull = () => {
            Toast.show({
                message: "Making final decision task allocation send successfully!",
                timeout: 4000,
                intent: 'success'
            })
            this.setState({
                universityName: '',
                courseName: '',
                year: '',
                month: '',
                degree: '',
                id: '',
                errors: {},
                multiple: [1],
                studentIndex: 0,
                selected: []

            })
            onCloseModal()

        }
        const handleFailure = () => {
            Toast.show({
                message: "Making final decision task allocation send unsuccessfull!",
                timeout: 4000,
                intent: 'danger'
            })
            this.setState({
                universityName: '',
                courseName: '',
                year: '',
                month: '',
                degree: '',
                id: '',
                errors: {},
                multiple: [1],
                studentIndex: 0,
                selected: []

            })
            onCloseModal()

        }

        this.props.actions.taskAllocation(taskList, () => { }, () => { })
        this.props.actions.counsellorOpportunity(data, handlesuccessfull, handleFailure)
        if (ZAtaskList.user_id) {
            this.props.actions.ZAcounsellorOpportunity(zadata, handlesuccessfull, handleFailure)
            this.props.actions.ZAtaskAllocation(ZAtaskList, () => { }, () => { })
        }


    }
    onChangeInput = (name) => {
        const { selected } = this.state
        selected.push(name)
        this.setState({
            selected
        })

    }
    render() {
        const { universityName, errors, courseName, multiple } = this.state;
        const { studentCourses } = this.props
        return (
            <div className="balance-fee">
                { studentCourses && studentCourses.length > 0 ?
                    <>
                        { studentCourses.map((input, index) => {
                            return (<div>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        onChange={(event) => {
                                            this.onChangeInput(input)
                                        }}
                                        label={`${input.applied_courses}, ${input.course_level} ${input.application_intake_month} ${input.application_intake_year} ${input.university_name}`}
                                    />
                                </Form.Group>
                            </div>)
                        })
                        }
                        <Col className="button-save">
                            <Button className="save-offer" onClick={() => this.onSubmit()}>Allocate</Button>
                        </Col>
                    </>
                    : <div>
                        <h5>Please apply for course</h5>
                    </div>}

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        student: state.students.student,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmingInterests));
