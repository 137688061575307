import auth from '../screens/Auth';
import dashboard from '../screens/Dashboard';
import createuser from '../screens/CreateUser';
import interview from '../screens/Interview';
import integration from '../screens/Integration';
import students from "../screens/Students";
import course from "../screens/Course";
import profile from "../screens/Profile";
import wizard from "../screens/Wizards"
import studentInterview from '../screens/StudentInterview'
import features from '../screens/FeatureSelection'
import userrole from '../screens/UserRole';
import members from '../screens/Members';
import source from '../screens/Source';
import channel from '../screens/Channel'
import reports from '../screens/Report'
import marketing from '../screens/Marketing'
import cohort from '../screens/Cohort'
import activity from '../screens/activity'
import reportExport from '../screens/ReportExport'

const routes = [
    ...auth,
    ...dashboard,
    ...createuser,
    ...integration,
    ...interview,
    ...students,
    ...course,
    ...profile,
    ...wizard,
    ...studentInterview,
    ...features,
    ...userrole,
    ...members,
    ...source,
    ...channel,
    ...reports,
    ...marketing,
    ...activity,
    ...reportExport,
    ...cohort
];

export default routes;
