import React from 'react';
import { Container } from 'react-bootstrap';
import './DesignMarkup.scss';
import { Row, Col } from 'react-bootstrap';
import zilter from '../../../assets/zilterWhiteLogo2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import images from '../../../img/clip.png'
const DesignMarkup = (props) => {
    const { push, location, userAccessData, wizardMode, onShow, showSubmenu, showReportSubmenu, onReportShow, dashboardAccess, reportAccess } = props;
    console.log("userAccessDat///////////", userAccessData)
    return (

        <div className="new-design" >
            <Row>
                <Col className="header"><img src={zilter} /></Col>
            </Row>
            <Row>

                {wizardMode ? <img className="left-side-image-container" src={images} /> :
                    <ul>
                        { dashboardAccess ? <li onClick={() => { push.push('/dashboard'); onShow() }} className={"deactivated"}>  <FontAwesomeIcon className={location == "/dashboard" ? "left-side-icon drop channel" : "left-side-icon drop channel deactivate"} icon={showSubmenu ? ['fas', 'chevron-down'] : ['fas', 'chevron-right']} /><FontAwesomeIcon className={location == "/dashboard" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'home']} /><a  >Dashboard</a></li>: null}
                        {showSubmenu ?
                            <>
                                <li onClick={() => push.push('/dashboard')} className={location == "/dashboard" ? "activated submenu" : "deactivated submenu"}> <FontAwesomeIcon className={location == "/dashboard" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'cubes']} /><a  >Matrix</a></li>
                                <li onClick={() => push.push('/reports')} className={location == "/reports" ? "activated submenu" : "deactivated submenu"}> <FontAwesomeIcon className={location == "/reports" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'chart-bar']} /><a  >CRM</a></li>
                                <li onClick={() => push.push('/marketing')} className={location == "/marketing" ? "activated submenu" : "deactivated submenu"}> <FontAwesomeIcon className={location == "/marketing" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'chart-line']} /><a  >Marketing</a></li>
                                <li onClick={() => push.push('/cohort')} className={location == "/cohort" ? "activated submenu" : "deactivated submenu"}> <FontAwesomeIcon className={location == "/Cohort" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'chart-line']} /><a  >Cohort Analysis</a></li>
                            </> :
                            null}
                       { reportAccess ? <li onClick={() => { push.push('/customerreports'); onReportShow() }} className={"deactivated"}>  <FontAwesomeIcon className={location == "/customerreports" ? "left-side-icon drop channel" : "left-side-icon drop channel deactivate"} icon={showReportSubmenu ? ['fas', 'chevron-down'] : ['fas', 'chevron-right']} /><FontAwesomeIcon className={location == "/customerreports" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'file']} /><a  >Report</a></li>: null}
                        {showReportSubmenu ? <><li onClick={() => push.push('/customerreports')} className={location == "/customerreports" ? "activated submenu" : "deactivated submenu"}> <FontAwesomeIcon className={location == "/customerreports" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'cubes']} /><a  >Customer</a></li>
                            <li onClick={() => push.push('/callingreports')} className={location == "/callingreports" ? "activated submenu" : "deactivated submenu"}> <FontAwesomeIcon className={location == "/callingreports" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'chart-bar']} /><a  >Calling</a></li>
                        </> : null}
                        <li onClick={() => push.push('/integration')} className={location == "/integration" ? "activated" : "deactivated"}><FontAwesomeIcon className={location == "/integration" ? "left-side-icon" : "left-side-icon deactivate"} icon={['fas', 'link']} /><a >Integration</a></li>
                        <li onClick={() => push.push('/interviews')} className={location == "/interviews" ? "activated" : "deactivated"}><FontAwesomeIcon className={location == "/interviews" ? "left-side-icon interview" : "left-side-icon interview deactivate"} icon={['fas', 'video']} /><a >Interviews</a></li>
                        <li onClick={() => push.push('/questions')} className={location == "/questions" ? "activated" : "deactivated"}><FontAwesomeIcon className={location == "/questions" ? "left-side-icon questions" : "left-side-icon questions deactivate"} icon={['fas', 'question-circle']} /><a >Questions</a></li>
                        <li onClick={() => push.push('/course')} className={location == "/course" ? "activated" : "deactivated"} ><FontAwesomeIcon className={location == "/course" ? "left-side-icon course" : "left-side-icon course deactivate"} icon={['fas', 'book']} /><a >Course</a></li>
                        <li onClick={() => push.push('/students')} className={location == "/students" ? "activated" : "deactivated"}><FontAwesomeIcon className={location == "/students" ? "left-side-icon students" : "left-side-icon students deactivate"} icon={['fas', 'user-graduate']} /><a >Students</a></li>
                        {userAccessData ? <><li onClick={() => push.push('/members')} className={location == "/members" ? "activated" : "deactivated"}><FontAwesomeIcon className={location == "/members" ? "left-side-icon members" : "left-side-icon members deactivate"} icon={['fas', 'users']} /><a >Members</a></li></> : null}
                        {userAccessData ? <> <li onClick={() => push.push('/source')} className={location == "/source" ? "activated" : "deactivated"}><FontAwesomeIcon className={location == "/source" ? "left-side-icon source" : "left-side-icon source deactivate"} icon={['fas', 'address-card']} /><a  >Source</a></li></> : null}
                        {userAccessData ? <> <li onClick={() => push.push('/channel')} className={location == "/channel" ? "activated" : "deactivated"}><FontAwesomeIcon className={location == "/channel" ? "left-side-icon channel" : "left-side-icon channel deactivate"} icon={['fas', 'address-book']} /><a  >Channel</a></li></> : null}
                        <li onClick={() => push.push('/activity/event')} className={location == "/activity/event" ? "activated" : "deactivated"}><FontAwesomeIcon className={location == "/activity/event" ? "left-side-icon" : "left-side-icon deactivate"} icon={['fas', 'link']} /><a >Activity</a></li>



                    </ul>}

            </Row>
        </div>

    )
}

export default DesignMarkup;