import './ExperienceUpdate.scss';
import React, { Component } from 'react';
import {
  Row, Col, Button, Container, Form, Table,
} from 'react-bootstrap';

import VirtualizedSelect from 'react-virtualized-select'
import moment from 'moment';
import yearsjson from '../../modules/years.json';
import monthsjson from '../../modules/months.json';
const years = yearsjson ? yearsjson : [];
const months = monthsjson ? monthsjson : [];

class ExperienceUpdate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      errors,
      expStartMonth,
      expEndMonth,
      experienceDetilsState,
      userDetails,
      redirectTo,
      addexperienceFile,
      startDate,
      endDate,
      descriptionExp,
      deleteExperience,
      onExperienceUpdate,
      onChangeInput,
      titleExp,
      companyExp,
      locationExp,
      experienceEdit,
      typeExp,
    } = this.props;
    console.log("errors",errors)
    return (
      <Container
        className={
                    this.props.wizardFlow == 'wizard'
                      ? 'experience-update-container wizard'
                      : 'experience-update-container'
                }
      >
        {this.props.wizardFlow == 'wizard' ? (
          <div className="upper-arrow-wizard three" />
        ) : null}
        {this.props.wizardFlow == 'wizard' ? (
          <div>
            <p>Added Proficiency</p>

            <Table striped bordered>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Title</th>
                  <th>Duration</th>
                  <th>Type</th>
                  <th>Location</th>
                  <th>dDescription</th>
                </tr>
              </thead>
              <tbody>
                {this.props.wizardFlow == 'wizard'
                                && experienceDetilsState
                                && experienceDetilsState.length
                  ? experienceDetilsState.map((exp) => (
                    <tr>
                      <td>
                        {exp.company}
                        {' '}
                      </td>
                      <td>{exp.title}</td>
                      <td key={exp.type}>
                        {moment(exp.start).format('DD-MM-YYYY')}
                        {' '}
                        -
                        {' '}
                        {moment(exp.end).format('DD-MM-YYYY')}
                      </td>
                      <td>{exp.type}</td>
                      <td>{exp.location}</td>
                      <td>{exp.description}</td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </Table>
          </div>
        ) : null}
      
          <div className="wizard-button-container">
            {' '}
            <Button className="prv-button-wizard" onClick={this.props.previousStep}>
              Previous
            </Button>
            {' '}
            <Button
              className="next-button-wizard"
              onClick={() => redirectTo.push(`/profile/${userDetails.id}`)}
            >
              Finish
            </Button>
            {' '}
          </div>
        <Form>
          <Form.Group>
          <Form.Label>Job Title *</Form.Label>
            <Form.Control
              value={titleExp}
              name="titleExp"
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              className="form-input-field"
              placeholder="Title of the Job"
            />
            {errors && errors.titleErr ? <span className="error">Please Enter title of the Job</span> : ''}
          </Form.Group>
          <Form.Group>
          <Form.Label>Job Type *</Form.Label>
            <Form.Control
              value={typeExp}
              //disabled={!titleExp}
              name="typeExp"
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              className="form-input-field"
              placeholder="Type of Job"
            />
            {errors && errors.typeErr ? <span className="error">Please Enter type of the Job</span> : ''}
          </Form.Group>

          <Form.Group>
          <Form.Label>Company Name *</Form.Label>
            <Form.Control
              value={companyExp}
              //disabled={!typeExp}
              name="companyExp"
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              className="form-input-field"
              placeholder="Name of the Company"
            />
            {errors && errors.companyErr ? (
              <span className="error">Pleasse Enter Name of the Company</span>
            ) : (
              ''
            )}
          </Form.Group>
          {/* <Form.Group >
                        <Form.Control value={startExp} name="startExp" onChange={(event) => onChangeInput(event.target.name, event.target.value)} className="form-input-field" placeholder="Start" />
                    </Form.Group>  */}
          {/* <Row>
                        <Col>
                            <div>
                                <Form.Label>Start date</Form.Label>
                                <DatePicker className="date-picker"
                                    onChange={onChangeDate}
                                    value={startDate}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <Form.Label>End date &nbsp;</Form.Label>
                                <DatePicker
                                    className="date-picker"
                                    onChange={onChangeEndDate}
                                    value={endDate}

                                />
                            </div>
                        </Col>
                    </Row> */}
          <Row style={{ marginBottom: 20 }}>
            <Col md="12" lg="12" sm="12" xs="12">
              <Form.Label>Start Date *</Form.Label>
              <Row>
                <Col md="6" lg="6" sm="6" xs="6">
                    <VirtualizedSelect
                        options={months}
                        //disabled={!companyExp}
                        onChange={(selectedOption) => onChangeInput('expStartMonth',  selectedOption ? selectedOption.value:'')}
                        value={expStartMonth}
                        placeholder={"Select Month"}
                    />

                  {/* <Form.Control
                    name="expStartMonth"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    as="select"
                    value={expStartMonth}
                    placeholder="Start Year"
                  >
                    <option value="00">Month</option>
                    <option value="01">Jan</option>
                    <option value="02">Feb</option>
                    <option value="03">Mar</option>
                    <option value="04">Apr</option>
                    <option value="05">May</option>
                    <option value="06">Jun</option>
                    <option value="07">Jul</option>
                    <option value="08">Aug</option>
                    <option value="09">Sept</option>
                    <option value="10">Oct</option>
                    <option value="11">Nov</option>
                    <option value="12">Dec</option>
                  </Form.Control> */}
                </Col>
                <Col md="6" lg="6" sm="6" xs="6">
                    <VirtualizedSelect
                        options={years}
                        //disabled={!expStartMonth}
                        onChange={(selectedOption) => onChangeInput('startDate',  selectedOption ? selectedOption.value:'')}
                        value={startDate}
                        placeholder={"Select Year"}
                    />
                </Col>
              </Row>
            </Col>
            <Col md="12" lg="12" sm="12" xs="12">
              <Form.Label>End Date * &nbsp;</Form.Label>
              <Row>
                <Col md="6" lg="6" sm="6" xs="6">
                    <VirtualizedSelect
                        options={months}
                        //disabled={!startDate}
                        onChange={(selectedOption) => onChangeInput('expEndMonth',  selectedOption ? selectedOption.value:'')}
                        value={expEndMonth}
                        placeholder={"Select Month"}
                    />

                  {/* <Form.Control
                    name="expEndMonth"
                    as="select"
                    onChange={(event) => onChangeInput(event.target.name, event.target.value)}
                    value={expEndMonth}
                    placeholder="Start Year"
                  >
                    <option value="00">Month</option>

                    <option value="01">Jan</option>
                    <option value="02">Feb</option>
                    <option value="03">Mar</option>
                    <option value="04">Apr</option>
                    <option value="05">May</option>
                    <option value="06">Jun</option>
                    <option value="07">Jul</option>
                    <option value="08">Aug</option>
                    <option value="09">Sept</option>
                    <option value="10">Oct</option>
                    <option value="11">Nov</option>
                    <option value="12">Dec</option>
                  </Form.Control> */}
                </Col>
                <Col md="6" lg="6" sm="6" xs="6">
                    <VirtualizedSelect
                        options={years}
                        //disabled={!expEndMonth}
                        onChange={(selectedOption) => onChangeInput('endDate', selectedOption ? selectedOption.value:'')}
                        value={endDate}
                        placeholder={"Select Year"}
                    />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {errors && errors.expDateError ? <span className="error">End Date should not comes before Start date</span>:''}
            {errors && errors.blankError ?<span className="error">Date should not be empty or same</span> : ''}
            {errors && errors.expendError ?<span className="error">Please insert End Date</span> : ''}
            {errors && errors.startDateError ?<span className="error">Please insert Start Date</span> : ''}
          </Row>
          <Form.Group>
          <Form.Label>Location *</Form.Label>
            <Form.Control
              value={locationExp}
              name="locationExp"
              //disabled={!endDate}
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              className="form-input-field"
              placeholder="Location"
            />
            {errors && errors.locationErr ? (
              <span className="error">Please Enter valid Location details</span>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group>
          <Form.Label>Description</Form.Label>
            <Form.Control
              value={descriptionExp}
              name="descriptionExp"
              //disabled={!locationExp}
              onChange={(event) => onChangeInput(event.target.name, event.target.value)}
              className="form-textarea-field"
              as="textarea"
              row="3"
              placeholder="Description"
            />
            {errors && errors.descriptionErr ? (
              <span className="error">Please Enter valid details</span>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group>
            <Form.File
              id="exampleFormControlFile1"
              label="Upload Document"
              val
              accept=".png, .jpg, .jpeg, .pdf"
              name="documentFile"
              onChange={addexperienceFile}
            />
          </Form.Group>

          <Row>
            {/* <Col>
              {' '}
              <Button className="button-delete" onClick={() => deleteExperience()}>
                Delete
              </Button>
            </Col> */}
            <Col>
              <Button
                className="button-delete save"
                // disabled={!locationExp}
                onClick={ () => onExperienceUpdate() }
              >
               {experienceEdit ? 'Update' : 'Save' }
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}
export default ExperienceUpdate;
