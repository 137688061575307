import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Tag } from "@blueprintjs/core";
import Modal from '../../../../shared/model/model'
import ApplicationCreate from './ApplicationCreate'
import ConditionalOffer from './ApplicationStatus/ConditionalOffer'
import UniversityAssessment from './ApplicationStatus/UniversityAssessment'
import UnconditionalOffer from './ApplicationStatus/UnconditionalOffer'
import Feedeposit from './ApplicationStatus/FeeDeposit'
import VisaAppointment from './ApplicationStatus/VisaAppointment'
import VisaApplied from './ApplicationStatus/VisaApplied'
import VisaGranted from './ApplicationStatus/VisaGranted'
import BalanceFeePayment from './ApplicationStatus/BalanceFeePayment'
import Enrolled from './ApplicationStatus/Enrolled'
import Invoice from './ApplicationStatus/Invoice'
import Lost from './ApplicationStatus/Lost'
import VisaRefused from './ApplicationStatus/VisaRefused'
import CasReceived from './ApplicationStatus/CasReceived'
import CasApplied from './ApplicationStatus/CasApplied'
import NoDetails from './ApplicationStatus/NoDetails'
import moment from 'moment';
import ApplicationRejected from './ApplicationStatus/ApplicationRejected'

import './Applications.scss';

const Application = (props) => {
    const { student, courses, myState, onChangetext, onStatus, applicationStatus, applicationValue, applyZoomabroad,
        showApplicationCreateModal, onCloseModal, onApplyCourse, appicationCourseCreate, onChangeInput, onClearAppModal, onApplyCreateCourse,
        zaCoursesData, zaUniversityData, channelData, channelList, applicationCourseType,onEditApplicationcoursename,applicationType,
        applicationCourseUniversity, applicationChannelName, applicationIntakeYear, applicationIntakeMonth,
        showApplicationStatusModal, onApplicationStatusModal, applicationStatusIndex, applicationModalName,showApplicationcourseModal, applicationStatusId,
        clearModel, setClearForm, applicationNumber, openApplicationEditModal, showApplicationNumberModal, onApplicationEditModal,openApplicationIntakeEditModal,
        onIntakeEditModal,application_intake_month,onCourseEditModal,applied_courses,showApplicationTypeModal,openApplicationTypeEditModal,onApplicationTypeEditModal,application_type,openApplicationCourseEditModal,application_intake_year,showApplicationIntakeModal,onChangeApplication, userProfile,studentAppicationIntake } = props;
    console.log("props",props)
    let applied = null;
    const options = [
        {
            "value": "",
            "label": "Select"
        },
        {
            "value": "2028",
            "label": "2028"
        },
        {
            "value": "2027",
            "label": "2027"
        },
        {
            "value": "2026",
            "label": "2026"
        },
        {
            "value": "2025",
            "label": "2025"
        },
        {
            "value": "2024",
            "label": "2024"
        },
        {
            "value": "2023",
            "label": "2023"
        },
        {
            "value": "2022",
            "label": "2022"
        },
        {
            "value": "2021",
            "label": "2021"
        },
        {
           "value": "2020",
           "label": "2020"
        },
        {
            "value": "2019",
            "label": "2019"
        },
        {
            "value": "2018",
            "label": "2018"
        },
        {
            "value": "2017",
            "label": "2017"
        },
        {
            "value": "2016",
            "label": "2016"
          }
    ]
    const months = [
        {
            "value": "",
            "label": "Select"
        },
        {
            "value": "January",
            "label": "January"
        },
        {
            "value": "February",
            "label": "February"
        },
        {
            "value": "March",
            "label": "March"
        },
        {
            "value": "April",
            "label": "April"
        },
        {
            "value": "May",
            "label": "May"
        },
        {
            "value": "June",
            "label": "June"
        },
        {
            "value": "July",
            "label": "July"
        },
        {
            "value": "August",
            "label": "August"
        },
        {
           "value": "September",
           "label": "September"
        },
        {
            "value": "October",
            "label": "October"
        },
        {
            "value": "November",
            "label": "November"
        },
        {
            "value": "December",
            "label": "December"
        }
    ]

    const applicationTypeData = [
        { "label": "Unique", "value": "1" },
        { "label": "Non-Unique", "value": "0" },
    ]

    const channelName = (channel_id) => {
        if (channelList && channelList) {
            for (let i = 0; i < channelList.length; i++) {
                if (channelList[i].id == channel_id) {
                    return channelList[i].channel_name
                }
            }
        }
    }
    const onViewDetails = () => {

        switch (applicationStatusIndex) {
            case 0:
                return <ConditionalOffer
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}
                />
            case 1:
                return <UniversityAssessment
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}
                />
            case 2:
                return <UnconditionalOffer
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />
            case 3:
                return <Feedeposit
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />
            case 4:
                return <VisaAppointment
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />
            case 5:
                return <VisaApplied
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}
                />
            case 6:
                return <VisaGranted
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}
                />
            case 7:
                return <VisaRefused
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />
            case 8:
                return <BalanceFeePayment
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}
                />
            case 9:
                return <Enrolled
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />
            case 10:
                return <Invoice
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />
            case 11:
                return <Lost
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />
            case 12:
                return <CasReceived
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />
            case 13:
                return <CasApplied
                    clearModel={clearModel}
                    setClearForm={setClearForm}
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}
                />
            case 14: 
                return<ApplicationRejected
                clearModel={clearModel}
                setClearForm={setClearForm}
                onCloseModal={onCloseModal}
                applicationStatusId={applicationStatusId}
                />
            case 15:
                return <NoDetails
                    onCloseModal={onCloseModal}
                    applicationStatusId={applicationStatusId}

                />

            default:
                console.log("i is greater than 2.");
        }

    }
    const handleApplicationName = (channel_id) => {
        if (applicationStatus && applicationStatus && applicationStatus.length) {
            for (let i = 0; i < applicationStatus.length; i++) {
                if (applicationStatus[i].id == channel_id) {
                    return applicationStatus[i].key
                }
            }
        }
    }

    if (courses && courses.length) {
        applied = courses.map(acad => {
            if(acad.course_level != 'selected offer'){
            if (acad.application_status) {
                if (myState[`applicationValue${acad.application_status_id}`] == undefined) {
                    myState[`applicationValue${acad.application_status_id}`] = acad.application_status_id
                }
            }
            let appliedUniversityid = '';
            let x = zaUniversityData ?  zaUniversityData.map(university =>{
                if(university.label == acad.university_name) return appliedUniversityid = university.value
            })  : null
            return (<div className="applied">
                <Modal style={{ zIndex: '1' }}
                    modalTitle={applicationModalName}
                    isModalOpen={showApplicationStatusModal}
                    onCloseModal={onCloseModal}
                    className="application-status-modal"

                >
                    {onViewDetails()}
                </Modal>
                <Modal style={{ zIndex: '1' }}
                    modalTitle={"Application Number"}
                    isModalOpen={showApplicationNumberModal}
                    onCloseModal={onCloseModal}

                >
                    <>
                        <Form.Control
                            placeholder="Application number"
                            type="text"
                            className="form-control text-box"
                            value={applicationNumber}
                            onChange={(event) => onChangeApplication('applicationNumber', event.target.value)}
                        />
                        <Button className="save-button" onClick={() => onApplicationEditModal()}>Save</Button>
                    </>
                </Modal>
                <Modal style={{ zIndex: '1' }}
                    modalTitle={"Application Intake"}
                    isModalOpen={showApplicationIntakeModal}
                    onCloseModal={onCloseModal}

                >
                    <Row>{console.log("acadacad",acad)}
                        <Col>  
                            <select                             
                                placeholder="Select Intake Month"
                                type="text"
                                className="form-control text-box"
                                value={application_intake_month}
                                onChange={(event) => onChangeApplication('application_intake_month', event.target.value)}>
                                {
                                    months.map((month) =>{
                                        return <option value={month.value}>{month.label}</option>
                                    })
                                }
                            </select>
                        </Col>
                        <Col>
                            <select                             
                                 placeholder="Select Intake Year"
                                 type="text"
                                 className="form-control text-box"
                                 value={application_intake_year}
                                 onChange={(event) => onChangeApplication('application_intake_year', event.target.value)}>
                                {
                                    options.map((year) =>{
                                        return <option value={year.value}>{year.label}</option>
                                    })
                                }
                            </select>
                        </Col>                        
                    </Row>
                    <Button className="save-button" onClick={() => onIntakeEditModal()}>Save</Button>
                  
                </Modal>
                <Modal style={{ zIndex: '1' }}
                    modalTitle={"Application Course"}
                    isModalOpen={showApplicationcourseModal}
                    onCloseModal={onCloseModal}

                >
                    <Row>
                        <Col>  
                            <select                             
                                placeholder="Select Course"
                                type="text"
                                className="form-control text-box"
                                value={applied_courses}
                                onChange={(event) => onChangeApplication('applied_courses', event.target.value)}>
                                {
                                    zaCoursesData.map((course) =>{
                                        return <option value={course.label}>{course.label}</option>
                                    })
                                }
                            </select>
                        </Col>                     
                    </Row>
                    <Button className="save-button" onClick={() => onCourseEditModal()}>Save</Button>                  
                </Modal>

                <Modal style={{ zIndex: '1' }}
                    modalTitle={"Application Type"}
                    isModalOpen={showApplicationTypeModal}
                    onCloseModal={onCloseModal}

                >
                    <Row>
                        <Col>  
                            <select                             
                                placeholder="Select application type"
                                type="text"
                                className="form-control text-box"
                                value={application_type}
                                onChange={(event) => onChangeApplication('application_type', event.target.value)}>
                                {
                                    applicationTypeData.map((applicationtype) =>{
                                        return <option value={applicationtype.value}>{applicationtype.label}</option>
                                    })
                                }
                            </select>
                        </Col>                     
                    </Row>
                    <Button className="save-button" onClick={() => onApplicationTypeEditModal()}>Save</Button>                  
                </Modal>
                <div className="application-number">
                    <Col className="application-input">
                        <Row style={{paddingBottom: 10}}>                      
                            <Col className="text-left" lg="10" md="10" sm="10" style={{marginLeft: 0}}>
                                {acad.unique_application == '1' ? <Tag intent='success' htmlTitle="Unique">Unique</Tag> : <Tag intent='danger' htmlTitle="Non-unique">Non-unique</Tag>}
                            </Col>
                            <Col style={{alignSelf: 'center'}} md="2">
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}                            
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                                    <i class="fa fa-pencil edit-pencil" style={{ cursor: 'pointer'}} aria-hidden="true" onClick={() => {openApplicationTypeEditModal(acad)}}></i>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 10}}>
                            <Col className="text-left" lg="12" md="12" sm="12" style={{marginLeft: 0}}>
                                Application date:{moment(acad.created_at).format("DD-MM-YYYY")}
                            </Col>
                        </Row>  
                        <Row>
                            <Col className="degree-type"  md="10" style={{marginLeft: 0}}>
                                {acad.applied_courses}
                            </Col>
                            <Col style={{alignSelf: 'center'}} md="2">
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}                            
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                                    <i class="fa fa-pencil edit-pencil" style={{ cursor: 'pointer'}} aria-hidden="true" onClick={() => {openApplicationCourseEditModal(acad);onEditApplicationcoursename(appliedUniversityid);}}></i>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Col>
                </div>
                <div className="degree-score">{acad.university_name}</div>
                <Row>
                    <Col md="3" >
                        <div className="degree-name">{acad.course_level}</div>
                    </Col>
                    <Col md="7">
                        <Form.Group >
                            <Form.Control
                                className="select-options"
                                name={`applicationValue${acad.id}`}
                                as="select"
                                placeholder="Status"
                                value={myState && myState[`applicationValue${acad.application_status_id}`] ? myState[`applicationValue${acad.application_status_id}`] : null}
                                onChange={(event) => onChangetext(event.target.name, event, acad.id, acad.id, acad.university_name, acad.applied_courses, acad.course_level, acad.application_status)}
                            >
                                {
                                    applicationStatus && applicationStatus.length ? applicationStatus.map((applications) => {
                                        return (
                                            <option value={applications.id}>{applications.key}</option>
                                        )
                                    })
                                        : ''}

                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <div className="application-number">
                    <Col className="application-input">
                        <Form.Label className="title">Application Intake :</Form.Label>   
                        <Row>
                            <Col md="10" className="degree-type" style={{marginLeft: '10px'}}>
                                Current Intake: {acad.application_intake_month} {acad.application_intake_year}
                            </Col>
                            <Col md="1" style={{alignSelf: 'center'}}>
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}                            
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                                    <i class="fa fa-pencil edit-pencil" style={{ cursor: 'pointer'}} aria-hidden="true" onClick={() => openApplicationIntakeEditModal(acad)}></i>
                                </OverlayTrigger>
                            </Col>
                        </Row>  
                        {
                                studentAppicationIntake &&  studentAppicationIntake.length ?              
                            <Row>
                                <Col className="degree-type">
                                    Previous Intake
                                </Col>
                                <Col className="degree-type">
                                {
                                    studentAppicationIntake ? studentAppicationIntake.map((intake) =>{
                                        if(intake.application_id == acad.id) {
                                            if(intake.application_month == acad.application_intake_month && intake.application_year == acad.application_intake_year) return 
                                            return <div>{intake.application_month} {intake.application_year}</div>
                                        }
                                    })
                                    : null
                                }
                                </Col>
                            </Row>
                            : null
                        }
                    </Col>  
                </div>
                <div className="degree-type">{userProfile && userProfile.is_counselor ? '' : channelName(acad.channel)}</div>
                <div className="application-number">
                    <Col className="application-input">
                        <Form.Label className="title">Application Number :</Form.Label>
                        <Row>
                            <Col>
                                {acad.application_number ? <p className="app-view">{acad.application_number}</p> : 'NA'}
                            </Col>
                            <Col>
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ hide: 200 }}
                                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Edit</Popover>}>
                                    <i class="fa fa-pencil edit-pencil"style={{ cursor: 'pointer'}}  aria-hidden="true" onClick={() => openApplicationEditModal(acad)}></i>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Col>
                </div>
                <Button className="view-button" onClick={() => onApplicationStatusModal(acad)}>{handleApplicationName(myState[`applicationValue${acad.application_status_id}`])} Details</Button>

            </div>)
            }
        })
    }
    return (
        <Row className="education-section">
            <Modal style={{ zIndex: '1' }}
                modalTitle={" Create Application"}
                isModalOpen={showApplicationCreateModal}
                onCloseModal={onCloseModal}

            >
                <ApplicationCreate
                    appicationCourseCreate={appicationCourseCreate}
                    onChangeInput={onChangeInput}
                    onApplyCreateCourse={onApplyCreateCourse}
                    onClearAppModal={onClearAppModal}
                    zaCoursesData={zaCoursesData}
                    zaUniversityData={zaUniversityData}
                    channelData={channelData}
                    applicationCourseType={applicationCourseType}
                    applicationType={applicationType}
                    applicationCourseUniversity={applicationCourseUniversity}
                    applicationChannelName={applicationChannelName}
                    applicationIntakeYear={applicationIntakeYear}
                    applicationIntakeMonth={applicationIntakeMonth}
                />
            </Modal>
            <Col>
                <h3>Application</h3>
                {applied}
                <div></div>
                <Button className="apply-button" onClick={() => onApplyCourse(student.id)}>Apply</Button>
            </Col>
        </Row>
    )
}
export default Application;
