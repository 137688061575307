import React, {Component} from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { DateInput } from "@blueprintjs/datetime";
import { RadioGroup, Radio } from "@blueprintjs/core";
import moment from 'moment';
import {
    Label
} from "@blueprintjs/core";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './Invoice.scss';

class Invoice extends Component {
       constructor(props) {
        super(props)
        this.state = {
            currencyinvoiceType:'',
            invoiceDate:new Date(),
            amountInvoice:'',
            id:'',
            errors:{}

        }
    }
    componentDidMount(){
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppInvoice(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.invoice && props.invoice.length){
            if(props.invoice[0].id != state.id){
                return {
                    currencyinvoiceType:props.invoice[0].invoice_currency,
                    invoiceDate:props.invoice[0].invoice_date ? new Date(props.invoice[0].invoice_date): new Date(),
                    amountInvoice:props.invoice[0].invoice_amount,
                    id:props.invoice[0].id
                  };
            }
        }
    }
    clearForm = () =>{
        this.setState({
            currencyinvoiceType:'',
            invoiceDate:new Date(),
            amountInvoice:'',
            id:'',
            errors:{}   
        })
        this.props.setClearForm()
    }
    isvalid = () => {
        const { currencyinvoiceType, invoiceDate, amountInvoice } = this.state;
        let errors = {};
        if (currencyinvoiceType == '' && !amountInvoice == '' ) {
            errors.currencyinvoiceType = true;
            this.setState({ errors });
            return false;
        }
        if (currencyinvoiceType == 'USD' || currencyinvoiceType == 'EUR' || currencyinvoiceType == 'GBP' || currencyinvoiceType == 'AUD' || currencyinvoiceType == 'INR') {
            if (amountInvoice == '') {
                errors.amountInvoice = true;
                this.setState({ errors });
                return false;
            }
        }

        if (invoiceDate == '') {
            errors.invoiceDate = true;
            this.setState({ errors });
            return false;
        }

        return true;
    };
    onSubmit = () => {
    const { onChangeInput, currencyinvoiceType, invoiceDate, amountInvoice } = this.state;
    const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if(this.isvalid()){

        const data = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'invoice_currency': currencyinvoiceType,
            'invoice_amount': amountInvoice,
            'invoice_date': invoiceDate,
            'application_id': applicationStatusId
        }
        const handlesuccessfull = () => {
            Toast.show({
                message: 'Save successfully',
                timeout: 4000,
                intent: 'success'
            });
            onCloseModal()
              this.setState({
                currencyinvoiceType:'',
                invoiceDate:new Date(),
                amountInvoice:'',
                id:''
    
                    })
        };
        const handleFailure = () => {
            Toast.show({
                message: 'Save unsuccessfully',
                timeout: 4000,
                intent: 'danger'
            });
            onCloseModal()
              this.setState({
                currencyinvoiceType:'',
                invoiceDate:new Date(),
                amountInvoice:'',
                id:''    
                    })
        }
        this.props.action.createApplicationStatus(
                                    data,
                                    handlesuccessfull,
                                    handleFailure,
                                    'invoice'
        )
    }
    }
    
    onChangeInput = (name, value) => {
        if (name == 'currencyinvoiceType') {
            this.setState({
                [name]: value, errors: {},
                amountInvoice : ''
            });
        }
        else {
            this.setState({
                [name]: value, errors: {}
            })
        }
    }
    render() {
    const {  currencyinvoiceType, invoiceDate, amountInvoice, errors } = this.state;
    const { clearModel } =this.props
    if(!clearModel){
        this.clearForm()
    }
    return (
        <div className="balance-fee">
                  <Col>
                <Form.Label className="title">Currency Type *</Form.Label>
                <Form.Control
                    as="select"
                    placeholder="currency"
                    className="form-control text-box"
                    value={currencyinvoiceType}
                    onChange={(event) => this.onChangeInput('currencyinvoiceType', event.target.value)}
                >
                    <option value="">Select currency</option>
                    <option value="USD" selected="selected">United States Dollars</option>
                    <option value="EUR">Euro</option>
                    <option value="GBP">United Kingdom Pounds</option>
                    <option value="AUD">Australia Dollars</option>
                    <option value="INR">India Rupees</option>
                </Form.Control>
                {errors.currencyinvoiceType ? <span className="errors">Please Select Appropriate Currency Type</span> : ''}
            </Col>
            <Col>
                <Form.Label className="title">Amount *</Form.Label>
                <Form.Control
                    disabled={!currencyinvoiceType}
                    placeholder="Enter Amount"
                    type="text"
                    className="form-control text-box"
                    value={amountInvoice}
                    onChange={(event) => this.onChangeInput('amountInvoice', event.target.value)}
                />
                {errors.amountInvoice ? <span className="errors">Please Enter Amount</span> : ''}
            </Col>
            <Col>
                <Label className="applied-date title" htmlFor="input-b"> Date *
                <DateInput
                        formatDate={date => moment(date).format("DD/MM/YYYY")}
                        onChange={(date) => this.onChangeInput("invoiceDate", date)}
                        parseDate={str => new Date(str)}
                        placeholder={"DD/MM/YYYY"}
                        defaultValue={new Date()}
                        maxDate={new Date('1/1/2030')}
                        value={invoiceDate}
                    />
                </Label>
            </Col>
            {errors.invoiceDate ? <span className="errors">Please Enter Valid Date </span> : ''}
            <Col className="button-save">
                <Button className="save-offer"  onClick={this.onSubmit}>Save</Button>
            </Col>
        </div>
    )
}
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        invoice: state.students.invoice
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Invoice));

