import React, { Component } from "react";
import { RadioGroup, Radio } from "@blueprintjs/core";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import action from '../../../modules/actions'
import { Toast } from "../../../../../shared/toast/Toast";
import './Enrolled.scss';

class Enrolled extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enrolled:null,
            enrolledYesChecked: false,
            enrolledNoChecked: false,
            id:''

        }
    }
    componentDidMount(){
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppEnrolled(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if(props.enrolled && props.enrolled.length){
            if(props.enrolled[0].id != state.id){
                return {
                    enrolledYesChecked: props.enrolled[0].enrolled, 
                    enrolledNoChecked: !props.enrolled[0].enrolled, 
                    id:props.enrolled[0].id
                  };
            }
        }
    }
    clearForm = () =>{
        this.setState({
            enrolled:null,
            enrolledYesChecked: false,
            enrolledNoChecked: false,
            id:''
        })
        this.props.setClearForm()
    }
    onSubmit = () => {
        const {  enrolled } = this.state;
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;

        const data = {
            'student_id': params.id,
            'user_id': userProfile.organization.user_id,
            'organization_id': userProfile.organization.organization_id,
            'enrolled': enrolled,
            'application_id': applicationStatusId

        }
        const handlesuccessfull = () => {
            Toast.show({
                message: 'Save successfully',
                timeout: 4000,
                intent: 'success'
            });
            onCloseModal()
            this.setState({
                enrolled:null,
            enrolledYesChecked: false,
            enrolledNoChecked: false,
            id:''
            })
        };
        const handleFailure = () => {
            Toast.show({
                message: 'Save unsuccessfully',
                timeout: 4000,
                intent: 'danger'
            });
            onCloseModal()
            this.setState({
                enrolled:null,
            enrolledYesChecked: false,
            enrolledNoChecked: false,
            id:''
            })
        }
        this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'enrolled')


    }
    onCheckYes = (e) => {
        const { target: { name } } = e;
        this.setState({
            [name]: true,
            [`${name}YesChecked`]: true,
            [`${name}NoChecked`]: false
        })

    }

    onCheckNo = (e) => {
        const { target: { name } } = e;
        this.setState({
            [name]: false,
            [`${name}YesChecked`]: false,
            [`${name}NoChecked`]: true
        })
    }
    onChangeInput = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    render() {

        const { enrolled, enrolledYesChecked, enrolledNoChecked } = this.state;
        const { clearModel } =this.props
        if(!clearModel){
            this.clearForm()
        }
        return (
            <div className="university-assessment">
                <Col>

                <Form.Group as={Row}>
                        <Col>
                            <Form.Check
                                checked={enrolledYesChecked}
                                inline
                                name={'enrolled'}
                                onChange={(e) => {
                                    this.onCheckYes(
                                        {
                                            "target": { id: "radio1", value: true, name: "enrolled" }
                                        })
                                }}
                                label="Yes"
                                type={'radio'}
                            />
                            <Form.Check
                                checked={enrolledNoChecked}
                                inline
                                name={'enrolled'}
                                onChange={(e) => {
                                    this.onCheckNo({
                                        "target": { id: "radio1", value: false, name: "enrolled" }
                                    })
                                }}
                                label="No"
                                type={'radio'} />
                        </Col>
                    </Form.Group>

                </Col>
                <Button className="save-offer" onClick={this.onSubmit}>Save</Button>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        enrolled: state.students.enrolled
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Enrolled));
