import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Elevation, MenuItem, Toaster, Label } from "@blueprintjs/core";
import { bindActionCreators } from 'redux';
import { Omnibar } from "@blueprintjs/select";
import Card from 'react-bootstrap/Card'
import { Row, Col, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import Paginator from '../../../shared/pagination/pagination'
import Dropzone from 'react-dropzone';
import data from "../components/staticFile"
import StudentMarkup from '../components/StudentMarkup'
import "./Student.scss"
import { Toast } from '../../../shared';
import interviewActions from '../../Interview/modules/actions';
import Modal from "../../../shared/model/model"
import actions from '../modules/actions'
import memberActions from '../../Members/modules/actions';
import sourceActions from '../../Source/modules/actions'
import { ZLoader } from '../../../shared/loader/ZLoader';
import Denied from '../../../shared/denied/Denied'
import { WEB_URL } from '../../../env';
import DownloadExport from '../container/StudentExport';
import moment from 'moment';
import AdvancedFilter from './AdvancedFilters'
import InputAppend from '../../../shared/input/InputCrossed'
let taday = moment()
class Student extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userRoleAccess: [],
            showModal: false,
            viewAccess: false,
            studentFile: null,
            loading: true,
            pageIndex: 1,
            selectedInterview: '',
            interviewName: '',
            studentId: '',
            showInterview: false,
            interviewEmail: '',
            interviewEmailContent: '',
            currentInterview: '',
            showVideoModal: false,
            keywordsearch: "",
            selectedInterview: null,
            isValid: true,
            leadStatusSearch: '',
            assignedIdSearch: '',
            supportStaffIdSearch: '',
            intakeMonthSearch: '',
            intakeYearSearch: '',
            sourceSearch: '',
            showExportModal: false,
            exportStartDate: taday.toDate(),
            exportEndDate: taday.toDate(),
            downloadStudentData: true,
            isContextMenuOpen: false,
            showAdvancedFilterModal: false,
            applicationStatusSearch:'',

            supportStaffFilter:'',
            ownerFilter: '',
            assignedFilter: '',
            leadStatusFilter: '',
            intakeFilter: '',
            intakeYearFilter: '',
            sourceFilter: '',
            searchFilter: '',
            startDateFilter: '',
            endDateFilter: '',
            universitySearchFilter: '',
            courseSearchFilter: '',
            applicationStatusSearchFilter:'',

            universitySearch: '',
            universitySearchObj: '',

            courseSearch: '',
            studentStartDate: '',
            studentEndDate: '',
            rootCloseVal: false,

            supportStaffAdvancedFilter: '',
            ownerAdvancedFilter: '',
            assignedAdvancedFilter: '',
            leadStatusAdvancedFilter: '',
            intakeAdvancedFilter: '',
            intakeYearAdvancedFilter: '',
            sourceAdvancedFilter: '',
            searchAdvancedFilter: '',
            startDateAdvancedFilter: '',
            endDateAdvancedFilter: '',
            universitySearchAdvancedFilter: '',
            courseSearchAdvancedFilter: '',
            applicationStatusSearchAdvancedFilter: '',

        }
    }
    componentDidMount() {
        const { pageIndex, keywordsearch, leadStatusSearch, assignedIdSearch, intakeMonthSearch,intakeYearSearch, sourceSearch, applicationStatusSearch, supportStaffIdSearch } = this.state
        const { pageNumber, user: { organization } } = this.props
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));
        const filterObject = {
            search: keywordsearch,
            status: leadStatusSearch,
            assigned: assignedIdSearch,
            month: intakeMonthSearch,
            year: intakeYearSearch,
            source: sourceSearch,
            application: applicationStatusSearch,
            yopmail:yopmail,
            supportstaff: supportStaffIdSearch

        }
        if (pageNumber) {
            this.onPageChange(pageNumber)
            this.props.memberActions.getAllMembers(organization.organization_id);
            this.props.sourceActions.getOrgSource(organization.organization_id);

        }
        else {
            this.props.actions.getAllStudents(pageIndex, filterObject);
            this.props.memberActions.getAllMembers(organization.organization_id);
            this.props.sourceActions.getOrgSource(organization.organization_id);

        }
        this.props.interviewActions.getInterviews();
        this.props.actions.getZoomabroadUniversities()
        this.props.actions.getApplicationStatus(organization.organization_id)
        // this.props.actions.getAllApplications();
    }


    static getDerivedStateFromProps(props, state) {
        const { user } = props
        const access = user.organization.access
        let finalData = access ? JSON.parse(access) : [{ "operations": [1, 1, 1, 1], "name": "MAPPING" }, { "operations": [1, 1, 1, 1], "name": "QUESION" }, { "operations": [1, 1, 1, 1], "name": "INTERVIEW" }, { "operations": [1, 1, 1, 1], "name": "COURSE" }, { "operations": [1, 1, 1, 1], "name": "REPORT" }, { "operations": [1, 1, 1, 1], "name": "STUDENTS" }]
        if (finalData) {
            let viewAccess = false
            let searchFilterName = ''
            let leadFilterSearch = ''
            let searchFilterAssigned = ''
            let searchFilterSupportStaff = ''
            let searchFilterMonth = ''
            let searchFilterYear = ''
            let searchFilterSource
            let searchFilterStartDate
            let searchFilterEndDate = ''
            let searchUniversityFilter = ''
            let searchCourseFilter = ''
            let searchapplicationStatusFilter = ''

            if (finalData[5].operations.includes(1)) {
                viewAccess = true
            }
            if (state.keywordsearch !== props.searchName) {
                searchFilterName = props.searchName
            }
            if (state.leadStatusSearch !== props.studentLead) {
                leadFilterSearch = props.studentLead
            }
            if (state.intakeMonthSearch !== props.searchMonth) {
                searchFilterMonth = props.searchMonth
            }
            if (state.intakeYearSearch !== props.searchYear) {
                searchFilterYear = props.searchYear
            }
            if (state.assignedIdSearch !== props.searchAssigned) {
                searchFilterAssigned = props.searchAssigned
            }
            if (state.sourceSearch !== props.searchSource) {
                searchFilterSource = props.searchSource
            }
            if (state.studentStartDate !== props.searchStartDate) {
                searchFilterStartDate = props.studentStartDate
            }
            if (state.studentEndDate !== props.searchEndDate) {
                searchFilterEndDate = props.studentEndDate
            }
            if (state.universitySearch !== props.universitySearch) {
                searchUniversityFilter = props.universitySearch
            }
            if (state.courseSearch !== props.courseSearch) {
                searchCourseFilter = props.courseSearch
            }
            if (state.applicationStatusSearch !== props.applicationStatusSearch) {
                searchapplicationStatusFilter = props.applicationStatusSearch
            }
            if(state.supportStaffIdSearch !==props.searchSupportStaff){
                searchFilterSupportStaff = props.searchStaffIdSearch
            }
            // applicationStatusSearch
            if (props.searchAdvancedFilter) {
                return {
                    userRoleAccess: finalData,
                    viewAccess: viewAccess,

                    keywordsearch: searchFilterName ? searchFilterName : state.keywordsearch,
                    leadStatusSearch: leadFilterSearch ? leadFilterSearch : state.leadStatusSearch,
                    assignedIdSearch: searchFilterAssigned ? searchFilterAssigned : state.assignedIdSearch,
                    supportStaffIdSearch: searchFilterSupportStaff ? searchFilterSupportStaff : state.supportStaffIdSearch,
                    intakeMonthSearch: searchFilterMonth ? searchFilterMonth : state.intakeMonthSearch,
                    intakeYearSearch: searchFilterYear ? searchFilterYear : state.intakeYearSearch,
                    sourceSearch: searchFilterSource ? searchFilterSource : state.sourceSearch,
                    studentStartDate: searchFilterStartDate ? searchFilterStartDate : state.studentStartDate,
                    studentEndDate: searchFilterEndDate ? searchFilterEndDate : state.studentEndDate,
                    universitySearch: searchUniversityFilter ? searchUniversityFilter : state.universitySearch,
                    courseSearch: searchCourseFilter ? searchCourseFilter : state.courseSearch,
                    applicationStatusSearch: searchapplicationStatusFilter ? searchapplicationStatusFilter :state.applicationStatusSearch,

                    supportStaffFilter: searchFilterSupportStaff ? Student.runThisFunction('supportStaffIdSearch', searchFilterSupportStaff, props) : Student.runThisFunction('supportStaffIdSearch', state.supportStaffIdSearch, props),
                    ownerFilter: searchFilterAssigned ? Student.runThisFunction('assignedIdSearch', searchFilterAssigned, props) : Student.runThisFunction('assignedIdSearch', state.assignedIdSearch, props),
                    leadStatusFilter: leadFilterSearch ? Student.runThisFunction('leadStatusSearch', leadFilterSearch, props) : Student.runThisFunction('leadStatusSearch', state.leadStatusSearch, props),
                    intakeFilter: searchFilterMonth ? Student.runThisFunction('intakeMonthSearch', searchFilterMonth, props) : Student.runThisFunction('intakeMonthSearch', state.intakeMonthSearch, props),
                    intakeYearFilter: searchFilterYear ? Student.runThisFunction('intakeYearSearch', searchFilterYear, props) : Student.runThisFunction('intakeYearSearch', state.intakeYearSearch, props),
                    sourceFilter: searchFilterSource ? Student.runThisFunction('sourceSearch', searchFilterSource, props) : Student.runThisFunction('sourceSearch', state.sourceSearch, props),
                    startDateFilter: searchFilterStartDate ? Student.runThisFunction('studentStartDate', searchFilterStartDate, props) : Student.runThisFunction('studentStartDate', state.studentStartDate, props),
                    endDateFilter: searchFilterEndDate ? Student.runThisFunction('studentEndDate', searchFilterEndDate, props) : Student.runThisFunction('studentEndDate', state.studentEndDate, props),
                    universitySearchFilter: searchUniversityFilter ? Student.runThisFunction('universitySearch', searchUniversityFilter, props) : Student.runThisFunction('universitySearch', state.universitySearch, props),
                    courseSearchFilter: searchCourseFilter ? Student.runThisFunction('courseSearch', searchCourseFilter, props) : Student.runThisFunction('courseSearch', state.courseSearch, props),
                    applicationStatusSearchFilter: searchapplicationStatusFilter ? Student.runThisFunction('applicationStatusSearch', searchapplicationStatusFilter, props) : Student.runThisFunction('applicationStatusSearch', state.applicationStatusSearch, props),
                    
                    // searchFilter:props.search ? props.search : state.keywordsearch
                }
            }
            else {
                return {
                    userRoleAccess: finalData,
                    viewAccess: viewAccess,

                    keywordsearch: searchFilterName ? searchFilterName : state.keywordsearch,
                    leadStatusSearch: leadFilterSearch ? leadFilterSearch : state.leadStatusSearch,
                    supportStaffIdSearch: searchFilterSupportStaff ? searchFilterSupportStaff : state.supportStaffIdSearch,
                    assignedIdSearch: searchFilterAssigned ? searchFilterAssigned : state.assignedIdSearch,
                    intakeMonthSearch: searchFilterMonth ? searchFilterMonth : state.intakeMonthSearch,
                    intakeYearSearch: searchFilterYear ? searchFilterYear : state.intakeYearSearch,
                    sourceSearch: searchFilterSource ? searchFilterSource : state.sourceSearch,
                    studentStartDate: searchFilterStartDate ? searchFilterStartDate : state.studentStartDate,
                    studentEndDate: searchFilterEndDate ? searchFilterEndDate : state.studentEndDate,
                    universitySearch: searchUniversityFilter ? searchUniversityFilter : state.universitySearch,
                    courseSearch: searchCourseFilter ? searchCourseFilter : state.courseSearch,
                    applicationStatusSearch: searchapplicationStatusFilter ? searchapplicationStatusFilter :state.applicationStatusSearch,
                    
                    supportStaffFilter: searchFilterSupportStaff ? Student.runThisFunction('supportStaffIdSearch', searchFilterSupportStaff, props) : Student.runThisFunction('supportStaffIdSearch', state.supportStaffIdSearch, props),
                    ownerFilter: searchFilterAssigned ? Student.runThisFunction('assignedIdSearch', searchFilterAssigned, props) : Student.runThisFunction('assignedIdSearch', state.assignedIdSearch, props),
                    leadStatusFilter: leadFilterSearch ? Student.runThisFunction('leadStatusSearch', leadFilterSearch, props) : Student.runThisFunction('leadStatusSearch', state.leadStatusSearch, props),
                    intakeFilter: searchFilterMonth ? Student.runThisFunction('intakeMonthSearch', searchFilterMonth, props) : Student.runThisFunction('intakeMonthSearch', state.intakeMonthSearch, props),
                    intakeYearFilter: searchFilterYear ? Student.runThisFunction('intakeYearSearch', searchFilterYear, props) : Student.runThisFunction('intakeYearSearch', state.intakeYearSearch, props),
                    sourceFilter: searchFilterSource ? Student.runThisFunction('sourceSearch', searchFilterSource, props) : Student.runThisFunction('sourceSearch', state.sourceSearch, props),
                    startDateFilter: searchFilterStartDate ? Student.runThisFunction('studentStartDate', searchFilterStartDate, props) : Student.runThisFunction('studentStartDate', state.studentStartDate, props),
                    endDateFilter: searchFilterEndDate ? Student.runThisFunction('studentEndDate', searchFilterEndDate, props) : Student.runThisFunction('studentEndDate', state.studentEndDate, props),
                    universitySearchFilter: searchUniversityFilter ? Student.runThisFunction('universitySearch', searchUniversityFilter, props) : Student.runThisFunction('universitySearch', state.universitySearch, props),
                    courseSearchFilter: searchCourseFilter ? Student.runThisFunction('courseSearch', searchCourseFilter, props) : Student.runThisFunction('courseSearch', state.courseSearch, props),
                    applicationStatusSearchFilter: searchapplicationStatusFilter ? Student.runThisFunction('applicationStatusSearch', searchapplicationStatusFilter, props) : Student.runThisFunction('applicationStatusSearch', state.applicationStatusSearch, props),
                    
                    // searchFilter:props.search ? props.search : state.keywordsearch
                }
            }

        }
    }

    static runThisFunction(name, value, props) {
        const { sourceList, allMembers } = props;
        if (name == 'leadStatusSearch') {
            let str = value.replace('_', ' ');
            var res = str.split(" ");
            let name = value.includes("_") ? res[0].charAt(0).toUpperCase() + res[0].slice(1) + `${' '}` + res[1].charAt(0).toUpperCase() + res[1].slice(1) : value.charAt(0).toUpperCase() + value.slice(1);
            return name;
        }
        if (name == 'assignedIdSearch') {
            if (allMembers && allMembers) {
                let members = allMembers.Users
                if (members) {
                    let name = '';
                    let x = members.map((member) => {
                        if (member.user_id == value && value != '') {
                            name = member.first_name
                        }
                    })
                    return name;
                }
            }
        }
        if (name == 'supportStaffIdSearch') {
            if (allMembers && allMembers) {
                let members = allMembers.Users
                if (members) {
                    let name = '';
                    let x = members.map((member) => {
                        if (member.user_id == value && value != '') {
                            name = member.first_name
                        }
                    })
                    return name;
                }
            }
        }
        if (name == 'intakeMonthSearch') {
            let name = value.charAt(0).toUpperCase() + value.slice(1)
            return name;
        }
        if (name == 'intakeYearSearch') {            
            return value
        }
        if (name == 'sourceSearch') {
            let sourceName = sourceList
            if (sourceName && sourceName) {
                let name = '';
                let x = sourceName.map((source) => {
                    if (source.id == value && value != '') {
                        name = source.source_short_name
                    }
                })
                return name;
            }
        }
        if (name == 'studentStartDate') {
            let date = value ? moment(value).format("MMM Do YY") : '';
            return date
        }
        if (name == 'studentEndDate') {
            let date = value ? moment(value).format("MMM Do YY") : ''
            return date
        }
        if (name == 'universitySearch') {
            return value;
        }
        if (name == 'courseSearch') {
            return value;
        }
        if (name == 'applicationStatusSearch') {
            return value;
        }
    }

    filterAllStudent = () => {

        const {
            pageIndex,
            keywordsearch,
            leadStatusSearch,
            supportStaffIdSearch,
            assignedIdSearch,
            intakeMonthSearch,
            intakeYearSearch,
            sourceSearch,
            studentStartDate,
            studentEndDate,
            universitySearch,
            courseSearch,
            ownerAdvancedFilter,
            assignedAdvancedFilter,
            leadStatusAdvancedFilter,
            intakeAdvancedFilter,
            sourceAdvancedFilter,
            searchAdvancedFilter,
            startDateAdvancedFilter,
            endDateAdvancedFilter,
            universitySearchAdvancedFilter,
            courseSearchAdvancedFilter,
            applicationStatusSearch,
            applicationStatusSearchAdvancedFilter,
            intakeYearAdvancedFilter

        } = this.state;

        this.setState({
            supportStaffFilter: supportStaffIdSearch,
            ownerFilter: ownerAdvancedFilter,
            assignedFilter: assignedAdvancedFilter,
            leadStatusFilter: leadStatusAdvancedFilter,
            intakeFilter: intakeAdvancedFilter,
            intakeYearFilter: intakeYearAdvancedFilter,
            sourceFilter: sourceAdvancedFilter,
            searchFilter: searchAdvancedFilter,
            startDateFilter: startDateAdvancedFilter,
            endDateFilter: endDateAdvancedFilter,
            universitySearchFilter: universitySearchAdvancedFilter,
            courseSearchFilter: courseSearchAdvancedFilter,
            applicationStatusSearchFilter: applicationStatusSearchAdvancedFilter
        })
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));
        const filterObject = {
            search: keywordsearch,
            status: leadStatusSearch,
            assigned: assignedIdSearch,
            month: intakeMonthSearch,
            year: intakeYearSearch,
            source: sourceSearch,
            start: studentStartDate && studentStartDate ? moment(studentStartDate).format('YYYY-MM-DD HH:mm:ss') : studentStartDate,
            end: studentEndDate ? moment(studentEndDate).format('YYYY-MM-DD HH:mm:ss') : studentEndDate,
            university: universitySearch,
            course: courseSearch,
            application: applicationStatusSearch,
            yopmail: yopmail,
            supportstaff: supportStaffIdSearch
        }
        this.props.actions.getAllStudents(1, filterObject);
    }
    reportPush = (id) => {
        this.props.history.push(`/student/result/${id}`);
        let filterApply = true
        this.props.actions.advancedFilterApply(filterApply)
    }
    editPush = (id) => {
        this.props.history.push(`/student/view/${id}`);
        let filterApply = true
        this.props.actions.advancedFilterApply(filterApply)

    }

    onShowModal = () => {

        this.setState({
            showModal: true
        })

    }
    SetContextMenuOpen = () => {
        this.setState({ isContextMenuOpen: true })
    }

    SetContextMenuClose = () => {
        this.setState({ isContextMenuOpen: false })
    }

    onshowVideoModal = (inter) => {
        this.setState({
            showVideoModal: true,
            selectedInterview: inter
        })
    }
    handleClick = (id) => {
        this.setState({
            isOpen: true,
            studentId: id

        })

    };
    onDropStudentFile = (studentFile) => {
        this.setState({ studentFile });
    }

    onOpenshowAdvancedFilterModal = () => {
        this.setState({
            showAdvancedFilterModal: true,
        })
    }
    onCloseModal = () => {
        this.setState({
            showModal: false,
            showInterview: false,
            showVideoModal: false,
            showExportModal: false,
            downloadStudentData: true,
            showAdvancedFilterModal: false
        });
    }
    handleItemSelect = (interview) => {
        this.setState({
            isOpen: false,
            selectedInterview: interview.id,
            interviewName: interview.set_name

        })
        this.handleTest(interview);

    };
    handleTest = (interview) => {
        const { selectedInterview, interviewName, studentId, pageIndex } = this.state;
        // const { match: { params}, studentReport } = this.props;

        let data = {}
        data.interview_id = interview.id
        data.interview_name = interview.set_name
        const handleSuccess = () => {
            Toast.show({
                message: "Interview Selected",
                timeout: 4000,
                intent: 'success'
            })
            const { pageIndex, keywordsearch } = this.state
            this.props.actions.getAllStudents(pageIndex, keywordsearch);
            // this.props.actions.getAllApplications();

        }
        this.props.actions.updateReport(handleSuccess, studentId, data)


    }


    uploadFile = (event) => {
        const { user: { user, organization } } = this.props;
        const { studentFile } = this.state;

        const formData = new FormData()
        formData.append("user_id", user.id);
        formData.append("organization_id", organization.organization_id);
        if (!studentFile) {
            Toast.show({
                message: "Please Upload CSV File",
                timeout: 4000,
                intent: 'danger'

            })
            this.onCloseModal();
        }
        else {
            formData.append("studentFile", studentFile[0], 'student_file.csv');
            const handleSuccess = () => {

                Toast.show({
                    message: "File upload Successfully",
                    timeout: 4000,
                    intent: 'success'

                })
                this.filterAllStudent()
            };
            const handleFailure = (data) => {
                Toast.show({
                    message: "Please Check CSV File ",
                    timeout: 4000,
                    intent: 'Danger'

                })
            };
            this.setState({
                studentFile: ''
            })
            this.onCloseModal();
            this.props.actions.upload(handleSuccess, handleFailure, formData)
        }
    }


    updateDirection = (event) => {
        const ele = document.getElementById(event.target.id);
        if (ele) {
            ele.classList.toggle('zbexpand');
        }
    }


    itemRender = (current, type, element) => {
        if (type === 'page') {

            return <a>{current}</a>;
        }
        return element;
    };

    onPageChange = (pageIndex) => {
        const { keywordsearch, leadStatusSearch, assignedIdSearch, supportStaffIdSearch, intakeMonthSearch,intakeYearSearch, sourceSearch,
            studentStartDate, studentEndDate, universitySearch, courseSearch, applicationStatusSearch } = this.state
        this.setState({
            pageIndex: pageIndex
        });
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));

        const filterObject = {
            search: keywordsearch,
            status: leadStatusSearch,
            assigned: assignedIdSearch,
            month: intakeMonthSearch,
            year: intakeYearSearch,
            source: sourceSearch,
            start: studentStartDate && studentStartDate ? moment(studentStartDate).format('YYYY-MM-DD HH:mm:ss') : studentStartDate,
            end: studentEndDate ? moment(studentEndDate).format('YYYY-MM-DD HH:mm:ss') : studentEndDate,
            university: universitySearch,
            course: courseSearch,
            application: applicationStatusSearch,
            yopmail: yopmail,
            supportstaff: supportStaffIdSearch


        }
        this.props.actions.getAllStudents(pageIndex, filterObject);
        this.props.actions.paginationBack(pageIndex)
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        this.props.actions.paginationSearch(e.target.value)
        this.props.actions.paginationBack(1)
    };

    handleClose = () => this.setState({ isOpen: false });
    renderFilm = (interview, { handleClick, modifiers, query }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        const text = `${interview.set_name}`;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={interview.id}
                onClick={handleClick}
                text={text}
                selectedInterview={this.state.selectedInterview}
                interviewEmailContent={this.state.interviewEmailContent}
            />
        );
    };
    onSearch = () => {
        const { keywordsearch } = this.state;
        this.filterAllStudent()
        this.props.actions.paginationSearch(keywordsearch)
        this.setState({ showAdvancedFilterModal: false, searchFilter: keywordsearch, rootCloseVal: true })
    }

    filterItems = (query, interview) => {
        return interview ? interview.set_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 : [];
    };

    handleOpenInterview = (mail, interviewDetails) => {
        this.setState({
            showInterview: true,
            interviewEmail: mail,
            currentInterview: interviewDetails

        })
    }
    handleTextChange = (name, event) => {
        this.setState({ [name]: event.target.value });
    }
    handleChangeCk = (value) => {
        this.setState({
            interviewEmailContent: value
        });
    }
    onDeleteInterview = (id) => {

        let data = {}
        data.interview_id = null
        data.interview_name = null
        const handleSuccess = () => {


            Toast.show({
                message: "Interview Removed",
                timeout: 4000,
                intent: 'success'
            })
            this.filterAllStudent()
            // this.props.actions.getAllApplications();
        }

        this.props.actions.updateReport(handleSuccess, id, data)
    }

    onSendInterview = (data) => {
        const { currentInterview, interviewEmail, interviewEmailContent, pageIndex } = this.state
        const sendData = {
            name: `${data.first_name} ${data.last_name}`,
            email: interviewEmail,
            course: data.applied_course,
            interview_id: currentInterview.interview_id,
            application_id: data.id,
            htmlFile: interviewEmailContent
        }

        const handleSuccess = () => {
            Toast.show({
                message: "Interview mail sent Successfully",
                timeout: 4000,
                intent: 'success'

            })
            this.onCloseModal()
            this.filterAllStudent()
        }
        const succes = () => {

        }
        const handleFailure = () => {
            Toast.show({
                message: "Interview mail could not be sent",
                timeout: 10000,
                intent: 'danger'

            })
            this.onCloseModal()
            this.filterAllStudent()
        }
        this.props.actions.sendInterviewLink(sendData, handleSuccess, handleFailure)
        this.props.actions.updateReport(succes, currentInterview.student_id, { interview_status: 1 });

    }
    openInNewTab = (id) => {
        var win = window.open(`${WEB_URL}/profile/check/${id}`, '_blank');
        win.focus();
    }
    openStudentEdit = (id) => {
        this.props.history.push(`/student/edit/${id}`);
        let filterApply = true
        this.props.actions.advancedFilterApply(filterApply)
    }
    handleKeyPress = (event, click) => {
        let { isValid, keywordsearch } = this.state;
        if (isValid) {
            if (event.key === 'Enter' || click) {
                this.setState({ searchFilter: keywordsearch })
                this.onSearch()
                // const { onChangeFilter } = this.props;
                // onChangeFilter(event);
            }
        }
    };
    onChangeInput = (name, value, event) => {
        this.setState({ [name]: value })
    }

    onChangeAdvanceFilter = (name, value, event) => {
        const { keywordsearch, leadStatusSearch, assignedIdSearch, supportStaffIdSearch, intakeMonthSearch,intakeYearSearch, sourceSearch, studentStartDate,
            studentEndDate, universitySearch, courseSearch, applicationStatusSearch } = this.state

        const { sourceList, allMembers } = this.props;
        this.setState({ [name]: value})
        if (name == 'universitySearch') {
            this.setState({ [name]: value.label, universitySearchObj: value  });

        }
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));

        const filterObject = {
            search: keywordsearch,
            status: leadStatusSearch,
            assigned: assignedIdSearch,
            month: intakeMonthSearch,
            year: intakeYearSearch,
            source: sourceSearch,
            start: studentStartDate && studentStartDate ? moment(studentStartDate).format('YYYY-MM-DD HH:mm:ss') : studentStartDate,
            end: studentEndDate ? moment(studentEndDate).format('YYYY-MM-DD HH:mm:ss') : studentEndDate,
            university: universitySearch,
            course: courseSearch,
            appliaction: applicationStatusSearch,
            yopmail: yopmail,
            supportstaff: supportStaffIdSearch
        }
        let filterApply = false
        this.props.actions.advancedFilterApply(filterApply)

        if (name == 'leadStatusSearch') {
            this.props.actions.paginationLead(value);
            filterObject.status = value
            let str = value.replace('_', ' ');
            var res = str.split(" ");
            let name = value.includes("_") ? res[0].charAt(0).toUpperCase() + res[0].slice(1) + `${' '}` + res[1].charAt(0).toUpperCase() + res[1].slice(1) : value.charAt(0).toUpperCase() + value.slice(1);
            this.setState({ leadStatusAdvancedFilter: name })
        }
        if (name == 'assignedIdSearch') {
            this.props.actions.paginationAssigned(value);
            filterObject.assigned = value
            if (allMembers && allMembers) {
                let members = allMembers.Users
                if (members) {
                    let name = '';
                    let x = members.map((member) => {
                        if (member.user_id == value) {
                            name = member.first_name
                        }
                    })
                    this.setState({ ownerAdvancedFilter: name });
                }
            }
        }
        if (name == 'supportStaffIdSearch') {
            this.props.actions.paginationSupportStaff(value);
            filterObject.assigned = value
            if (allMembers && allMembers) {
                let members = allMembers.Users
                if (members) {
                    let name = '';
                    let x = members.map((member) => {
                        if (member.user_id == value) {
                            name = member.first_name
                        }
                    })
                    this.setState({ ownerAdvancedFilter: name });
                }
            }
        }
        if (name == 'intakeMonthSearch') {
            this.props.actions.paginationMonth(value);
            filterObject.month = value
            let name = value.charAt(0).toUpperCase() + value.slice(1)
            this.setState({ intakeAdvancedFilter: name })
        }
        if (name == 'intakeYearSearch') {
            this.props.actions.paginationYear(value);
            filterObject.year = value
            let name = value
            this.setState({ intakeYearAdvancedFilter: name })
        }
        if (name == 'sourceSearch') {
            this.props.actions.paginationSource(value);
            filterObject.source = value
            let sourceName = sourceList
            if (sourceName && sourceName) {
                let name = '';
                let x = sourceName.map((source) => {
                    if (source.id == value) {
                        name = source.source_short_name
                    }
                })
                this.setState({ sourceAdvancedFilter: name });
            }
        }
        if (name == 'studentStartDate') {
            this.setState({ startDateAdvancedFilter: name });
        }
        if (name == 'studentEndDate') {
            this.setState({ endDateAdvancedFilter: name });
        }
        if (name == 'universitySearch') {
            this.setState({ universitySearchAdvancedFilter: name });
        }
        if (name == 'courseSearch') {
            this.setState({ courseSearchAdvancedFilter: name });
        }
        if( name == 'applicationStatusSearch'){
            this.setState({applicationStatusSearchAdvancedFilter: name})
        }

    }
    resetFilter = (name) => {
        this.setState({ [name]: '' })
        const { keywordsearch, leadStatusSearch, assignedIdSearch, intakeMonthSearch,intakeYearSearch, sourceSearch, studentStartDate,
            studentEndDate, universitySearch, courseSearch, applicationStatusSearch, supportStaffIdSearch } = this.state

        let value = '';
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));

        const filterObject = {
            search: keywordsearch,
            status: leadStatusSearch,
            assigned: assignedIdSearch,
            month: intakeMonthSearch,
            year: intakeYearSearch,
            source: sourceSearch,
            start: studentStartDate && studentStartDate ? moment(studentStartDate).format('YYYY-MM-DD HH:mm:ss') : studentStartDate,
            end: studentEndDate ? moment(studentEndDate).format('YYYY-MM-DD HH:mm:ss') : studentEndDate,
            university: universitySearch,
            course: courseSearch,
            appliaction: applicationStatusSearch,
            yopmail: yopmail,
            supportstaff: supportStaffIdSearch

        }

        if (name == 'searchFilter') {
            this.props.actions.paginationSearch(value)
            filterObject.search = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ searchFilter: value, keywordsearch: value })

        }
        if (name == 'ownerFilter') {
            this.props.actions.paginationAssigned(value);
            filterObject.assigned = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ ownerFilter: value, assignedIdSearch: value })

        }
        if (name == 'supportStaffFilter') {
            this.props.actions.paginationSupportStaff(value);
            filterObject.supportstaff = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ supportStaffFilter: value, supportStaffIdSearch: value })

        }
        if (name == 'leadStatusFilter') {
            this.props.actions.paginationLead(value);
            filterObject.status = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ leadStatusFilter: value, leadStatusSearch: value })
        }
        if (name == 'intakeFilter') {
            this.props.actions.paginationMonth(value);
            filterObject.month = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ intakeFilter: value, intakeMonthSearch: '' })
        }
        if (name == 'intakeYearFilter') {
            this.props.actions.paginationYear(value);
            filterObject.year = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ intakeYearFilter: value, intakeYearSearch: '' })
        }
        if (name == 'sourceFilter') {
            this.props.actions.paginationSource(value);
            filterObject.source = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ sourceFilter: value, sourceSearch: '' })
        }
        if (name == 'startDateFilter') {
            this.props.actions.paginationStartDate(value);
            filterObject.start = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ startDateFilter: value, studentStartDate: '' });
        }
        if (name == 'endDateFilter') {
            this.props.actions.paginationEndDate(value);
            filterObject.end = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ endDateFilter: value, studentEndDate: '' });
        }
        if (name == 'universitySearchFilter') {
            this.props.actions.paginationUniversity(value);
            filterObject.university = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ universitySearchFilter: value, universitySearch: '' });
        }
        if (name == 'courseSearchFilter') {
            this.props.actions.paginationCourse(value);
            filterObject.course = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ courseSearchFilter: value, courseSearch: '' });
        }
        if (name == 'applicationStatusSearchFilter') {
            this.props.actions.paginationApplicationStatus(value);
            filterObject.application = value
            this.props.actions.getAllStudents(1, filterObject);
            this.setState({ applicationStatusSearchFilter: value, applicationStatusSearch: '' });
        }
    }

    onReset = () => {
        let target = { name: 'keywordsearch', value: '' };
        let e = { target: { name: 'keywordsearch', value: '' } };
        this.onChange(e)
        this.setState({
            leadStatusSearch: '',
            assignedIdSearch: '',
            supportStaffIdSearch:'',
            intakeMonthSearch: '',
            intakeYearSearch: '',
            sourceSearch: '',
            sourceFilter: '',
            ownerFilter: '',
            supportStaffFilter: '',
            leadStatusFilter: '',
            intakeFilter: '',            
            intakeYearFilter: '',
            searchFilter: '',
            universitySearchFilter: '',
            courseSearchFilter: '',
            startDateFilter: '',
            endDateFilter: '',
            applicationStatusSearchFilter: '',

            ownerAdvancedFilter: '',
            assignedAdvancedFilter: '',
            leadStatusAdvancedFilter: '',
            intakeAdvancedFilter: '',
            intakeYearAdvancedFilter:'',
            sourceAdvancedFilter: '',
            searchAdvancedFilter: '',
            startDateAdvancedFilter: '',
            endDateAdvancedFilter: '',
            universitySearchAdvancedFilter: '',
            courseSearchAdvancedFilter: '',
            applicationStatusSearchAdvancedFilter: '',

            studentStartDate: '',
            studentEndDate: '',
            universitySearch: '',
            courseSearch: '',
            applicationStatusSearch:''

        })
        let value = '';
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));

        const filterObject = {
            search: '',
            status: '',
            assigned: '',
            month: '',
            year:'',
            source: '',
            start: '',
            end: '',
            university: '',
            course: '',
            application: '',
            yopmail:yopmail
        }
        this.props.actions.paginationLead(value);
        filterObject.status = value
        this.props.actions.paginationAssigned(value);
        filterObject.assigned = value
        this.props.actions.paginationMonth(value);
        filterObject.month = value
        this.props.actions.paginationYear(value);
        filterObject.year = value
        this.props.actions.paginationSource(value);
        filterObject.source = value
        this.props.actions.paginationStartDate(value);
        filterObject.start = value
        this.props.actions.paginationEndDate(value);
        filterObject.end = value
        this.props.actions.paginationUniversity(value);
        filterObject.university = value
        this.props.actions.paginationCourse(value);
        filterObject.course = value
        this.props.actions.paginationApplicationStatus(value);
        filterObject.appliaction = value
        this.props.actions.getAllStudents(1, filterObject);

    }
    onStudentCreateForm = () => {
        const { user: { user, organization } } = this.props;
        const zilterformLink = `https://advisor.zoomabroad.com/form/${organization.organization_id}?id=${user.id}`;
        window.open(zilterformLink, "_blank")
    }
    onStudentsExport = () => {
        this.setState({ showExportModal: true })
    }
    exportExel = () => {
        const { keywordsearch, leadStatusSearch, assignedIdSearch, supportStaffIdSearch, intakeMonthSearch,intakeYearSearch, sourceSearch, exportStartDate, exportEndDate } = this.state
        const yopmail = JSON.parse(localStorage.getItem('yopmail'));

        const filterObject = {
            search: keywordsearch,
            status: leadStatusSearch,
            assigned: assignedIdSearch,
            month: intakeMonthSearch,
            year: intakeYearSearch,
            source: sourceSearch,
            start: moment(exportStartDate).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(exportEndDate).format('YYYY-MM-DD HH:mm:ss'),
            yopmail: yopmail,
            supportstaff: supportStaffIdSearch

        }
        const handleSuccess = () => {
            this.setState({ downloadStudentData: false })
        }
        this.props.actions.getExportStudents(1, filterObject, handleSuccess);

    }

    handleApplicationStatusName = (channel_id) => {
        if (this.props.applicationStatus && this.props.applicationStatus && this.props.applicationStatus.length) {
            for (let i = 0; i < this.props.applicationStatus.length; i++) {
                if (this.props.applicationStatus[i].id == channel_id) {
                    return this.props.applicationStatus[i].key
                }
            }
        }
    }
    render() {
        const { applicationData, interviews: { interview }, studentList: { students }, pagination: { count, pages }, pageNumber, allMembers, sourceList, exportStudent,
            zoomabroadUniversity, applicationStatus, user } = this.props;
        const { viewAccess, pageIndex, interviewName, selectedInterview, showInterview, leadStatusSearch, keywordsearch, assignedIdSearch, intakeMonthSearch,intakeYearSearch, sourceSearch, showExportModal,
            exportStartDate, exportEndDate, downloadStudentData, searchFilter, sourceFilter, leadStatusFilter, intakeFilter,intakeYearFilter, isContextMenuOpen, showAdvancedFilterModal, ownerFilter, supportStaffFilter,
            studentStartDate, studentEndDate, startDateFilter, endDateFilter, universitySearch, universitySearchFilter, courseSearch, courseSearchFilter, rootCloseVal, universitySearchObj, 
            applicationStatusSearch, supportStaffIdSearch } = this.state
        let members = []
        let supportMembers = []

        let sourceName = sourceList
        if (allMembers && allMembers) {
            members = Object.assign([], allMembers.Users)
            if (members) {
                let selectMember = { 'first_name': "Select Owner", 'user_id': '' }
                let index = members.findIndex(x => x.first_name == "Select Owner");
                index === -1 ? members.splice(0, 0, selectMember) : console.log("object already exists")
            }   
        }
        if (allMembers && allMembers) {
            supportMembers = Object.assign([], allMembers.Users)
            if (supportMembers) {
                let selectSupport = { 'first_name': "Support Staff", 'user_id': '' }
                let index = supportMembers.findIndex(x => x.first_name == "Support Staff");
                index === -1 ? supportMembers.splice(0, 0, selectSupport) : console.log("object already exists")
            }   
        }
        if (sourceName && sourceName) {
            let select = { 'source_short_name': "Select Source", 'id': '' }
            let index = sourceName.findIndex(x => x.source_short_name == "Select Source");
            index === -1 ? sourceName.splice(0, 0, select) : console.log("object already exists")
        }
        return (

            viewAccess ? <div className="student-page">
                {this.props.isStudentLoading && this.props.isStudentLoading? <ZLoader /> : null}

                <Row>
                    <Modal style={{ zIndex: '1' }}
                        modalTitle={"Student Application Data"}
                        isModalOpen={showExportModal}
                        onCloseModal={this.onCloseModal}

                    >
                        <DownloadExport
                            exportStartDate={exportStartDate}
                            exportEndDate={exportEndDate}
                            onChangeInput={this.onChangeInput}
                            exportExel={this.exportExel}
                            downloadStudentData={downloadStudentData}
                            onCloseModal={this.onCloseModal}
                            exportStudent={exportStudent && exportStudent ? exportStudent : {}}
                        />
                    </Modal>

                    <Col>
                        <Row>
                            <Col md="4" className="left-side-search-class">
                                <Row style={{ marginLeft: 10 }}>
                                    <Col md="8" className="bp3-input-group .modifier">
                                        <span style={{ marginLeft: 25 }} className="bp3-icon bp3-icon-search"></span>
                                        <input
                                            name={"keywordsearch"}
                                            value={keywordsearch ? keywordsearch : ""}
                                            onChange={this.onChange}
                                            // onChange={(event) => this.onChangeInput(event.target.name, event.target.value)}

                                            onKeyPress={this.handleKeyPress}
                                            className="bp3-input" modifier type="search"
                                            placeholder="Search students" dir="auto" />
                                    </Col>
                                    <Col md="4" style={{ textAlign: 'start' , marginLeft: -30 }}>
                                        <Button className="search-button btn btn-info" onClick={this.onSearch} disabled={!(keywordsearch)} >Search</Button>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md='2' className='student-title'>
                                <p>Students : {count}</p>
                            </Col>
                            <Col md="6" className="right-side-search-class">
                                <OverlayTrigger
                                    trigger="click"
                                    rootClose={rootCloseVal}
                                    // show={showAdvancedFilterModal}
                                    // target={target}
                                    placement="bottom"
                                    // container={ref.current}
                                    containerPadding={20}
                                    overlay={
                                        <Popover style={{ zIndex: 0 }}>
                                            <Popover.Title className="popover-title-h3" as="h3">{`Advance Filters`}</Popover.Title>
                                            <Popover.Content style={{ zIndex: 0 }}>
                                                <AdvancedFilter
                                                    modalTitle={"More Filters"}
                                                    isModalOpen={showAdvancedFilterModal}
                                                    onCloseModal={this.onCloseModal}
                                                    // onChangeInput={this.onChangeInput}
                                                    onChangeInput={this.onChangeAdvanceFilter}
                                                    assignedIdSearch={assignedIdSearch}
                                                    leadStatusSearch={leadStatusSearch}
                                                    members={members}
                                                    supportMembers={supportMembers}
                                                    sourceSearch={sourceSearch}
                                                    sourceName={sourceName}
                                                    intakeMonthSearch={intakeMonthSearch}
                                                    intakeYearSearch={intakeYearSearch}
                                                    applicationStatusSearch={applicationStatusSearch}
                                                    studentStartDate={studentStartDate}
                                                    studentEndDate={studentEndDate}
                                                    universitySearch={universitySearch}
                                                    courseSearch={courseSearch}
                                                    onSearch={this.onSearch}
                                                    zoomabroadUniversity={zoomabroadUniversity ? zoomabroadUniversity : []}
                                                    universitySearchObj={universitySearchObj}
                                                    applicationStatus={applicationStatus}
                                                    supportStaffIdSearch={supportStaffIdSearch}
                                                    user={user ? user.user : ''}
                                                />
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <Button className="create-button btn btn-info" onClick={this.onOpenshowAdvancedFilterModal} >More Filters</Button>

                                </OverlayTrigger>
                                {/* <Button className="create-button btn btn-info" onClick={this.onOpenshowAdvancedFilterModal} >More Filters</Button> */}
                                <Button className="import-button btn btn-info" onClick={() => this.onReset()} >Reset</Button>
                                <Button className="create-button btn btn-info" onClick={this.onStudentCreateForm} >Create</Button>

                                {this.state.userRoleAccess[4].operations[1] == 1 ? <Button className="import-button btn btn-info" onClick={this.onShowModal} >Import</Button> : ""}
                                <Button className="create-button btn btn-info" onClick={this.onStudentsExport} >Export</Button>

                            </Col>
                        </Row>
                    </Col>
                    <Modal
                        modalTitle={"Student Application Data"}
                        isModalOpen={this.state.showModal}
                        onCloseModal={this.onCloseModal}
                    >
                        <Dropzone onDrop={files => this.onDropStudentFile(files)}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({
                                    className: this.state.studentFile && this.state.studentFile.length ? 'dropzone active' : 'dropzone',
                                    onDrop: event => event.stopPropagation()
                                })}>
                                    <input {...getInputProps()} accept='.csv' />
                                    <p>Click to select file</p>

                                    {this.state.studentFile && this.state.studentFile.length ? <span className="file-name">Selected File: {this.state.studentFile[0].name}</span> : <em>(Only *.csv file type will be accepted)</em>}

                                </div>
                            )}
                        </Dropzone>
                        <div>
                            <Row>
                                <Col className="download-csv"  >
                                    <a className="download text-left" href="https://zilter.s3.amazonaws.com/Student.csv">Download sample CSV file</a>
                                </Col>
                                <Col>
                                    <Button className="upload-button btn btn-danger" disabled={this.state.studentFile ? false : true} onClick={this.uploadFile} >Upload</Button>
                                </Col>
                            </Row>
                        </div>
                    </Modal>
                </Row>
                <Row className="applied-filter">
                    <div style={{ paddingTop: 10 }}>
                        Active Filters:
                    </div>
                    {
                        (searchFilter || ownerFilter || supportStaffFilter || sourceFilter || leadStatusFilter || intakeFilter || intakeYearFilter || startDateFilter || endDateFilter || universitySearchFilter || courseSearchFilter || applicationStatusSearch) ?
                            <>
                                {
                                    searchFilter ?
                                        <div className="filter">
                                            Keyword:&nbsp;&nbsp;{searchFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('searchFilter')} aria-hidden="true"></i>
                                        </div>
                                        : ''
                                }
                                {
                                    ownerFilter ?
                                        <div className="filter">
                                            Owner:&nbsp;&nbsp;{ownerFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('ownerFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    supportStaffFilter ?
                                        <div className="filter">
                                            Owner:&nbsp;&nbsp;{supportStaffFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('supportStaffFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    sourceFilter ?
                                        <div className="filter">
                                            Source:&nbsp;&nbsp;{sourceFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('sourceFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    leadStatusFilter ?
                                        <div className="filter">
                                            Status:&nbsp;&nbsp;{leadStatusFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('leadStatusFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    intakeFilter ?
                                        <div className="filter">
                                            Intake Month:&nbsp;&nbsp;{intakeFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('intakeFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    intakeYearFilter ?
                                        <div className="filter">
                                            Intake Year:&nbsp;&nbsp;{intakeYearFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('intakeYearFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    startDateFilter && startDateFilter ?
                                        <div className="filter">
                                            Start Date:&nbsp;&nbsp;{startDateFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('startDateFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    endDateFilter && endDateFilter ?
                                        <div className="filter">
                                            End Date:&nbsp;&nbsp;{endDateFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('endDateFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    universitySearchFilter ?
                                        <div className="filter">
                                            University:&nbsp;&nbsp;{universitySearchFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('universitySearchFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                {
                                    courseSearchFilter ?
                                        <div className="filter">
                                            Course:&nbsp;&nbsp;{courseSearchFilter}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('courseSearchFilter')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                 {
                                    applicationStatusSearch ?
                                        <div className="filter">
                                            Application Status:&nbsp;&nbsp;{this.handleApplicationStatusName(applicationStatusSearch)}
                                            <i className="fa fa-times-circle delete-icon" onClick={() => this.resetFilter('applicationStatusSearch')} aria-hidden="true"></i>
                                        </div> : ''
                                }
                                <div className="filter">
                                    <i className="resetall" onClick={() => this.onReset()} >Clear All</i>
                                </div>

                            </> : <div className="filter">
                                None
                          </div>
                    }
                </Row>

                <Card className=" rounded card-header-title">
                    <Row className="row-class">
                        <Col md="3" xs="12" className="text-center">
                            <p className="title">Name</p>
                        </Col>
                        <Col md="1" xs="12">
                            <p className="title">Score</p>
                        </Col>
                        {/* <Col md="2" xs="5" className="text-center"> */}
                        {/* <p className="title">Course</p> */}
                        {/* </Col> */}
                        <Col md="1" xs="6" >
                            <p className="title">Eligibility</p>
                        </Col>
                        {/* <Col md="3" xs="6" >
                            <p className="title">Interview</p>
                        </Col> */}
                        <Col md="1" xs="2" className=" button-conatiner text-center" style={{ padding: 0 }}>
                            <p className="title">Lead Status</p>
                        </Col>
                        <Col>
                            <p>Source</p>
                        </Col>
                        <Col>
                            <p>Intake</p>
                        </Col>
                        <Col>
                            <p className="title text-center"> Owner</p>
                        </Col>
                        <Col md="1" xs="2" className=" button-conatiner" style={{ padding: 0 }}>
                            <p className="title text-center">Last Action</p>
                        </Col>
                        <Col md="1" xs="2" className=" button-conatiner" style={{ padding: 0 }}>
                            <p className="title text-center">Created At</p>
                        </Col>
                    </Row>
                </Card>
                {this.props.isStudentLoading ? <ZLoader /> : null}

                {students && students ? students.map(items => {
                    return (
                        <StudentMarkup
                            userRoleAccess={this.state.userRoleAccess[5].operations}
                            items={items}
                            reportPush={this.reportPush}
                            editPush={this.editPush}
                            updateDirection={this.updateDirection}
                            isLoading={this.props.isStudentLoading}
                            loading={this.state.loading}
                            handleClick={this.handleClick}
                            interviewData={applicationData}
                            showInterview={this.state.showInterview}
                            handleOpenInterview={this.handleOpenInterview}
                            onCloseModal={this.onCloseModal}
                            handleTextChange={this.handleTextChange}
                            interviewEmail={this.state.interviewEmail}
                            handleChangeCk={this.handleChangeCk}
                            onSendInterview={this.onSendInterview}
                            showModal={this.state.showModal}
                            showVideoModal={this.state.showVideoModal}
                            onshowVideoModal={this.onshowVideoModal}
                            onDeleteInterview={this.onDeleteInterview}
                            selectedInterview={this.state.selectedInterview}
                            openInNewTab={this.openInNewTab}
                            openStudentEdit={this.openStudentEdit}
                            allMembers={allMembers ? allMembers : []}
                            sourceList={sourceList ? sourceList : []}
                            SetContextMenuOpen={this.SetContextMenuOpen}
                            SetContextMenuClose={this.SetContextMenuClose}
                            isContextMenuOpen={isContextMenuOpen}
                            count={count}
                        />

                    )
                }) : ''
                }
                <Paginator
                    count={count}
                    pages={pages}
                    onPageChange={this.onPageChange}
                    itemRender={this.itemRender}
                    pageIndex={pageIndex}
                />
                <Omnibar
                    isOpen={this.state.isOpen}
                    onItemSelect={this.handleItemSelect}
                    items={interview ? interview : []}
                    onClose={this.handleClose}
                    itemRenderer={this.renderFilm}
                    itemPredicate={this.filterItems}
                    // onActiveItemChange
                    className="omni-search"

                />
            </div> : <Denied />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        error: state.Auth.error,
        studentList: state.students.studentsList,
        pagination: state.students.pagination,
        interviews: state.interview.interviews,
        studentList: state.students.studentsList,
        studentSearch: state.students.studentSearch,
        // pagination: state.students.pagination,
        user: state.Auth.user,
        applicationData: state.students.applicationList,
        pageNumber: state.students.pageNumber,
        searchName: state.students.searchName,
        studentLead: state.students.studentLead,
        allMembers: state.members.allMembers,
        sourceList: state.source.sourceList,
        searchAssigned: state.students.searchAssigned,
        searchMonth: state.students.searchMonth,
        searchYear: state.students.searchYear,
        searchSource: state.students.searchSource,
        exportStudent: state.students.exportStudent,
        searchStartDate: state.students.searchStartDate,
        searchEndDate: state.students.searchEndDate,
        universitySearch: state.students.searchUniversity,
        courseSearch: state.students.searchCourse,
        searchAdvancedFilter: state.students.searchAdvancedFilter,
        zoomabroadUniversity: state.students.zoomabroadUniversity,
        applicationStatusSearch: state.students.searchApplicationStatus,
        applicationStatus: state.students.applicationStatus,
        isStudentLoading: state.students.isStudentLoading

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        interviewActions: bindActionCreators(interviewActions, dispatch),
        actions: bindActionCreators(actions, dispatch),
        memberActions: bindActionCreators(memberActions, dispatch),
        sourceActions: bindActionCreators(sourceActions, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Student));
