// export const URL = "https://app.zilter.io";
export const URL = "https://advisor.zoomabroad.com";
export const WEB_URL = "https://www.zoomabroad.com";

export const HUBSPOT = "https://app.hubspot.com";
export const HUBSPOTAPI = "https://api.hubapi.com";
export const HUBURL = `https://cors-anywhere.herokuapp.com/${HUBSPOTAPI}/`;
export const HUBSPOT_OAUTH_REQUEST = {
    "grant_type": "authorization_code",
    "client_id": "c62bc004-574a-4554-acb7-ef4ad2a76623",
    "client_secret": "620739b9-7224-4972-8dee-62304c0a7f6f",
    "redirect_uri": `${URL}/integration/connect?app=hubspot`,
    "code": ""
}

// export const APISAAS_DOMAIN = "https://uatapi.advisor.zoomabroad.com/";


export const APISAAS_DOMAIN = "https://api.advisor.zoomabroad.com/";
// export const APISAAS_DOMAIN = "http://uatadvisorziltertest-env.eba-ed8pfyjd.us-west-2.elasticbeanstalk.com/"

export const ASSETS_DOMAIN = "https://betaapi.zoomabroad.com";
export const CLOUDFROND_ASSET_URL = 'https://assets.zoomabroad.com';
export const CLOUDFROND_ASSETS3_URL = 'https://zoomabroad-assets.s3.amazonaws.com';

export const API_DOMAIN = "https://blueapi.zoomabroad.com";
// export const API_DOMAIN = "http://127.0.0.1:8000/";

export const API_ZABO_STD_DOMAIN = "https://api.student.zoomabroad.com";
// export const API_ZABO_STD_DOMAIN = "http://127.0.0.1:8000";

