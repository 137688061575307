import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { Button, Form } from 'react-bootstrap';
import actions from '../../modules/actions';
import { bindActionCreators } from 'redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ZLoader } from "../../../../shared/loader/ZLoader"
import { Toast } from '../../../../shared';
import './Note.scss';


class EmailComposer extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    subject: '',
    courseName:''
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onSubjectChange = (event) => {
    this.setState({
      subject: event.target.value,
    });
  };

  onChangeInput = (name, value)=>{
    this.setState({
      [name]: value,
  });
  }

  sendEmail = () => {
    const { student, userProfile, actions: { sendEmail, createLog },courseName, closeModal, subject, editorState, editState, editId, onEditState, noteStatusValue, onEditNoteChange, editorNoteState } = this.props;
    console.log("student>>>>>",student,">>>>>", userProfile)
    // userProfile ? userProfile.organization.user_id : null,
    const log = {
      "std": student.id,           ////student_id
      "u_id": userProfile ? userProfile.organization.user_id : null,
      "student_courses_id": courseName ? courseName : null,
      "subject": subject,
      "type": 'note',
      "note": draftToHtml(convertToRaw(editorNoteState.getCurrentContent())),
      "note_status": userProfile.organization.organization_id == 'efdf7895-8dac-471b-b9d9-325920bf0847' ?  noteStatusValue :'private',
      "student_name": student.first_name,
      "student_email": student.email,
      "organization_id":userProfile ? userProfile.organization.organization_id: null,

    }
    const handleSuccess = () => {
      closeModal();
      onEditState();
      Toast.show({
        message: "Note saved successfully!",
        timeout: 4000,
        intent: 'success'
      })
     
      const page = 1
      this.props.actions.getLogs(student.id, page);

    }
    const handleError = () => {
      Toast.show({
        message: "Note saving failed!",
        timeout: 4000,
        intent: 'danger'
      })
    }
    closeModal();
    if(editState){
      this.props.actions.updateTaskLog(editId, log, handleSuccess, handleError);
    }
    else{
      this.props.actions.createNoteLog(log, handleSuccess, handleError);
    }
  }

  render() {
    const { student, userAuthDetails, courseName, courses, onSubjectChange, subject, onChangeInput, editorNoteState, onEditNoteChange, noteStatusValue, isLoading } = this.props;
    return (
      <div className="email-composer">
         {isLoading ? <ZLoader/>: null }
        <div className="select-name">
          <Form.Control type="text" placeholder="Subject" value={subject} onChange={(event) => onSubjectChange("subject", event.target.value)} />
        </div>
        {  courses && courses.length ? 
        <div className="select-name">
          <Form.Control placeholder="From" className="form-control modal-email" as="select" onChange={(event) => onChangeInput("courseName", event.target.value)} value={courseName} >
          <option value="">Select Course Name</option>
          {
                                    courses && courses.length ? courses.map((course) => {
                                        return (
                                            <option value={course.id}>{course.applied_courses}</option>
                                        )
                                    })
                                        : ''}
          </Form.Control>
        </div>
        :''}
          
        <div className="select-name">
          <Form.Control placeholder="From" className="form-control modal-email" as="select" onChange={(event) => onChangeInput("noteStatusValue", event.target.value)} value={noteStatusValue} >
          <option value="">Select</option>
                                    <option value="private">Private</option>
                                    <option value="public">Public</option>
                                    <option value="call_log">Call Log</option>
                            
          </Form.Control>
        </div>
        


        <Editor
          editorState={editorNoteState}
          wrapperClassName="email-wrapper"
          editorClassName="email-editor"
          onEditorStateChange={onEditNoteChange}
        />
        <div className="email-btn-toolbar">
          <Button className="pull-right" variant="primary" onClick={() => this.sendEmail()} disabled={!(editorNoteState && subject && noteStatusValue)}>Save Note</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
	}

}

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch),

	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailComposer));
