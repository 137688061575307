import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CLOUDFROND_ASSETS3_URL } from '../../../../../env'
import { bindActionCreators } from 'redux';
import action from '../../../modules/actions'
import integrationAction from '../../../../Integration/modules/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Image, Accordion, Card, Form , OverlayTrigger,Popover} from 'react-bootstrap';
import { Toast } from "../../../../../shared/toast/Toast";
import './ConditionalOffer.scss';

class ConditionalOffer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            academicValue: '',
            otherValue: '',
            immigrationHistoryVal: '',
            toeflVal: false,
            pteVal: false,
            satVal: false,
            gmatVal: false,
            depositVal: false,
            actVal: false,
            greVal: false,
            bankVal: false,
            ieltsVal:false,
            id: '',
            docConditionalFile: '',
            docUrl:'',
            credibilityTestVal:'',
            errors:{}

        }
    }
    componentDidMount() {
        const { applicationStatusId } = this.props;
        // if(applicationStatusId && applicationStatusId){
        // this.props.action.getAppConditionalOffer(applicationStatusId)
        // }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.conditionalOffer && props.conditionalOffer.length) {
            if (props.conditionalOffer[0].id != state.id) {
                return {
                    otherValue: props.conditionalOffer[0].other_description,
                    academicValue: props.conditionalOffer[0].academic_description,
                    immigrationHistoryVal: props.conditionalOffer[0].immigration_history_description,
                    toeflVal: props.conditionalOffer[0].toefl_val,
                    pteVal: props.conditionalOffer[0].pte_val,
                    satVal: props.conditionalOffer[0].sat_val,
                    gmatVal: props.conditionalOffer[0].gmat_val,
                    depositVal: props.conditionalOffer[0].deposit_val,
                    actVal: props.conditionalOffer[0].act_val,
                    greVal: props.conditionalOffer[0].gre_val,
                    bankVal: props.conditionalOffer[0].bank_statement_val,
                    docUrl: props.conditionalOffer[0].conditional_ol_doc,
                    ieltsVal:props.conditionalOffer[0].ielts_val,
                    credibilityTestVal: props.conditionalOffer[0].credibility_test_description,
                    id: props.conditionalOffer[0].id
                };
            }
        }
    }
    onDropFile = (event) => {
        this.setState({
            docConditionalFile: event.target.files[0]
        });
    }
    clearForm = () =>{
        this.setState({
            academicValue: '',
            otherValue: '',
            immigrationHistoryVal: '',
            toeflVal: false,
            pteVal: false,
            satVal: false,
            gmatVal: false,
            depositVal: false,
            actVal: false,
            greVal: false,
            bankVal: false,
            ieltsVal:false,
            id: '',
            docConditionalFile: '',
            docUrl:'',
            credibilityTestVal:'',
            errors:{}
        })
        this.props.setClearForm()
    }
    isvalidOLC = () => {
        const { academicValue, ieltsVal, toeflVal, pteVal, immigrationHistoryVal, depositVal, bankVal, otherValue, docConditionalFile } = this.state;
        let errors = {};
        if (academicValue == '') {
            errors.academicValue = true;
            this.setState({ errors });
            return false;
        }
        if (ieltsVal == '' && toeflVal == '' && pteVal == '') {
            errors.langerror = true;
            this.setState({ errors });
            return false;
        }
        if (immigrationHistoryVal == '') {
            errors.immigrationHistoryVal = true;
            this.setState({ errors });
            return false;
        }
        if (depositVal == '' && bankVal == '') {
            errors.financeerror = true;
            this.setState({ errors });
            return false;
        }
        if (otherValue == '') {
            errors.otherValue = true;
            this.setState({ errors });
            return false;
        }
        if (docConditionalFile == '') {
            errors.docConditionalFile = true;
            this.setState({ errors });
            return false;
        }
        return true;

    };

    onSubmit = () => {
        const { academicValue, otherValue, immigrationHistoryVal, toeflVal, pteVal, satVal, gmatVal,
            depositVal, actVal, greVal, bankVal, docConditionalFile, docUrl, ieltsVal, credibilityTestVal } = this.state
        const { match: { params }, userProfile, onCloseModal, applicationStatusId } = this.props;
        if (this.isvalidOLC()) {

            if (docConditionalFile) {
                const formData = new FormData()
                formData.append('doc_file', docConditionalFile);

                const handleSuccess = (response) => {
                    const data = {
                        'student_id': params.id,
                        'user_id': userProfile.organization.user_id,
                        'organization_id': userProfile.organization.organization_id,
                        'academic_description': academicValue,
                        'act_val': actVal,
                        'bank_statement_val': bankVal,
                        'deposit_val': depositVal,
                        'gmat_val': gmatVal,
                        'ielts_val': ieltsVal,
                        'gre_val': greVal,
                        'immigration_history_description': immigrationHistoryVal,
                        'other_description': otherValue,
                        'pte_val': pteVal,
                        'sat_val': satVal,
                        'toefl_val': toeflVal,
                        'conditional_ol_doc': `${CLOUDFROND_ASSETS3_URL}/${response.data}`,
                        'application_id': applicationStatusId,
                        'credibility_test_description': credibilityTestVal
                    }
                    const handlesuccessfull = () => {
                        Toast.show({
                            message: 'Save successfully',
                            timeout: 4000,
                            intent: 'success'
                        });
                        onCloseModal()

                        this.setState({
                            academicValue: '',
                            otherValue: '',
                            immigrationHistoryVal: '',
                            toeflVal: false,
                            pteVal: false,
                            satVal: false,
                            gmatVal: false,
                            depositVal: false,
                            actVal: false,
                            greVal: false,
                            bankVal: false,
                            ieltsVal: false,
                            id: '',
                            docConditionalFile: '',
                            docUrl: '',
                            credibilityTestVal: ''
                        })
                    };
                    const handleFailure = () => {
                        Toast.show({
                            message: 'Save unsuccessfully',
                            timeout: 4000,
                            intent: 'danger'
                        });
                        onCloseModal()

                        this.setState({
                            academicValue: '',
                            otherValue: '',
                            immigrationHistoryVal: '',
                            toeflVal: false,
                            pteVal: false,
                            satVal: false,
                            gmatVal: false,
                            depositVal: false,
                            actVal: false,
                            greVal: false,
                            bankVal: false,
                            ieltsVal: false,
                            id: '',
                            docConditionalFile: '',
                            docUrl: '',
                            credibilityTestVal: ''
                        })
                    }
                    this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'conditional')
                }

                const handleDocFailure = () => { }
                this.props.integrationAction.documentUpload(handleSuccess, handleDocFailure, formData)
            }
            else {
                const data = {
                    'student_id': params.id,
                    'user_id': userProfile.organization.user_id,
                    'organization_id': userProfile.organization.organization_id,
                    'academic_description': academicValue,
                    'act_val': actVal,
                    'bank_statement_val': bankVal,
                    'deposit_val': depositVal,
                    'gmat_val': gmatVal,
                    'gre_val': greVal,
                    'ielts_val': ieltsVal,
                    'immigration_history_description': immigrationHistoryVal,
                    'other_description': otherValue,
                    'pte_val': pteVal,
                    'sat_val': satVal,
                    'toefl_val': toeflVal,
                    'conditional_ol_doc': docUrl,
                    'application_id': applicationStatusId,
                    'credibility_test_description': credibilityTestVal
                }
                const handlesuccessfull = () => {
                    Toast.show({
                        message: 'Save successfully',
                        timeout: 4000,
                        intent: 'success'
                    });
                    onCloseModal()
                };
                const handleFailure = () => {
                    Toast.show({
                        message: 'Save unsuccessfully',
                        timeout: 4000,
                        intent: 'danger'
                    });
                    onCloseModal()
                }
                this.props.action.createApplicationStatus(data, handlesuccessfull, handleFailure, 'conditional')
            }
        }
    }

    onChangeInput = (name, value) => {
        this.setState({
            [name]: value, errors:{}
        })
    }
    userDocument = (doc_url) => {
        if (doc_url) {
          return (
            <a href={`${doc_url}`}>
              <span>
                <OverlayTrigger
                    placement={'top'}
                    delay={{ hide: 200 }}
                    overlay={<Popover className="tool-tip" id="tooltip-disabled">Download</Popover>}>
                    <i class="fa fa-cloud-download" aria-hidden="true"></i>
                </OverlayTrigger>
              </span>
            </a>
    
          );
        }
    }

    render() {
        const { academicValue, otherValue, immigrationHistoryVal, toeflVal, pteVal, satVal,
             gmatVal, depositVal, actVal, greVal, bankVal, ieltsVal, docConditionalFile, docUrl, credibilityTestVal, errors } = this.state;

             const { clearModel } =this.props
             if(!clearModel){
                 this.clearForm()
             }

        return (
            <div className="conditional">
                <Col>
                    <Form.Label className="title">Academic Status *</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Click here to enter Academic Status"
                        className="form-control text-box"
                        value={academicValue}
                        resize={false}
                        onChange={(event) => this.onChangeInput('academicValue', event.target.value)}
                    />
                    {errors.academicValue ? <span className="errors">Please Enter Academic Description</span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Language *</Form.Label>
                    <Row>
                        <Form.Group >
                            <Form.Check
                                name="ielts"
                                type="checkbox"
                                label="IELTS"
                                className="check-box"
                                value={ieltsVal}
                                checked={ieltsVal ? true: false}
                                onChange={(event) => this.onChangeInput('ieltsVal', event.target.checked)}

                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="toefl"
                                type="checkbox"
                                label="TOEFL"
                                className="check-box"
                                value={toeflVal}
                                checked={toeflVal ? true: false}
                                onChange={(event) => this.onChangeInput('toeflVal', event.target.checked)}

                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="pte"
                                type="checkbox"
                                label="PTE"
                                className="check-box"
                                checked={pteVal ? true: false}
                                onChange={(event) => this.onChangeInput('pteVal', event.target.checked)}
                                value={pteVal}

                            />
                        </Form.Group>
                    </Row>
                    {errors.langerror ? <span className="errors">Please Select Appropriate Language Check Box</span> :''}
                </Col>
                <Col>
                    <Form.Label className="title">Aptitude test </Form.Label>
                    <Row>
                        <Form.Group >
                            <Form.Check
                                name="sat"
                                type="checkbox"
                                label="SAT"
                                className="check-box"
                                value={satVal}
                                checked={satVal ? true: false}
                                onChange={(event) => this.onChangeInput('satVal', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="act"
                                type="checkbox"
                                label="ACT"
                                className="check-box"
                                value={actVal}
                                checked={actVal ? true: false}
                                onChange={(event) => this.onChangeInput('actVal', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="gre"
                                type="checkbox"
                                label="GRE"
                                className="check-box"
                                value={greVal}
                                checked={greVal ? true: false}
                                onChange={(event) => this.onChangeInput('greVal', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="gmat"
                                type="checkbox"
                                label="GMAT"
                                className="check-box"
                                value={gmatVal}
                                checked={gmatVal ? true: false}
                                onChange={(event) => this.onChangeInput('gmatVal', event.target.checked)}
                            />
                        </Form.Group>
                    </Row>
                </Col>
                <Col>
                    <Form.Label className="title">Immigration History *</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Click here to enter Immigration History"
                        className="form-control text-box"
                        value={immigrationHistoryVal}
                        onChange={(event) => this.onChangeInput('immigrationHistoryVal', event.target.value)}
                    />
                    {errors.immigrationHistoryVal ? <span className="errors">Please Enter Valid Immigration History</span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Credibility Test </Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Click here to enter text"
                        className="form-control text-box"
                        value={credibilityTestVal}
                        onChange={(event) => this.onChangeInput('credibilityTestVal', event.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label className="title">Finance *</Form.Label>
                    <Row>
                        <Form.Group >
                            <Form.Check
                                name="deposit"
                                type="checkbox"
                                label="Deposit"
                                className="check-box"
                                value={depositVal}
                                checked={depositVal ? true: false}
                                onChange={(event) => this.onChangeInput('depositVal', event.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Check
                                name="bank_statement"
                                type="checkbox"
                                label="Bank Statement"
                                className="check-box"
                                value={bankVal}
                                checked={bankVal ? true: false}
                                onChange={(event) => this.onChangeInput('bankVal', event.target.checked)}
                            />
                        </Form.Group>
                    </Row>
                    {errors.financeerror ? <span className="errors">Please Select Appropriate Finance Check Box</span> : ''}
                </Col>
                <Col>
                    <Form.Label className="title">Others *</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={2}
                        placeholder="Click here to enter text"
                        className="form-control text-box"
                        value={otherValue}
                        onChange={(event) => this.onChangeInput('otherValue', event.target.value)}
                    />
                    {errors.otherValue ? <span className="errors">Please Enter Other Details</span> : ''}
                </Col>
               {
               docUrl && docUrl?  <Col>
                {this.userDocument(docUrl)}<p>Download</p>
                </Col>: ''}
                <Col>
                    <Form.File
                        id="custom-file"
                        label="Upload Document *"
                        val
                        accept=".png, .jpg, .jpeg, .pdf"
                        name="docConditionalFile"
                        onChange={this.onDropFile}
                    />
                    {errors.docConditionalFile ? <span className="errors">Please Upload Document File (".png, .jpg, .jpeg, .pdf")</span> : ''}
                </Col>
                <Col className="save-offer-button">
                    <Button className="save-offer" onClick={this.onSubmit}>Save</Button>
                </Col>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.students.isLoading,
        userProfile: state.Auth.user,
        conditionalOffer: state.students.conditionalOffer
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(action, dispatch),
        integrationAction: bindActionCreators(integrationAction, dispatch),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ConditionalOffer));